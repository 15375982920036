import React, { Component } from 'react';
import ChatBot from 'react-simple-chatbot';
import history from 'helpers/history';
import { BrowserRouter as Router, Switch, Route, Link, useRouteMatch } from 'react-router-dom';

/**
 *
 */
class Review extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      gender: '',
      email: '',
    };
  }

  componentWillMount() {
    const { steps } = this.props;
    const { name, gender, email } = steps;

    this.setState({ name, gender, email });
  }

  render() {
    // console.log(this.state);
    // debugger;
    const { name, gender, email } = this.state;
    return (
      <div style={{ width: '100%' }}>
        <h3>Summary</h3>
        <table>
          <tbody>
            <tr>
              <td>Name</td>
              <td>{name.value}</td>
            </tr>
            <tr>
              <td>Gender</td>
              <td>{gender.value}</td>
            </tr>
            <tr>
              <td>Email</td>
              <td>{email.value}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

class GoToPage extends Component {
  render() {
    return (
      <>
        <Link to={'/register'}>
          <button
            className="sc-AykKF rsc-os-option-element"
            style={{ background: 'rgb(23, 23, 23)' }}
          >
            register
          </button>
        </Link>
        {/*
<Link to={'/register'}>{'llllllllll'}</Link>
        
          <Link to={'/register'}>
        <button class="sc-AykKF rsc-os-option-element" style="background: rgb(23, 23, 23);">register</button>
        </Link>      
        */}
      </>
    );
  }
}

/**
 *
 */
class SimpleForm extends Component {
  state = {
    isChatOpened: true,
  };

  componentDidMount() {
    // if (this.props.isOpened) this.setState(state => ({ isChatOpened: true }));
  }

  handleEnd = ({ steps, values }) => {
    /*
    history.push({
      pathname: '/',
      state: { test: 'test' },
    });
    */
    // this.setState(state => ({ isChatOpened: false }));
    // currentChat: 'firstChat',
    // this.props.changeIsFirstMeeting('currentChat', 'firstChat');
    // setTimeout(() => {
    // history.push('/');
    // }, 3000);
  };

  render() {
    // console.log('------');
    return (
      <ChatBot
        handleEnd={this.handleEnd}
        {...this.props.chatSettings}
        opened={this.state.isChatOpened}
        steps={[
          {
            id: '1',
            message: 'What is your name?',
            // trigger: 'name',
            trigger: '2',
          },
          {
            id: '2',
            options: [
              { value: 'name', label: 'name', trigger: 'name' },
              { value: 'register', label: 'register', trigger: 'register' },
            ],
          },
          {
            id: 'name',
            user: true,
            trigger: '3',
            validator: value => {
              // //jhjk
              if (!value.match(/^[a-z0-9_-]{3,16}$/)) {
                return `Please ... `;
              }
              return true;
            },
          },
          {
            id: '3',
            message: 'Hi {previousValue}! What is your gender?',
            trigger: 'gender',
          },
          {
            id: 'gender',
            options: [
              { value: 'male', label: 'Male', trigger: '4' },
              { value: 'female', label: 'Female', trigger: '4' },
            ],
          },
          {
            id: '4',
            message: 'What is your Email?',
            trigger: 'email',
          },
          {
            id: 'email',
            user: true,
            trigger: '7',
            validator: value => {
              // check@this.com
              if (
                (!value.match(/\S+@\S+\.\S+/) && value.indexOf(' ') != -1) ||
                value.indexOf('..') != -1
              ) {
                return `Must be like, - check@this.com!`;
              }
              return true;
            },
          },
          // ---------------------------
          {
            id: '7',
            message: 'Great! Check out your summary',
            trigger: 'review',
          },
          {
            id: 'review',
            component: <Review />,
            asMessage: true,
            trigger: 'update',
          },
          {
            id: 'update',
            message: 'Would you like to update some field?',
            trigger: 'update-question',
          },
          {
            id: 'update-question',
            options: [
              { value: 'yes', label: 'Yes', trigger: 'update-yes' },
              { value: 'no', label: 'No', trigger: 'end-message' },
            ],
          },
          {
            id: 'update-yes',
            message: 'What field would you like to update?',
            trigger: 'update-fields',
          },
          {
            id: 'update-fields',
            options: [
              { value: 'name', label: 'Name', trigger: 'update-name' },
              { value: 'gender', label: 'Gender', trigger: 'update-gender' },
              { value: 'email', label: 'Email', trigger: 'update-email' },
            ],
          },
          {
            id: 'update-name',
            update: 'name',
            trigger: '7',
          },
          {
            id: 'update-gender',
            update: 'gender',
            trigger: '7',
          },
          {
            id: 'update-email',
            update: 'email',
            trigger: '7',
          },
          {
            id: 'end-message',
            message:
              'Thanks! I will remember the data you entered! It remains to take one small step and register on the site (and you will have access to your personal account, and to multi-channel chat for direct correspondence with my creator)',
            trigger: '8',
          },
          {
            id: '8',
            options: [
              { value: 'register', label: 'Register', trigger: 'register' },
              { value: 'gender', label: 'Maiby letter', trigger: 'review' },
            ],
          },
          {
            id: 'register',
            component: <GoToPage />,
            asMessage: true,
            trigger: 'last',
          },
          {
            id: 'last',
            message: 'Great! Thanks..',
            end: true,
          },
        ]}
      />
    );
  }
}

export default SimpleForm;

/*
          {
            id: 'register',
            message: props => {
              // console.log('>------->', props);
              
              history.push({
                pathname: '/',
                state: { test: 'test' },
              });
              
             return 'messssage';
            },
            end: true,
          },
*/

/*
    history.push({
      pathname: LISTING_PRICE,
      state: { listingId, formattedAddress },
    });
*/

/*
          {
            id: 'end-message',
            message: 'Thanks! Your data was submitted successfully!',
            end: true,
          },
*/
