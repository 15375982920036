import { Reducer, combineReducers } from 'redux';

import applicationReducer from './modules/aplication/reducer';
import authReducer from './modules/auth/reducer';
import * as chatReducers from './modules/chat/reducer';
import botReducer from './modules/bot/reducer';

import { IState as IApplicationState } from './modules/aplication/interfaces';
import { IState as IAuthState } from './modules/auth/interfaces';

const reducers = combineReducers({
  application: applicationReducer,
  auth: authReducer,
  bot: botReducer,
  user: chatReducers.user_reducer,
  channel: chatReducers.channel_reducer,
  colors: chatReducers.colors_reducer,
});

export default reducers;

/*
export interface ApplicationState {
  application: IApplicationState;
  auth: IAuthState;
}

const reducers: Reducer<ApplicationState> = combineReducers<ApplicationState>({
  application: applicationReducer,
  auth: authReducer,
});
*/
