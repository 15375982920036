import { connect } from 'react-redux';
import { ApplicationState } from 'redux/temp_interface';

// actions
// import { getListings, getListing, setListingStep } from 'redux/modules/listing/actions';

// components
import Account from 'components/account';

const mapStateToProps = (state: ApplicationState) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Account);
