import React, { Component } from 'react';

import Sing from './sign.svg';

import './Signature.css';

export default class ыAnimatedSignature extends Component {
  // setInterval(calcPaths, 5500)

  state = {
    reload: false,
    show_SVG: false,
  };

  calcPaths = (totalDur: any) => {
    // unset 'animated' class to body which will reset the animation
    document.body.classList.remove('animated');

    // get all SVG elements - lines and dots
    const paths = document.querySelectorAll('.autograph__path');
    // prepare path length variable
    let len = 0;
    // prepare animation delay length variable
    let delay = 0;

    // escape if no elements found
    if (!paths.length) {
      return false;
    }

    // set duration in seconds of animation to default if not set
    const totalDuration = totalDur || 5;

    // calculate the full path length
    paths.forEach((path: any) => {
      const totalLen = path.getTotalLength();
      len += totalLen;
    });

    paths.forEach((path: any) => {
      const pathElem = path;
      // get current path length
      const totalLen = path.getTotalLength();
      // calculate current animation duration
      const duration = (totalLen / len) * totalDuration;

      // set animation duration and delay
      pathElem.style.animationDuration = `${duration < 0.2 ? 0.2 : duration}s`;
      pathElem.style.animationDelay = `${delay}s`;

      // set dash array and offset to path length - this is how you hide the line
      pathElem.setAttribute('stroke-dasharray', totalLen);
      pathElem.setAttribute('stroke-dashoffset', totalLen);

      // set delay for the next path - added .2 seconds to make it more realistic
      delay += duration + 0.2;
    });

    // set 'animated' class to body which will start the animation
    document.body.classList.add('animated');

    return true;
  };

  svg_folder = () => {
    return (
      <>
        {/*A*/}
        <svg
          className="autograph a_letter"
          height="103"
          viewBox="-10 0 80 80"
          xmlns="http://www.w3.org/2000/svg"
          style={{ opacity: 0 }}
        >
          <g
            fill="none"
            fill-rule="evenodd"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="3"
            transform="translate(2 2)"
          >
            <path
              className="autograph__path"
              d="M12.2295 63.2714C11.9437 60.448 11.1769 58.7429 8.14268 58.7429C5.54063 58.7429 3.19611 63.3082 2.81537 65.3934C2.17015 68.9271 1.1662 72.6253 3.25839 75.8723C5.36734 79.1453 11.1037 78.7198 13.9795 76.977C18.7333 74.0961 23.3021 70.7691 25.5866 65.5465C28.1146 59.7671 29.6288 53.6995 31.3569 47.6406C32.3692 44.0915 33.3833 40.5442 34.4027 36.9977C35.5157 33.1253 36.3304 29.1735 37.8582 25.425C39.2932 21.9039 40.4991 18.2968 41.6349 14.6726C42.0032 13.4975 42.3906 12.3139 42.9308 11.2052C43.9219 9.17079 44.4618 6.81708 45.7218 4.92664C46.3182 4.03175 47.4533 2.71115 48.7343 3.05619C50.5742 3.55176 51.8163 6.51849 52.6994 7.9456C54.1655 10.315 54.6643 13.519 54.848 16.2587C55.1611 20.9287 54.99 25.7414 54.3385 30.3691C52.2614 45.1233 52.3006 60.1973 52.3006 75.0628C52.3006 76.2332 52.1194 77.3614 52.1013 78.5412C52.0815 79.8285 51.833 78.0292 51.8022 77.7318C51.6821 76.5694 51.5385 75.4436 51.3038 74.2972C49.8145 67.0201 48.7534 59.9682 45.0019 53.4051C40.2268 45.0512 30.8968 42.6429 21.8431 42.0511C17.3074 41.7547 13.2455 42.3043 9.01764 43.9325C7.20166 44.6319 5.41404 45.4571 4.22196 47.0499C3.25211 48.3458 3.08267 49.9164 2.85968 51.458"
              stroke="black"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </svg>
        {/*n*/}
        <svg
          className="autograph n_letter"
          height="103"
          viewBox="4 -25 45 45"
          xmlns="http://www.w3.org/2000/svg"
          style={{ opacity: 0 }}
        >
          <g
            fill="none"
            fill-rule="evenodd"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="3"
            transform="translate(2 2)"
          >
            <path
              className="autograph__path"
              d="M2 9.33671C3.72068 7.54217 7.67778 2.62366 10.5361 5.2376C11.3401 5.97291 11.5384 7.31921 11.6465 8.33963C12.0177 11.8423 11.8495 15.6885 11.6465 19.1967C11.4329 22.8895 11.2481 26.6171 10.9287 30.2975C10.7211 32.6889 10.6253 35.1153 10.0762 37.4544C9.88691 38.2605 9.67237 39.1395 9.67237 39.9692C9.67237 40.6593 9.47047 41.6536 9.47047 40.4013C9.47047 37.3868 10.0977 34.5695 10.6819 31.627C12.1542 24.2109 13.9193 16.4564 17.3447 9.62475C18.6149 7.09155 20.2365 4.88794 22.7962 3.55365C24.1961 2.82391 26.6548 1.22368 28.2925 2.44578C30.2693 3.921 30.6391 6.02598 31.0742 8.251C31.7827 11.8733 31.2736 15.3279 30.9172 18.953C30.5358 22.8326 29.8285 26.6544 29.5039 30.5413C29.3106 32.8554 29.3428 35.7359 30.3115 37.8975C31.0949 39.6455 32.9988 40.6158 34.888 40.4013C36.3287 40.2377 41.454 38.3792 40.9676 36.4573"
              stroke="black"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </svg>
        {/*a*/}
        <svg
          className="autograph n_letter"
          height="103"
          viewBox="-4 -25 45 45"
          xmlns="http://www.w3.org/2000/svg"
          style={{ opacity: 0 }}
        >
          <g
            fill="none"
            fill-rule="evenodd"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="3"
            transform="translate(2 2)"
          >
            <path
              className="autograph__path"
              d="M22.275 11.2005C20.6694 8.06733 18.6904 3.00675 14.2817 3.00675C12.6303 3.00675 11.0765 2.88921 9.55634 3.63537C7.42605 4.681 5.42771 7.37306 4.68748 9.55307C3.54632 12.9138 3.15252 16.297 2.68915 19.8061C2.11652 24.1426 1.97058 28.4322 2.00464 32.812C2.02654 35.6284 2.78816 38.6847 4.54396 40.9624C5.82671 42.6264 7.64609 43 9.64467 43C10.9844 43 12.1926 41.66 13.0231 40.724C14.2942 39.2912 14.9148 37.8203 15.8273 36.1719C17.6238 32.9269 19.2712 29.4944 20.376 25.9514C21.6662 21.814 22.9862 17.6725 23.9531 13.444C24.3198 11.8404 24.4979 10.2137 24.8585 8.62097C25.0386 7.82536 25.0572 7.91237 25.0572 8.62097C25.0572 9.38109 24.9861 10.1198 24.9026 10.8753C24.0662 18.448 22.6277 26.2873 24.3616 33.8525C24.8989 36.1966 25.2074 39.621 27.6517 40.9407C28.9773 41.6565 30.7289 41.6033 31.5711 40.2254C32.587 38.5632 34 36.1929 34 34.221"
              stroke="black"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </svg>
        {/*n*/}
        <svg
          className="autograph n_letter"
          height="103"
          viewBox="-4 -25 45 45"
          xmlns="http://www.w3.org/2000/svg"
          style={{ opacity: 0 }}
        >
          <g
            fill="none"
            fill-rule="evenodd"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="3"
            transform="translate(2 2)"
          >
            <path
              className="autograph__path"
              d="M2 9.33671C3.63244 7.54217 7.38662 2.62366 10.0983 5.2376C10.8611 5.97291 11.0493 7.31921 11.1519 8.33963C11.504 11.8423 11.3444 15.6885 11.1519 19.1967C10.9492 22.8895 10.7738 26.6171 10.4708 30.2975C10.2739 32.6889 10.183 35.1153 9.66202 37.4544C9.48245 38.2605 9.27892 39.1395 9.27892 39.9692C9.27892 40.6593 9.08737 41.6536 9.08737 40.4013C9.08737 37.3868 9.68246 34.5695 10.2367 31.627C11.6335 24.2109 13.308 16.4564 16.5578 9.62475C17.7629 7.09155 19.3013 4.88794 21.7297 3.55365C23.0578 2.82391 25.3904 1.22368 26.9441 2.44578C28.8196 3.921 29.1704 6.02598 29.5833 8.251C30.2554 11.8733 29.7723 15.3279 29.4343 18.953C29.0725 22.8326 28.4013 26.6544 28.0934 30.5413C27.9101 32.8554 27.9406 35.7359 28.8596 37.8975C29.6028 39.6455 31.4091 40.6158 33.2014 40.4013C34.5683 40.2377 39.4308 38.3792 38.9692 36.4573"
              stroke="black"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </svg>

        {/*c*/}
        <svg
          className="autograph n_letter"
          height="103"
          viewBox="-7 -25 45 45"
          xmlns="http://www.w3.org/2000/svg"
          style={{ opacity: 0 }}
        >
          <g
            fill="none"
            fill-rule="evenodd"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="3"
            transform="translate(2 2)"
          >
            <path
              className="autograph__path"
              d="M19 8.8967C18.7781 6.67903 17.9897 4.51042 16.4995 2.9478C15.7137 2.1239 13.4517 1.68429 12.4807 2.26477C11.7275 2.71505 10.8969 2.77684 10.1092 3.14609C8.31971 3.98493 7.64196 5.43289 6.66598 7.25524C5.03273 10.3048 4.28864 13.6855 3.63953 17.137C3.13937 19.7966 2.49184 22.4213 2.17096 25.113C1.90035 27.3829 2.03204 29.7207 2.03204 32.0093C2.03204 33.5247 2.03396 34.8211 2.34957 36.2617C2.7251 37.9757 3.31786 40.5159 5.17756 40.8335C7.67655 41.2603 10.2032 40.8335 12.57 39.9301C14.9369 39.0268 16.8007 38.1504 16.4995 38.1504"
              stroke="black"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </svg>

        {/*h*/}
        <svg
          className="autograph n_letter"
          height="103"
          viewBox="6 17 45 45"
          xmlns="http://www.w3.org/2000/svg"
          style={{ opacity: 0 }}
        >
          <g
            fill="none"
            fill-rule="evenodd"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="3"
            transform="translate(2 2)"
          >
            <path
              className="autograph__path"
              d="M2.69251 49.4695C6.66443 44.3201 10.393 39.009 13.9527 33.5789C17.1682 28.6739 20.0394 23.1126 21.8676 17.531C22.7227 14.9204 23.7381 12.3944 23.9902 9.64742C24.1962 7.40304 23.8482 6.00727 23.0486 3.95193C21.8549 0.883753 18.6448 3.44908 17.1597 4.84884C14.286 7.55727 12.9814 12.7165 12.608 16.4973C12.3357 19.255 11.9194 21.9962 11.8373 24.7714C11.7393 28.08 11.593 31.3921 11.2808 34.6879C10.6604 41.2382 10.4516 47.9006 10.257 54.4748C10.1623 57.6751 9.81356 60.8636 9.63254 64.0582C9.47777 66.7892 9.62488 69.5593 9.29191 72.2768C8.97043 74.9006 8.69793 77.5396 8.61968 80.1838C8.5998 80.8553 8.60881 81.5374 8.55978 82.2076C8.41822 84.1423 8.14818 78.3469 8.06921 76.4086C7.80172 69.8431 7.94729 63.4028 10.0033 57.1258C11.8033 51.63 13.9315 46.1207 19.7786 44.0251C21.1585 43.5306 23.4321 42.8656 24.7903 43.8131C25.3869 44.2294 25.7517 45.1303 26.205 45.6955C26.7896 46.4243 27.1468 47.1981 27.4886 48.0609C29.2954 52.6221 28.2094 57.9263 28.0692 62.6659C27.972 65.9503 27.9358 69.2356 27.6415 72.5083C27.4088 75.0967 27.5615 77.6164 28.2537 80.1222C29.1901 83.5123 34.2523 82.69 36.2256 80.5724C38.2879 78.3593 40.1266 74.9552 40.4144 71.9709"
              stroke="black"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </svg>

        {/*e*/}
        <svg
          className="autograph n_letter"
          height="103"
          viewBox="4 -25 45 45"
          xmlns="http://www.w3.org/2000/svg"
          style={{ opacity: 0 }}
        >
          <g
            fill="none"
            fill-rule="evenodd"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="3"
            transform="translate(2 2)"
          >
            <path
              className="autograph__path"
              d="M2 27.3098C7.40352 26.9236 12.3768 25.498 17.0685 22.9613C20.0168 21.3673 23.4982 19.4868 25.799 17.1115C27.7373 15.1104 29.4539 12.2134 29.9229 9.52611C30.2719 7.52618 29.3983 6.38456 28.0656 4.9827C25.8336 2.63506 22.2335 1.48125 18.9048 2.22351C14.803 3.13818 11.8903 6.96323 9.78609 10.0721C7.01416 14.1676 6.07885 19.7678 5.50479 24.4921C4.99151 28.7161 6.77117 32.8116 8.61083 36.5721C9.87244 39.1509 13.2387 40.8578 16.1975 40.9887C19.0047 41.1129 21.8732 40.1897 24.4453 39.2338"
              stroke="black"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </svg>

        {/*n*/}
        <svg
          className="autograph n_letter"
          height="103"
          viewBox="6 -25 45 45"
          xmlns="http://www.w3.org/2000/svg"
          style={{ opacity: 0 }}
        >
          <g
            fill="none"
            fill-rule="evenodd"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="3"
            transform="translate(2 2)"
          >
            <path
              className="autograph__path"
              d="M2 9.33671C3.63244 7.54217 7.38662 2.62366 10.0983 5.2376C10.8611 5.97291 11.0493 7.31921 11.1519 8.33963C11.504 11.8423 11.3444 15.6885 11.1519 19.1967C10.9492 22.8895 10.7738 26.6171 10.4708 30.2975C10.2739 32.6889 10.183 35.1153 9.66202 37.4544C9.48245 38.2605 9.27892 39.1395 9.27892 39.9692C9.27892 40.6593 9.08737 41.6536 9.08737 40.4013C9.08737 37.3868 9.68246 34.5695 10.2367 31.627C11.6335 24.2109 13.308 16.4564 16.5578 9.62475C17.7629 7.09155 19.3013 4.88794 21.7297 3.55365C23.0578 2.82391 25.3904 1.22368 26.9441 2.44578C28.8196 3.921 29.1704 6.02598 29.5833 8.251C30.2554 11.8733 29.7723 15.3279 29.4343 18.953C29.0725 22.8326 28.4013 26.6544 28.0934 30.5413C27.9101 32.8554 27.9406 35.7359 28.8596 37.8975C29.6028 39.6455 31.4091 40.6158 33.2014 40.4013C34.5683 40.2377 39.4308 38.3792 38.9692 36.4573"
              stroke="black"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </svg>

        {/*k*/}
        <svg
          className="autograph n_letter"
          height="103"
          viewBox="4 17 45 45"
          xmlns="http://www.w3.org/2000/svg"
          style={{ opacity: 0 }}
        >
          <g
            fill="none"
            fill-rule="evenodd"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="3"
            transform="translate(2 2)"
          >
            <path
              className="autograph__path"
              d="M2.36328 49.3359C6.3352 44.1866 10.0638 38.8755 13.6235 33.4454C16.8389 28.5404 19.7102 22.979 21.5384 17.3974C22.3934 14.7869 23.4088 12.2608 23.6609 9.51387C23.8669 7.2695 23.519 5.87372 22.7193 3.81839C21.5257 0.750209 18.3156 3.31553 16.8304 4.71529C13.9568 7.42372 12.6522 12.583 12.2788 16.3638C12.0064 19.1214 11.5902 21.8626 11.508 24.6379C11.4101 27.9465 11.2638 31.2585 10.9516 34.5544C10.3312 41.1047 10.1223 47.767 9.92777 54.3413C9.83305 57.5416 9.48433 60.7301 9.3033 63.9246C9.14854 66.6557 9.29564 69.4258 8.96268 72.1433C8.6412 74.7671 8.3687 77.4061 8.29044 80.0503C8.27057 80.7217 8.27958 81.4039 8.23055 82.074C8.08899 84.0088 7.81895 78.2133 7.73998 76.275C7.47249 69.7095 7.61806 63.2693 9.67402 56.9923C11.4741 51.4965 13.6022 45.9872 19.4494 43.8916C20.8293 43.397 23.1029 42.7321 24.4611 43.6796C25.0577 44.0958 25.4225 44.9967 25.8758 45.562C26.4603 46.2908 26.8176 47.0646 27.1594 47.9274C28.9662 52.4885 10.068 59.185 9.92777 63.9246C9.83057 67.209 15.6266 64.2271 22.6762 67.3665C25.4516 70.7771 27.2323 77.4828 27.9244 79.9886C28.8608 83.3787 33.923 82.5565 35.8964 80.4388"
              stroke="black"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </svg>

        {/*o*/}
        <svg
          className="autograph n_letter"
          height="103"
          viewBox="2 -25 45 45"
          xmlns="http://www.w3.org/2000/svg"
          style={{ opacity: 0 }}
        >
          <g
            fill="none"
            fill-rule="evenodd"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="3"
            transform="translate(2 2)"
          >
            <path
              className="autograph__path"
              d="M18.5858 8.3906C18.4169 6.56002 18.227 5.58929 16.8703 4.44621C15.9839 3.69933 15.4178 3.37971 14.2599 3.11765C10.3564 2.23428 6.92088 6.51891 5.42579 9.49635C4.40067 11.5379 3.56676 13.3908 2.99766 15.5945C2.37988 17.9867 2.25636 20.7754 2.18552 23.244C2.05338 27.8492 1.45845 32.7151 3.45345 37.0494C4.54693 39.425 6.6493 42.2737 9.37047 42.7349C9.99944 42.8415 10.66 42.9334 11.2931 42.9907C12.3563 43.087 13.2017 42.4159 14.0527 41.8767C15.5327 40.9389 16.9752 40.1953 18.2046 38.9143C20.1819 36.8538 21.439 34.248 22.4393 31.6114C24.0841 27.276 25 22.3065 25 17.6492C25 15.6129 24.5705 13.5965 23.9558 11.6583C23.4166 9.95808 22.7068 8.68382 21.0056 7.97801C17.1876 6.39395 14.5969 9.97498 12.4699 12.6981"
              stroke="black"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </svg>
      </>
    );
  };

  componentDidMount() {
    // Получаем нужный элемент
    var element = document.getElementsByClassName('autograph')[0];

    let _that = this;

    var Visible = function(target: any) {
      // Все позиции элемента
      var targetPosition = {
          top: window.pageYOffset + target.getBoundingClientRect().top,
          left: window.pageXOffset + target.getBoundingClientRect().left,
          right: window.pageXOffset + target.getBoundingClientRect().right,
          bottom: window.pageYOffset + target.getBoundingClientRect().bottom,
        },
        // Получаем позиции окна
        windowPosition = {
          top: window.pageYOffset,
          left: window.pageXOffset,
          right: window.pageXOffset + document.documentElement.clientWidth,
          bottom: window.pageYOffset + document.documentElement.clientHeight,
        };

      if (
        targetPosition.bottom > windowPosition.top && // Если позиция нижней части элемента больше позиции верхней чайти окна, то элемент виден сверху
        targetPosition.top < windowPosition.bottom - 150 && // Если позиция верхней части элемента меньше позиции нижней чайти окна, то элемент виден снизу
        targetPosition.right > windowPosition.left && // Если позиция правой стороны элемента больше позиции левой части окна, то элемент виден слева
        targetPosition.left < windowPosition.right
      ) {
        // Если позиция левой стороны элемента меньше позиции правой чайти окна, то элемент виден справа
        // Если элемент полностью видно, то запускаем следующий код
        // console.clear();
        // console.log('Вы видите элемент :)');

        if (!_that.state.show_SVG) {
          _that.showSignature();
          _that.setState(state => ({ show_SVG: true }));
        }
      } else {
        // Если элемент не видно, то запускаем этот код
        // console.clear();
      }
    };

    // Запускаем функцию при прокрутке страницы
    window.addEventListener('scroll', function() {
      Visible(element);
    });

    // А также запустим функцию сразу. А то вдруг, элемент изначально видно
    Visible(element);
  }

  showSignature = () => {
    // console.log('showSignature');

    // @ts-ignore
    this.calcPaths();

    setTimeout(() => {
      let svg = document.getElementsByClassName('autograph');
      // svg[0].style.display = "block";
      for (let index = 0; index < svg.length; index++) {
        // @ts-ignore
        svg[index].style.opacity = 1;
      }
    }, 0);
  };

  render() {
    // <button onClick={this.showSignature} >Show Signature</button>
    return <>{this.svg_folder()}</>;
  }
}

/*
        <svg
          className="autograph"
          height="103"
          viewBox="0 0 424 103"
          width="424"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            fill="none"
            fill-rule="evenodd"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="3"
            transform="translate(2 2)"
          >
            <path
              className="autograph__path"
              d="M14.9562 24.3072C13.962 26.8592 12.971 28.6808 10.6901 29.6623C9.45348 30.1944 8.1053 30.4271 6.86962 29.7426C4.67923 28.5293 3.57749 24.597 3.21617 22.0045C2.0863 13.8976 7.08262 5.51833 13.3077 2.86024C15.5217 1.91487 17.4833 1.45633 19.5453 3.04767C20.7911 4.00912 21.6021 5.80609 21.9735 7.49236C22.4217 9.52747 22.304 11.6039 22.4191 13.6774C22.58 16.5797 22.5919 19.4704 22.575 22.3794C22.5298 30.1512 20.8906 37.7455 20.6146 45.4864C20.4916 48.9357 19.9059 52.7123 21.1715 55.9555C22.4591 59.2551 26.4841 59.17 28.9463 57.9905C31.7852 56.6303 33.981 53.7366 35.8744 50.9753C38.5091 47.1332 40.6614 43.5029 42.0675 38.7658C43.4052 34.2589 44.7873 29.6718 44.8298 24.8427C44.8641 20.9466 44.6294 17.0633 44.6294 13.1687C44.6294 12.0744 44.2262 12.5913 44.1281 13.3695C43.8047 15.9353 44.0279 18.6716 44.0279 21.2548C44.0279 25.4232 43.5769 29.5575 43.4264 33.7187C43.2203 39.4158 43.2635 45.1254 42.914 50.8147C42.5289 57.0847 41.9791 63.3236 41.7333 69.6109C41.481 76.0658 41.6849 82.537 41.6108 88.9962C41.5373 95.4011 41.0632 102.079 39.8843 108.328C38.8945 113.575 37.0146 117.954 34.6938 122.492C33.6837 124.467 32.622 126.535 30.8175 127.579C28.4273 128.962 25.3271 129.16 22.7309 128.905C19.7552 128.612 16.86 127.155 14.132 125.785C11.4041 124.416 8.64947 122.874 6.47977 120.404C2.59468 115.98 1.52374 110.746 2.18029 104.472C10.6901 75.2692 59.3611 70.3846 61 55.9555"
              stroke="black"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              className="autograph__path"
              d="M7.38843 3.45419C6.89714 2.55461 6.56877 2.01343 5.52316 2.01343C5.03797 2.01343 4.49246 1.9343 4.04288 2.16018C3.32522 2.52075 3.00622 3.13631 2.66882 3.81438C2.18632 4.78405 2.13442 6.17939 2.03821 7.24284C1.91015 8.65826 2.06298 9.6917 3.13348 10.698C5.00403 12.4563 8.8908 12.5754 10.349 10.1777C10.9033 9.26617 11.0915 7.62202 10.9597 6.56248C10.7502 4.87835 9.55932 3.936 7.98585 3.81438"
              stroke="black"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              className="autograph__path"
              d="M12.2295 63.2714C11.9437 60.448 11.1769 58.7429 8.14268 58.7429C5.54063 58.7429 3.19611 63.3082 2.81537 65.3934C2.17015 68.9271 1.1662 72.6253 3.25839 75.8723C5.36734 79.1453 11.1037 78.7198 13.9795 76.977C18.7333 74.0961 23.3021 70.7691 25.5866 65.5465C28.1146 59.7671 29.6288 53.6995 31.3569 47.6406C32.3692 44.0915 33.3833 40.5442 34.4027 36.9977C35.5157 33.1253 36.3304 29.1735 37.8582 25.425C39.2932 21.9039 40.4991 18.2968 41.6349 14.6726C42.0032 13.4975 42.3906 12.3139 42.9308 11.2052C43.9219 9.17079 44.4618 6.81708 45.7218 4.92664C46.3182 4.03175 47.4533 2.71115 48.7343 3.05619C50.5742 3.55176 51.8163 6.51849 52.6994 7.9456C54.1655 10.315 54.6643 13.519 54.848 16.2587C55.1611 20.9287 54.99 25.7414 54.3385 30.3691C52.2614 45.1233 52.3006 60.1973 52.3006 75.0628C52.3006 76.2332 52.1194 77.3614 52.1013 78.5412C52.0815 79.8285 51.833 78.0292 51.8022 77.7318C51.6821 76.5694 51.5385 75.4436 51.3038 74.2972C49.8145 67.0201 48.7534 59.9682 45.0019 53.4051C40.2268 45.0512 30.8968 42.6429 21.8431 42.0511C17.3074 41.7547 13.2455 42.3043 9.01764 43.9325C7.20166 44.6319 5.41404 45.4571 4.22196 47.0499C3.25211 48.3458 3.08267 49.9164 2.85968 51.458"
              stroke="black"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              className="autograph__path"
              d="M2 9.33671C3.72068 7.54217 7.67778 2.62366 10.5361 5.2376C11.3401 5.97291 11.5384 7.31921 11.6465 8.33963C12.0177 11.8423 11.8495 15.6885 11.6465 19.1967C11.4329 22.8895 11.2481 26.6171 10.9287 30.2975C10.7211 32.6889 10.6253 35.1153 10.0762 37.4544C9.88691 38.2605 9.67237 39.1395 9.67237 39.9692C9.67237 40.6593 9.47047 41.6536 9.47047 40.4013C9.47047 37.3868 10.0977 34.5695 10.6819 31.627C12.1542 24.2109 13.9193 16.4564 17.3447 9.62475C18.6149 7.09155 20.2365 4.88794 22.7962 3.55365C24.1961 2.82391 26.6548 1.22368 28.2925 2.44578C30.2693 3.921 30.6391 6.02598 31.0742 8.251C31.7827 11.8733 31.2736 15.3279 30.9172 18.953C30.5358 22.8326 29.8285 26.6544 29.5039 30.5413C29.3106 32.8554 29.3428 35.7359 30.3115 37.8975C31.0949 39.6455 32.9988 40.6158 34.888 40.4013C36.3287 40.2377 41.454 38.3792 40.9676 36.4573"
              stroke="black"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              className="autograph__path"
              d="M22.275 11.2005C20.6694 8.06733 18.6904 3.00675 14.2817 3.00675C12.6303 3.00675 11.0765 2.88921 9.55634 3.63537C7.42605 4.681 5.42771 7.37306 4.68748 9.55307C3.54632 12.9138 3.15252 16.297 2.68915 19.8061C2.11652 24.1426 1.97058 28.4322 2.00464 32.812C2.02654 35.6284 2.78816 38.6847 4.54396 40.9624C5.82671 42.6264 7.64609 43 9.64467 43C10.9844 43 12.1926 41.66 13.0231 40.724C14.2942 39.2912 14.9148 37.8203 15.8273 36.1719C17.6238 32.9269 19.2712 29.4944 20.376 25.9514C21.6662 21.814 22.9862 17.6725 23.9531 13.444C24.3198 11.8404 24.4979 10.2137 24.8585 8.62097C25.0386 7.82536 25.0572 7.91237 25.0572 8.62097C25.0572 9.38109 24.9861 10.1198 24.9026 10.8753C24.0662 18.448 22.6277 26.2873 24.3616 33.8525C24.8989 36.1966 25.2074 39.621 27.6517 40.9407C28.9773 41.6565 30.7289 41.6033 31.5711 40.2254C32.587 38.5632 34 36.1929 34 34.221"
              stroke="black"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              className="autograph__path"
              d="M2 9.33671C3.63244 7.54217 7.38662 2.62366 10.0983 5.2376C10.8611 5.97291 11.0493 7.31921 11.1519 8.33963C11.504 11.8423 11.3444 15.6885 11.1519 19.1967C10.9492 22.8895 10.7738 26.6171 10.4708 30.2975C10.2739 32.6889 10.183 35.1153 9.66202 37.4544C9.48245 38.2605 9.27892 39.1395 9.27892 39.9692C9.27892 40.6593 9.08737 41.6536 9.08737 40.4013C9.08737 37.3868 9.68246 34.5695 10.2367 31.627C11.6335 24.2109 13.308 16.4564 16.5578 9.62475C17.7629 7.09155 19.3013 4.88794 21.7297 3.55365C23.0578 2.82391 25.3904 1.22368 26.9441 2.44578C28.8196 3.921 29.1704 6.02598 29.5833 8.251C30.2554 11.8733 29.7723 15.3279 29.4343 18.953C29.0725 22.8326 28.4013 26.6544 28.0934 30.5413C27.9101 32.8554 27.9406 35.7359 28.8596 37.8975C29.6028 39.6455 31.4091 40.6158 33.2014 40.4013C34.5683 40.2377 39.4308 38.3792 38.9692 36.4573"
              stroke="black"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />

            <path
              className="autograph__path"
              d="M19 8.8967C18.7781 6.67903 17.9897 4.51042 16.4995 2.9478C15.7137 2.1239 13.4517 1.68429 12.4807 2.26477C11.7275 2.71505 10.8969 2.77684 10.1092 3.14609C8.31971 3.98493 7.64196 5.43289 6.66598 7.25524C5.03273 10.3048 4.28864 13.6855 3.63953 17.137C3.13937 19.7966 2.49184 22.4213 2.17096 25.113C1.90035 27.3829 2.03204 29.7207 2.03204 32.0093C2.03204 33.5247 2.03396 34.8211 2.34957 36.2617C2.7251 37.9757 3.31786 40.5159 5.17756 40.8335C7.67655 41.2603 10.2032 40.8335 12.57 39.9301C14.9369 39.0268 16.8007 38.1504 16.4995 38.1504"
              stroke="black"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              className="autograph__path"
              d="M2 27.3098C7.40352 26.9236 12.3768 25.498 17.0685 22.9613C20.0168 21.3673 23.4982 19.4868 25.799 17.1115C27.7373 15.1104 29.4539 12.2134 29.9229 9.52611C30.2719 7.52618 29.3983 6.38456 28.0656 4.9827C25.8336 2.63506 22.2335 1.48125 18.9048 2.22351C14.803 3.13818 11.8903 6.96323 9.78609 10.0721C7.01416 14.1676 6.07885 19.7678 5.50479 24.4921C4.99151 28.7161 6.77117 32.8116 8.61083 36.5721C9.87244 39.1509 13.2387 40.8578 16.1975 40.9887C19.0047 41.1129 21.8732 40.1897 24.4453 39.2338"
              stroke="black"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              className="autograph__path"
              d="M2 9.33671C3.63244 7.54217 7.38662 2.62366 10.0983 5.2376C10.8611 5.97291 11.0493 7.31921 11.1519 8.33963C11.504 11.8423 11.3444 15.6885 11.1519 19.1967C10.9492 22.8895 10.7738 26.6171 10.4708 30.2975C10.2739 32.6889 10.183 35.1153 9.66202 37.4544C9.48245 38.2605 9.27892 39.1395 9.27892 39.9692C9.27892 40.6593 9.08737 41.6536 9.08737 40.4013C9.08737 37.3868 9.68246 34.5695 10.2367 31.627C11.6335 24.2109 13.308 16.4564 16.5578 9.62475C17.7629 7.09155 19.3013 4.88794 21.7297 3.55365C23.0578 2.82391 25.3904 1.22368 26.9441 2.44578C28.8196 3.921 29.1704 6.02598 29.5833 8.251C30.2554 11.8733 29.7723 15.3279 29.4343 18.953C29.0725 22.8326 28.4013 26.6544 28.0934 30.5413C27.9101 32.8554 27.9406 35.7359 28.8596 37.8975C29.6028 39.6455 31.4091 40.6158 33.2014 40.4013C34.5683 40.2377 39.4308 38.3792 38.9692 36.4573"
              stroke="black"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />




            <path
              className="autograph__path"
              d="M2.69251 49.4695C6.66443 44.3201 10.393 39.009 13.9527 33.5789C17.1682 28.6739 20.0394 23.1126 21.8676 17.531C22.7227 14.9204 23.7381 12.3944 23.9902 9.64742C24.1962 7.40304 23.8482 6.00727 23.0486 3.95193C21.8549 0.883753 18.6448 3.44908 17.1597 4.84884C14.286 7.55727 12.9814 12.7165 12.608 16.4973C12.3357 19.255 11.9194 21.9962 11.8373 24.7714C11.7393 28.08 11.593 31.3921 11.2808 34.6879C10.6604 41.2382 10.4516 47.9006 10.257 54.4748C10.1623 57.6751 9.81356 60.8636 9.63254 64.0582C9.47777 66.7892 9.62488 69.5593 9.29191 72.2768C8.97043 74.9006 8.69793 77.5396 8.61968 80.1838C8.5998 80.8553 8.60881 81.5374 8.55978 82.2076C8.41822 84.1423 8.14818 78.3469 8.06921 76.4086C7.80172 69.8431 7.94729 63.4028 10.0033 57.1258C11.8033 51.63 13.9315 46.1207 19.7786 44.0251C21.1585 43.5306 23.4321 42.8656 24.7903 43.8131C25.3869 44.2294 25.7517 45.1303 26.205 45.6955C26.7896 46.4243 27.1468 47.1981 27.4886 48.0609C29.2954 52.6221 28.2094 57.9263 28.0692 62.6659C27.972 65.9503 27.9358 69.2356 27.6415 72.5083C27.4088 75.0967 27.5615 77.6164 28.2537 80.1222C29.1901 83.5123 34.2523 82.69 36.2256 80.5724C38.2879 78.3593 40.1266 74.9552 40.4144 71.9709"
              stroke="black"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              className="autograph__path"
              d="M2.36328 49.3359C6.3352 44.1866 10.0638 38.8755 13.6235 33.4454C16.8389 28.5404 19.7102 22.979 21.5384 17.3974C22.3934 14.7869 23.4088 12.2608 23.6609 9.51387C23.8669 7.2695 23.519 5.87372 22.7193 3.81839C21.5257 0.750209 18.3156 3.31553 16.8304 4.71529C13.9568 7.42372 12.6522 12.583 12.2788 16.3638C12.0064 19.1214 11.5902 21.8626 11.508 24.6379C11.4101 27.9465 11.2638 31.2585 10.9516 34.5544C10.3312 41.1047 10.1223 47.767 9.92777 54.3413C9.83305 57.5416 9.48433 60.7301 9.3033 63.9246C9.14854 66.6557 9.29564 69.4258 8.96268 72.1433C8.6412 74.7671 8.3687 77.4061 8.29044 80.0503C8.27057 80.7217 8.27958 81.4039 8.23055 82.074C8.08899 84.0088 7.81895 78.2133 7.73998 76.275C7.47249 69.7095 7.61806 63.2693 9.67402 56.9923C11.4741 51.4965 13.6022 45.9872 19.4494 43.8916C20.8293 43.397 23.1029 42.7321 24.4611 43.6796C25.0577 44.0958 25.4225 44.9967 25.8758 45.562C26.4603 46.2908 26.8176 47.0646 27.1594 47.9274C28.9662 52.4885 10.068 59.185 9.92777 63.9246C9.83057 67.209 15.6266 64.2271 22.6762 67.3665C25.4516 70.7771 27.2323 77.4828 27.9244 79.9886C28.8608 83.3787 33.923 82.5565 35.8964 80.4388"
              stroke="black"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              className="autograph__path"
              d="M18.5858 8.3906C18.4169 6.56002 18.227 5.58929 16.8703 4.44621C15.9839 3.69933 15.4178 3.37971 14.2599 3.11765C10.3564 2.23428 6.92088 6.51891 5.42579 9.49635C4.40067 11.5379 3.56676 13.3908 2.99766 15.5945C2.37988 17.9867 2.25636 20.7754 2.18552 23.244C2.05338 27.8492 1.45845 32.7151 3.45345 37.0494C4.54693 39.425 6.6493 42.2737 9.37047 42.7349C9.99944 42.8415 10.66 42.9334 11.2931 42.9907C12.3563 43.087 13.2017 42.4159 14.0527 41.8767C15.5327 40.9389 16.9752 40.1953 18.2046 38.9143C20.1819 36.8538 21.439 34.248 22.4393 31.6114C24.0841 27.276 25 22.3065 25 17.6492C25 15.6129 24.5705 13.5965 23.9558 11.6583C23.4166 9.95808 22.7068 8.68382 21.0056 7.97801C17.1876 6.39395 14.5969 9.97498 12.4699 12.6981"
              stroke="black"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </svg>

        <svg
          className="autograph"
          height="103"
          viewBox="0 0 224 103"
          width="104"
          xmlns="http://www.w3.org/2000/svg"
          style={{ opacity: 1 }}
        >
          <g
            fill="none"
            fill-rule="evenodd"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="3"
            transform="translate(2 2)"
          >
            <path
              className="autograph__path"
              d="m1 73C11.7625 87.5131 22.0446 93.1094 39.9444 97.6111C50.8494 100.354 70.8793 105.811 80.6667 96.6111C84.2199 93.2711 88.4362 89.7326 90.9444 85.5C96.2946 76.4716 97.3312 65.4189 99.2222 55.3333C100.618 47.8888 95.942 39.8073 91.9444 33.6111C85.9894 24.3808 78.946 15.5278 71 8C60.644 -1.8109 49.6459 0.933096 37.4444 5.77778C25.4057 10.5579 20 27.4871 20 39.5C20 43.8766 18.4732 51.0646 20.2222 55C21.6873 58.2964 22 60.9121 22 64.5C22 71.5926 18.6 79.6317 26 84.3889C31.7784 88.1036 35.2757 92.3391 42.5 93.9444C46.5427 94.8428 60.2034 94.8134 61 98"
              stroke="black"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </svg>
        <svg
          className="autograph"
          height="103"
          viewBox="0 0 224 103"
          width="104"
          xmlns="http://www.w3.org/2000/svg"
          style={{ opacity: 1 }}
        >
          <g
            fill="none"
            fill-rule="evenodd"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="3"
            transform="translate(2 2)"
          >
            <path
              className="autograph__path"
              d="m1 73C11.7625 87.5131 22.0446 93.1094 39.9444 97.6111C50.8494 100.354 70.8793 105.811 80.6667 96.6111C84.2199 93.2711 88.4362 89.7326 90.9444 85.5C96.2946 76.4716 97.3312 65.4189 99.2222 55.3333C100.618 47.8888 95.942 39.8073 91.9444 33.6111C85.9894 24.3808 78.946 15.5278 71 8C60.644 -1.8109 49.6459 0.933096 37.4444 5.77778C25.4057 10.5579 20 27.4871 20 39.5C20 43.8766 18.4732 51.0646 20.2222 55C21.6873 58.2964 22 60.9121 22 64.5C22 71.5926 18.6 79.6317 26 84.3889C31.7784 88.1036 35.2757 92.3391 42.5 93.9444C46.5427 94.8428 60.2034 94.8134 61 98"
              stroke="black"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </svg>
      */
