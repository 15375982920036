import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { setIsStarted, changeLanguage, contactFormHighlight } from './actions';
import { IState } from './interfaces';

import { EN, RU, UA } from '../../../constants/applications';

const INITIAL_STATE: IState = {
  isStarted: false,
  language: EN,
  isContactFormInputsHighlight: null,
};

const reducer = reducerWithInitialState<IState>(INITIAL_STATE)
  .case(setIsStarted, state => ({
    ...state,
    isStarted: true,
  }))
  .case(contactFormHighlight, (state, payload) => {

    // console.log("state - ", state);
    // debugger;
    
    return({
      ...state,
      isContactFormInputsHighlight: payload,
    });
  })
  .case(
    changeLanguage, (state, payload) => {
      // debugger;
      return({
        ...state,
        language: payload,
      });
    }
  );

export default reducer;
