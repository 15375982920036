import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyC1x-9iXuTHDnANUT5TC3F7iq8DLGXflQU",
  authDomain: "react-chat-940b3.firebaseapp.com",
  databaseURL: "https://react-chat-940b3.firebaseio.com",
  projectId: "react-chat-940b3",
  storageBucket: "react-chat-940b3.appspot.com",
  messagingSenderId: "624313490088",
  appId: "1:624313490088:web:99b9f949891b1daab7009d",
  measurementId: "G-9V978RPJYD"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// firebase.analytics();

export default firebase;
