import React, { FunctionComponent, useEffect } from 'react';
// components
// import AccountProperties from './AccountProperties';
// import AccountDetails from './AccountDetails';
// import ChangePassword from './ChangePassword';
// interface
import { IProps } from './interfaces';
// styles
// import styles from './Account.module.scss';
// routes
import history from 'helpers/history';

// import { SIGNUP } from 'constants/routes';
import { HOME } from 'constants/routes';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch,
} from 'react-router-dom';

import 'antd/dist/antd.css';
import './index.css';

import { Layout } from 'antd';

import AdminHeader from './Header';
import ContentPage from './Content';
import SiderMenu from './SiderMenu';

import Chat from 'pages/Chat';

const { Footer } = Layout;

/**
 * https://ant.design/components/menu/
 *
 * About router links and nesting routs:
 * - staging6/.../src/components/common/Header => NavLink
 * - https://reacttraining.com/react-router/web/example/nesting => Nesting routs
 */
class AccountMainPage extends React.Component {
  state = {
    collapsed: false,
  };

  onCollapse = collapsed => {
    console.log(collapsed);
    this.setState({ collapsed });
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    return (
      <Layout style={{ minHeight: '100vh' }}>
        <SiderMenu collapsed={this.state.collapsed} onCollapse={this.onCollapse} />

        {/*End menu and start content part of the page*/}
        <Layout className="site-layout">
          <AdminHeader toggle={this.toggle} collapsed={this.state.collapsed} />

          {/* Only this part must be switched 
              <h1>Hello test_2</h1>
          */}
          <Switch>
            <Route exact path="/account/test_1">
              <ContentPage />
            </Route>
            <Route path="/account/test_2">
              <Chat />
            </Route>
          </Switch>
          {/* //// Only this part must be switched... */}

          <Footer style={{ textAlign: 'center' }}>Design ©2020 Created by Garry -)</Footer>
        </Layout>
      </Layout>
    );
  }
}

export default AccountMainPage;
/**
 *
 * @param param0
 const Account: FunctionComponent<IProps> = ({ user = 'garry' }: IProps) => {
   // const Account: FunctionComponent<IProps> = ({ user = null }: IProps) => {
     useEffect(() => {
       if (user) {
         // getUserData({});
        } else {
          history.push(HOME);
        }
      }, []);
      
      return (
        <>
        <h3>My account</h3>
        </>
        );
      };
      
      export default Account;
     */
