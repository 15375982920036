import { SagaIterator } from 'redux-saga';
import { takeEvery, call, put, select } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';

import history from 'helpers/history';

import { HOME } from 'constants/routes';

import { logIn, sentContactMessage } from './actions';

import { postRequest, getRequest } from 'api/rest';
import { getPosts, setContactMessage } from 'api/firebase';

import { ILogInData, ISentMessageData } from 'models/User';

const logInRequest = (data: ILogInData) => postRequest('/api/User/Login', data);

const sentContactMessageRequest = (data: ISentMessageData) =>
  postRequest('/api/CustomerService/contact', data);

// firebase api
function* sentContactMessageWorker(action: Action<ISentMessageData>): SagaIterator {
  try {
    // @ts-ignore
    const response = yield call(setContactMessage, action.payload);

    // @ts-ignore
    if (response) {
      yield put(sentContactMessage.done({ params: action.payload, result: true }));
    }
  } catch (error) {
    console.log('Error: ', error);
    // @ts-ignore
    yield put(sentContactMessage.failed({ params: action.payload, error: 'Sorry...' }));
    // yield put(logIn.failed({ params: action.payload, error: 'Sorry...' }));
  }
}

/*  
// rest api
function* sentContactMessageWorker(action: Action<ISentMessageData>): SagaIterator {
  try {
    const response = yield call(sentContactMessageRequest, action.payload);

    // @ts-ignore
    if (response.status === 200) {
      yield put(sentContactMessage.done({ params: action.payload, result: true }));
    }
  } catch (error) {
    console.log('Error: ', error);
    // @ts-ignore
    yield put(logIn.failed({ params: action.payload, error: 'Sorry...' }));
  }
}
*/

function* logInWorker(action: Action<ILogInData>): SagaIterator {
  try {
    const response = yield call(logInRequest, action.payload);

    // @ts-ignore
    if (response.status === 200) {
      // @ts-ignore
      yield put(logIn.done({ params: action.payload, result: response.data }));

      // console.log( logIn.done({ params: action.payload, result: response.data }) ); debugger  // => {type: "auth/LOG_IN_DONE", payload: {…}}

      /*
      const address = history.location.state && history.location.state.address;
      const lastLocation = history.location.state && history.location.state.lastLocation;

      if (address) {
        // history.push(LISTING_TYPE);
      } else if (lastLocation) {
        history.push(lastLocation);
      } else {
        history.push(HOME);
      }
      */
    } else {
      yield put(logIn.failed({ params: action.payload, error: 'Login error occured!' }));
    }
  } catch (error) {
    if (error.message.includes('401')) {
      yield put(logIn.failed({ params: action.payload, error: 'Wrong password or email' }));
    }
    console.log('error', error.message);
  }
}

export default function* root(): SagaIterator {
  yield takeEvery(logIn.started, logInWorker);
  yield takeEvery(sentContactMessage.started, sentContactMessageWorker);
}
