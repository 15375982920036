// import React, { Component } from 'react';
import React, { Component, Suspense } from 'react';
// import { useTranslation, withTranslation, Trans } from 'react-i18next';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Router from './router';
import config from 'redux/config';

import LettersPreloader from './components/common/Preloaders/LettersPreloader';
import { GLOBAL, LOADING } from './constants/preloaders';

const { store, persistor } = config;

// loading component for suspense fallback
class App extends Component {
  render() {
    return (
      <>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Suspense fallback={<LettersPreloader lettersArr={LOADING} scope={GLOBAL} />}>
              <Router />
            </Suspense>
          </PersistGate>
        </Provider>
      </>
    );
  }
}

export default App;

/*
  <div className="App">
    <div>loading...</div>
  </div>
*/
