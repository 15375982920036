import React, { useState, FunctionComponent } from 'react';
import { ISentMessageData } from 'models/User';

import './MainHeader.css';

import BannerOne from './BannerOne';
import SidebarMenu from './SidebarMenu';
import ClosedMenu from './ClosedMenu';

interface IProps {
  changeLanguage: (data: string) => void; // aplication => actions (string - "en" | "ru")
  appLanguage: string; // state => application => language ("en" | "ru")
  sentContactMessage: (data: ISentMessageData) => boolean; // async action
  isContactMessageLoad: boolean; // State => auth => isContactMessageLoad => false | true (when is loading)
  isUserSentContactMessageEarly: [boolean, number]; // user sent data earlier?, how many times
  error: any;
  isContactFormInputsHighlight: any;
}

const MainHeader: FunctionComponent<IProps> = ({
  changeLanguage,
  appLanguage,
  sentContactMessage,
  isContactMessageLoad,
  isUserSentContactMessageEarly,
  error,
  isContactFormInputsHighlight,
}: IProps) => {
  // console.log(error); // {contactMessage: false}
  // debugger;

  const [menuIsOpen, setMenuIsOpen] = useState(true);
  const [subMenuIsOpen, setSubMenuIsOpen] = useState(false);

  const openMenu = () => {
    setMenuIsOpen(true);
  };

  const closeMenu = () => {
    setMenuIsOpen(false);
  };

  const toggleSubMenu = () => {
    setSubMenuIsOpen(!subMenuIsOpen);
  };

  return (
    <>
      <div className="op-banner-one">
        <ClosedMenu appLanguage={appLanguage} openMenu={openMenu} changeLanguage={changeLanguage} />
        <BannerOne
          sentContactMessage={sentContactMessage}
          isContactMessageLoad={isContactMessageLoad}
          isUserSentContactMessageEarly={isUserSentContactMessageEarly}
          error={error}
          isContactFormInputsHighlight={isContactFormInputsHighlight}
        />
        <SidebarMenu
          menuIsOpen={menuIsOpen}
          subMenuIsOpen={subMenuIsOpen}
          closeMenu={closeMenu}
          toggleSubMenu={toggleSubMenu}
          appLanguage={appLanguage}
          changeLanguage={changeLanguage}
        />
      </div>
    </>
  );
};

export default MainHeader;
