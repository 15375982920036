// import React from 'react';
import React, { Component, Suspense } from 'react';

import { useTranslation, withTranslation, Trans } from 'react-i18next';

import './About.css';

import Signature from '../Signature';


import Experience from './Experience';
// import experience from './experience.html';
// var experience = require('./experience.html');


// import AboutImg from '../../images/home/face.png';
// import ReactImg from '../../../images/icon/react.png';
import AboutImg from '../../../images/home/face.png';
// import partnerAvatarKorman from '../../../images/home/avatarKorman.jpg';
import partnerAvatarKorman from '../../../images/home/face_img.png';

import { ReactComponent as SkypeSVG } from './skype.svg';
import { ReactComponent as TelegramSVG } from './telegram.svg';
import { ReactComponent as ViberSVG } from './viber.svg';
import { ReactComponent as WhatsappSVG } from './whatsapp.svg';

// import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
// import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
// import 'react-circular-progressbar/dist/styles.css';

import { Element } from 'react-scroll';


/**
 * https://www.npmjs.com/package/react-circular-progressbar
 */
/**
 * style="position: absolute;bottom: 100px;left: 80px;color: white;font-size: 3.5rem;"
 */

function About() {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Element name="profile" className="element"></Element>
      <div className="op-about-us-one">
        <div className="upper-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-5">
                <h2 className="theme-title-four title-four-media">
                  {/*
                  Build idea for <span>your goal</span>.
                  */}
                  <Trans i18nKey="About.main_title_1_part">
                    We’r highly <br />
                  </Trans>
                  <Trans i18nKey="About.main_title_2_part">
                    <span>Expert & Idea</span> <br /> Builder.
                  </Trans>
                </h2>
              </div>
              <div className="col-lg-6 ml-auto">
                <div className="top-text">
                  <p className="font-lato">{t('About.main_text')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            {/*https://codepen.io/team/jotform/full/XWmqoMp*/}

            {/*
                        <div className="col-lg-6">
              <div className="img-box">
                <img src={AboutImg} alt="" />
                <div
                  style={{
                    position: 'absolute',
                    bottom: '100px',
                    left: '80px',
                    color: 'white',
                    fontSize: '3.5rem',
                  }}
                >
                  test information
                </div>

                <div className="text">{t('About.welcome')}</div>
              </div>
              </div>
              box-shadow: 0px 30px 50px 0px rgba(138, 138, 138, 0.1);

              <Index />
            */}

            <div className="col-lg-6">
              <Index t={t} />
            </div>

            <div className="col-lg-6">
              <div className="bottom-text">
                <h4 className="profileTitle">{t('About.profile.title')}</h4>

                <p className="font-lato">
                  {t('About.profile.text_1')}
                </p>

                <p className="font-lato">
                  <b>{t('About.profile.text_2')}</b>. {t('About.profile.text_3')}.
                </p>

                <p className="font-lato">
                  {t('About.profile.text_4')}
                </p>

                <div className="signaturePosition">
                  <Signature />
                </div>

                {/*
                <p className="font-lato">{t('About.profile.text_1')}</p>

                <p className="font-lato">
                  Detail oriented professional with experience in JavaScript, TypeScript, ReactJS,
                  PHP and many other tehnologys. Seeking to take the next career step with respected
                  organization dedicated to world class quality.
                </p>

                <p className="font-lato">
                  I am looking for a company willing to offer me a placement among their developers.
                  In return, I would offer my full commitment, and be a pleasant and friendly
                  addition to you team. I am therefore currently looking for a job as a front-end
                  developer (prefer to work with React / redux-saga), or as full stack developer (on
                  backend prefer to work with Laravel).
                </p>

                <p className="font-lato">
                  After all my work experience, I do think my most strongest skills are in
                  realization sophisticated software logic using the modern powerful technology.
                </p>
                */}

                {/*
                <h6 className="name">
                  Yuriy (Garry*) <br /> *for eng. speaking
                </h6>
                <div className="signaturePosition">
                  <div className="signaturePos">
                    <Signature />
                  </div>
                </div>
                <a href="#" className="more-button">
                  More about me <i className="flaticon-next-1"></i>
                </a>
                <p>
                  If you trieng ti build something, something that matters. You hawe a smart team
                  and you want to be fast. Fast means beating your competitors to market. Fast means
                  attracting the best talent to your team.
                </p>
                <p>
                  Active participation in the development process through the entire software
                  life-cycle under Agile methodology.
                </p>
                <p>
                  Analysis of UX and design requirements and transforming it into functional
                  components.
                </p>
                <p>
                  Started new web project from scratch, chosing technologies and code structure,
                  its' development, configuration and deployment to production.
                </p>
                <p>
                  Development with modern technologies such as ReactJS, redux, redux-saga, Sass,
                  flow, webscoket, REST, git, Babel, webpack, HTML5, ES6, Bootstrap, Materialize,
                  docker, nginx and constantly learning new...
                </p>
                <p>
                  Detail oriented professional with experience in SERVER MANUFACTURING, TRAINING
                  DEVELOPMENT, COMPUTER HARDWARE TROUBLESHOOTING and MANAGEMENT. Seeking to take the
                  next career step with respected organization dedicated to world class quality.
                  Born leader with the ability to lead teams to process and manage large volumes
                  without compromising service or quality.
                </p>
                */}

                {/*
                <p>*for english speaking people</p>
                <img src="images/home/sign4.png" alt="" />

                <Signature />

                <a href="#" className="more-button">
                  More about us <i className="flaticon-next-1"></i>
                </a>
                */}
              </div>
            </div>
          </div>

          <div
            class="theme-title-three text-center theme-title-three-pd"
          >
            {/*
            <p>{t('About.welcome')}</p>
            */}
            <h2 class="title welcomTitle">{t('About.welcome')}</h2>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;










/**
 *
 */
class Index extends React.Component {

  constructor(props) {
    super();
    this.translate = props.t;
  }

  state = {
    about: true,
    experience: false,
    contact: false,
  };

  changeSections = (e, section) => {
    this.setState(state => ({ about: false, experience: false, contact: false, [section]: true }));
    this.handleButtonClick(e);
  };

  handleButtonClick = e => {
    const card = document.querySelector('.card');
    const targetSection = e.target.getAttribute('data-section');
    targetSection !== '#about'
      ? card.classList.add('is-active')
      : card.classList.remove('is-active');
    card.setAttribute('data-state', targetSection);
  };

  render() {
    return (
      <>
        <div className="card" data-state="#about">
          <div className="card-header">
            <div className="card-cover"></div>

            <img className="card-avatar" src={partnerAvatarKorman} alt="avatar" />
            {/* <h1 className="card-fullname">Yuriy (Garry) Ananchenko</h1>
            <h2 className="card-jobtitle">Developer (frontend / backend)</h2> */}

            <h1 className="card-fullname">{this.translate('About.about_card.title')}</h1>
            <h2 className="card-jobtitle">{this.translate('About.about_card.sub_title')}</h2>
          </div>

          <div className="card-main">
            <div className={`card-section ${this.state.about && 'is-active'}`} id="about">
              <div className="card-content">
                <div className="cardContentAbout">
                  
                  <div className="card-subtitle">{this.translate('About.about_card.about_title')}</div>
                  <p className="card-desc">
                    {this.translate('About.about_card.about_text')}
                  </p>
                  {/*
                  <div className="card-subtitle">ABOUT</div>
                  <p className="card-desc">
                    Hello! I am Yuriy and I am a software engineer working on web applications and
                    web infrastructure. I have been developing professionally for more then 5 years
                    but tinkering since a kid.
                  </p>
                  <p className="card-desc">
                    Proactive person, eager to learn and always developing new skills. I like
                    involving in state-of-the-art projects. Currently growing as a person, and as a
                    professional.
                  </p>
                  <p className="card-desc">
                    I am hardworking professional who contribute to the success of the company
                    through efficient use of my technical, communication, engineering knowledge and
                    interpersonal skills.
                  </p>
                  */}
                </div>
              </div>
            </div>



            <div className={`card-section ${this.state.experience && 'is-active'}`} id="experience">
              <Experience />
            </div>




            <div className={`card-section ${this.state.contact && 'is-active'}`} id="contact">
              <div className="card-content">
                <div className="card-subtitle">{ this.translate('Contacts.title_1').toUpperCase() }</div>
                <div className="card-contact-wrapper">
                  {/*
                                      <div className="card-contact">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewbox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path d="M22 16.92v3a2 2 0 01-2.18 2 19.79 19.79 0 01-8.63-3.07 19.5 19.5 0 01-6-6 19.79 19.79 0 01-3.07-8.67A2 2 0 014.11 2h3a2 2 0 012 1.72 12.84 12.84 0 00.7 2.81 2 2 0 01-.45 2.11L8.09 9.91a16 16 0 006 6l1.27-1.27a2 2 0 012.11-.45 12.84 12.84 0 002.81.7A2 2 0 0122 16.92z" />
                    
                    </svg>
                    (269) 756-9809
                  </div>
                    */}
                  <div className="card-contact">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0118 0z" />
                      <circle cx="12" cy="10" r="3" />
                    </svg>
                    { this.translate('Contacts.text_1') }
                  </div>
                  <div className="card-contact">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path d="M22 16.92v3a2 2 0 01-2.18 2 19.79 19.79 0 01-8.63-3.07 19.5 19.5 0 01-6-6 19.79 19.79 0 01-3.07-8.67A2 2 0 014.11 2h3a2 2 0 012 1.72 12.84 12.84 0 00.7 2.81 2 2 0 01-.45 2.11L8.09 9.91a16 16 0 006 6l1.27-1.27a2 2 0 012.11-.45 12.84 12.84 0 002.81.7A2 2 0 0122 16.92z" />
                    </svg>
                    +38095 136 0 138
                  </div>

                  <div className="card-contact">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
                      <path d="M22 6l-10 7L2 6" />
                    </svg>
                    work.fse@gmail.com
                  </div>

                  <div className="card-contact">
                    <SkypeSVG />
                    yuriy.evl (skype)
                  </div>

                  <div className="card-contact">
                    <TelegramSVG />
                    +38095 136 0 138 (telegram)
                  </div>

                  <div className="card-contact">
                    <ViberSVG />
                    +38095 136 0 138 (viber)
                  </div>

                  <div className="card-contact">
                    <WhatsappSVG />
                    +38095 136 0 138 (whatsap)
                  </div>
                  {/*

                  skypeSVG
                    <button className="contact-me">WORK TOGETHER</button>
                  */}
                </div>
              </div>
            </div>
          </div>
          <div className="card-buttons">
            <button
              data-section="#about"
              className={`${this.state.about && 'is-active'}`}
              onClick={e => this.changeSections(e, 'about')}
            >
              {this.translate('About.about_card.about_title')}
            </button>
            <button
              data-section="#experience"
              className={`${this.state.experience && 'is-active'}`}
              onClick={e => this.changeSections(e, 'experience')}
            >
              {this.translate('About.about_card.experience_title')}
            </button>
            <button
              data-section="#contact"
              className={`${this.state.contact && 'is-active'}`}
              onClick={e => this.changeSections(e, 'contact')}
            >
              {this.translate('About.about_card.contacts_title')}
            </button>
          </div>
        </div>
      </>
    );
  }
}

/*
I recently befan to be fascinated by web programming, e.g. developing apps and building websites. Invited to join my frands start-up company as a front-end developer, I gained experience of working in this area.
*/

/*
              <div className="card-social">
                <a href="#">
                  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.997 3.985h2.191V.169C17.81.117 16.51 0 14.996 0c-3.159 0-5.323 1.987-5.323 5.639V9H6.187v4.266h3.486V24h4.274V13.267h3.345l.531-4.266h-3.877V6.062c.001-1.233.333-2.077 2.051-2.077z" />
                  </svg>
                </a>
                <a href="#">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M512 97.248c-19.04 8.352-39.328 13.888-60.48 16.576 21.76-12.992 38.368-33.408 46.176-58.016-20.288 12.096-42.688 20.64-66.56 25.408C411.872 60.704 384.416 48 354.464 48c-58.112 0-104.896 47.168-104.896 104.992 0 8.32.704 16.32 2.432 23.936-87.264-4.256-164.48-46.08-216.352-109.792-9.056 15.712-14.368 33.696-14.368 53.056 0 36.352 18.72 68.576 46.624 87.232-16.864-.32-33.408-5.216-47.424-12.928v1.152c0 51.008 36.384 93.376 84.096 103.136-8.544 2.336-17.856 3.456-27.52 3.456-6.72 0-13.504-.384-19.872-1.792 13.6 41.568 52.192 72.128 98.08 73.12-35.712 27.936-81.056 44.768-130.144 44.768-8.608 0-16.864-.384-25.12-1.44C46.496 446.88 101.6 464 161.024 464c193.152 0 298.752-160 298.752-298.688 0-4.64-.16-9.12-.384-13.568 20.832-14.784 38.336-33.248 52.608-54.496z" />
                  </svg>
                </a>
                <a href="#">
                  <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                    <path d="M301 256c0 24.852-20.148 45-45 45s-45-20.148-45-45 20.148-45 45-45 45 20.148 45 45zm0 0" />
                    <path d="M332 120H180c-33.086 0-60 26.914-60 60v152c0 33.086 26.914 60 60 60h152c33.086 0 60-26.914 60-60V180c0-33.086-26.914-60-60-60zm-76 211c-41.355 0-75-33.645-75-75s33.645-75 75-75 75 33.645 75 75-33.645 75-75 75zm86-146c-8.285 0-15-6.715-15-15s6.715-15 15-15 15 6.715 15 15-6.715 15-15 15zm0 0" />
                    <path d="M377 0H135C60.562 0 0 60.563 0 135v242c0 74.438 60.563 135 135 135h242c74.438 0 135-60.563 135-135V135C512 60.562 451.437 0 377 0zm45 332c0 49.625-40.375 90-90 90H180c-49.625 0-90-40.375-90-90V180c0-49.625 40.375-90 90-90h152c49.625 0 90 40.375 90 90zm0 0" />
                  </svg>
                </a>
                <a href="#">
                  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M23.994 24v-.001H24v-8.802c0-4.306-.927-7.623-5.961-7.623-2.42 0-4.044 1.328-4.707 2.587h-.07V7.976H8.489v16.023h4.97v-7.934c0-2.089.396-4.109 2.983-4.109 2.549 0 2.587 2.384 2.587 4.243V24zM.396 7.977h4.976V24H.396zM2.882 0C1.291 0 0 1.291 0 2.882s1.291 2.909 2.882 2.909 2.882-1.318 2.882-2.909A2.884 2.884 0 002.882 0z" />
                  </svg>
                </a>
              </div>
              */

/*
  componentDidMount() {
    const buttons = document.querySelectorAll('.card-buttons button');
    const sections = document.querySelectorAll('.card-section');
    const card = document.querySelector('.card');
    
    const handleButtonClick = e => {
      const targetSection = e.target.getAttribute('data-section');
      const section = document.querySelector(targetSection);
      targetSection !== '#about'
        ? card.classList.add('is-active')
        : card.classList.remove('is-active');
        card.setAttribute('data-state', targetSection);
      sections.forEach(s => s.classList.remove('is-active'));
      buttons.forEach(b => b.classList.remove('is-active'));
      e.target.classList.add('is-active');
      section.classList.add('is-active');
    };
    
    buttons.forEach(btn => {
      btn.addEventListener('click', handleButtonClick);
    });
  }
  */

/*
    style={{
    backgroundImage: `url(${AboutImg})`,
    color: 'red',
    marginLeft: '30px',
  }}
  */
