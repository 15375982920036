import React from 'react';
import { Header, Segment, Input, Icon } from 'semantic-ui-react';
import { Tabs, Layout, Card, Button, Tooltip, Descriptions } from 'antd';
import {
  AppleOutlined,
  AndroidOutlined,
  SearchOutlined,
  WechatOutlined,
  CommentOutlined,
  CheckCircleTwoTone,
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoOutlined,
  InfoCircleTwoTone,
  InfoCircleOutlined,
  AlertOutlined,
} from '@ant-design/icons';

/*

*/

const { TabPane } = Tabs;

class MessagesHeader extends React.Component {
  render() {
    const {
      channelName,
      numUniqueUsers,
      handleSearchChange,
      searchLoading,
      isPrivateChannel,
      handleStar,
      isChannelStarred,
    } = this.props;

    return (
      <>
        <Card>
          <Tabs defaultActiveKey="1">
            {/*-----------------*/}
            <TabPane
              tab={
                <span>
                  <CommentOutlined />
                  General
                </span>
              }
              key="1"
            >
              <Descriptions>
                <Descriptions.Item label="Сhat name">
                  {!isPrivateChannel && (
                    <Icon
                      name={isChannelStarred ? 'star' : 'star outline'}
                      color={isChannelStarred ? 'yellow' : 'black'}
                    />
                  )}
                  <b>{channelName}</b>
                </Descriptions.Item>
              </Descriptions>
            </TabPane>
            {/*-----------------*/}
            <TabPane
              tab={
                <span>
                  <AlertOutlined />
                  Subscribe
                </span>
              }
              key="2"
            >
              <Descriptions>
                <Descriptions.Item>
                  {!isPrivateChannel &&
                    (isChannelStarred ? (
                      <Tooltip title="You are subscribet">
                        <Button
                          danger
                          onClick={handleStar}
                          icon={<CloseCircleOutlined twoToneColor="#52c41a" />}
                        >
                          Un subscribe
                        </Button>
                      </Tooltip>
                    ) : (
                      <Tooltip title="You are subscribet">
                        <Button
                          className="green-ant-button"
                          onClick={handleStar}
                          icon={<CheckCircleOutlined twoToneColor="#eb2f96" />}
                        >
                          subscribe
                        </Button>
                      </Tooltip>
                    ))}
                </Descriptions.Item>
                <Descriptions.Item label="Description">
                  Use search if you want search some message
                </Descriptions.Item>
              </Descriptions>
            </TabPane>
            {/*-----------------*/}

            <TabPane
              tab={
                <span>
                  <SearchOutlined />
                  Search Messages
                </span>
              }
              key="3"
            >
              <Descriptions>
                <Descriptions.Item>
                  <Input
                    loading={searchLoading}
                    onChange={handleSearchChange}
                    size="mini"
                    icon="search"
                    name="searchTerm"
                    placeholder="Search Messages"
                  />
                </Descriptions.Item>
                <Descriptions.Item label="Description">
                  Use search if you want search some message
                </Descriptions.Item>
              </Descriptions>
            </TabPane>
            {/*-----------------*/}

            <TabPane
              tab={
                <span>
                  {/*
                  <InfoOutlined />
                  */}
                  <InfoCircleOutlined />
                  Chanell Information
                </span>
              }
              key="4"
            >
              <Descriptions>
                <Descriptions.Item label="Сhat name">aaaaaaa</Descriptions.Item>

                <Descriptions.Item>rrrr</Descriptions.Item>
              </Descriptions>
            </TabPane>
          </Tabs>
        </Card>
      </>
    );
  }
}

export default MessagesHeader;

/*
    return (
      <Segment clearing>
    
        <Header fluid="true" as="h2" floated="left" style={{ marginBottom: 0 }}>
          <span>
            {channelName}
            {!isPrivateChannel && (
              <Icon
                onClick={handleStar}
                name={isChannelStarred ? 'star' : 'star outline'}
                color={isChannelStarred ? 'yellow' : 'black'}
              />
            )}
          </span>
          <Header.Subheader>{numUniqueUsers}</Header.Subheader>
        </Header>


        <Header floated="right">
          <Input
            loading={searchLoading}
            onChange={handleSearchChange}
            size="mini"
            icon="search"
            name="searchTerm"
            placeholder="Search Messages"
          />
        </Header>
      </Segment>
    );
*/

/*
            <TabPane
              tab={
                <span>
                  <AppleOutlined />
                  Tab 1
                </span>
              }
              key="1"
            >
              <Header fluid="true" as="h2" floated="left" style={{ marginBottom: 0 }}>
                <span>
                  {channelName}
                  {!isPrivateChannel && (
                    <Icon
                      onClick={handleStar}
                      name={isChannelStarred ? 'star' : 'star outline'}
                      color={isChannelStarred ? 'yellow' : 'black'}
                    />
                  )}
                </span>
                <Tooltip title="search">
                  <Button danger icon={<SearchOutlined />}>
                    Search
                  </Button>
                </Tooltip>
                <Header.Subheader>{numUniqueUsers}</Header.Subheader>
              </Header>
            </TabPane>
*/
