import React from 'react';
import firebase from '../../../firebase';
import { connect } from 'react-redux';
import { setCurrentChannel, setPrivateChannel } from '../actions';
import { Menu, Icon } from 'semantic-ui-react';
import { Comment, Image } from 'semantic-ui-react';

import { Skeleton, Switch, Card, Avatar, Badge } from 'antd';
import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';

import { Tag } from 'antd';
import { Descriptions } from 'antd';

import { Tabs, Radio } from 'antd';

import { Typography } from 'antd';

const { TabPane } = Tabs;

const { Meta } = Card;

class DirectMessages extends React.Component {
  state = {
    activeChannel: '',
    user: this.props.currentUser,
    users: [],
    usersRef: firebase.database().ref('users'),
    connectedRef: firebase.database().ref('.info/connected'),
    presenceRef: firebase.database().ref('presence'),
  };

  componentDidMount() {
    if (this.state.user) {
      this.addListeners(this.state.user.uid);
    }
  }

  componentWillUnmount() {
    this.removeListeners();
  }

  removeListeners = () => {
    this.state.usersRef.off();
    this.state.presenceRef.off();
    this.state.connectedRef.off();
  };

  addListeners = currentUserUid => {
    let loadedUsers = [];
    this.state.usersRef.on('child_added', snap => {
      if (currentUserUid !== snap.key) {
        let user = snap.val();
        user['uid'] = snap.key;
        user['status'] = 'offline';
        loadedUsers.push(user);
        this.setState({ users: loadedUsers });
      }
    });

    this.state.connectedRef.on('value', snap => {
      if (snap.val() === true) {
        const ref = this.state.presenceRef.child(currentUserUid);
        ref.set(true);
        ref.onDisconnect().remove(err => {
          if (err !== null) {
            console.error(err);
          }
        });
      }
    });

    this.state.presenceRef.on('child_added', snap => {
      if (currentUserUid !== snap.key) {
        this.addStatusToUser(snap.key);
      }
    });

    this.state.presenceRef.on('child_removed', snap => {
      if (currentUserUid !== snap.key) {
        this.addStatusToUser(snap.key, false);
      }
    });
  };

  addStatusToUser = (userId, connected = true) => {
    const updatedUsers = this.state.users.reduce((acc, user) => {
      if (user.uid === userId) {
        user['status'] = `${connected ? 'online' : 'offline'}`;
      }
      return acc.concat(user);
    }, []);
    this.setState({ users: updatedUsers });
  };

  isUserOnline = user => user.status === 'online';

  changeChannel = user => {
    const channelId = this.getChannelId(user.uid);
    const channelData = {
      id: channelId,
      name: user.name,
    };
    this.props.setCurrentChannel(channelData);
    this.props.setPrivateChannel(true);
    this.setActiveChannel(user.uid);
  };

  getChannelId = userId => {
    const currentUserId = this.state.user.uid;
    return userId < currentUserId ? `${userId}/${currentUserId}` : `${currentUserId}/${userId}`;
  };

  setActiveChannel = userId => {
    this.setState({ activeChannel: userId });
  };

  render() {
    const { users, activeChannel } = this.state;

    // console.log(users);
    // debugger;

    return (
      <Menu.Menu className="menu">
        <Menu.Item>
          <span>
            <Icon name="mail" /> DIRECT MESSAGES
          </span>{' '}
          ({users.length})
        </Menu.Item>

        <div style={{ maxHeight: '100px', overflowY: 'scroll', overflowX: 'hidden' }}>
          {users.map((user, i) => (
            <Menu.Item
              key={user.uid}
              active={user.uid === activeChannel}
              onClick={() => this.changeChannel(user)}
              style={{ opacity: 1, fontStyle: 'italic' }}
            >
              {/*
              <Meta avatar={<Avatar src={user.avatar} />} title={`@ ${user.name}`} />
              <Icon name="circle" color={this.isUserOnline(user) ? 'green' : 'red'} />
              */}
              {/*
               */}
              <Badge count={1}>
                <Meta avatar={<Avatar src={user.avatar} />} />
              </Badge>
              <span>{`@ ${user.name}`}</span>
              <Tag color="success">online</Tag>
            </Menu.Item>
          ))}
        </div>
      </Menu.Menu>
    );
  }
}

export default connect(null, { setCurrentChannel, setPrivateChannel })(DirectMessages);

/*
        <Tabs defaultActiveKey="1" tabPosition="left" style={{ height: 220 }}>
          {users.map((user, i) => (
            <TabPane
              tab={<Meta avatar={<Avatar src={user.avatar} />} title={`@ ${user.name}`} />}
              key={i}
            ></TabPane>
          ))}
        </Tabs>
*/

/*
        <Tabs defaultActiveKey="1" tabPosition="left" style={{ height: 220 }}>
          {users.map((user, i) => (
            <Menu.Item
              key={user.uid}
              active={user.uid === activeChannel}
              onClick={() => this.changeChannel(user)}
              style={{ opacity: 0.7, fontStyle: 'italic' }}
            >

              
            <Image src={user.avatar} className="message__image" />
            
              
            <div>
              <Comment.Avatar src={user.avatar} />
              <Icon name="circle" color={this.isUserOnline(user) ? 'green' : 'red'} />@ {user.name}
            </div>
            <Avatar shape="square" icon={<UserOutlined />} />
            

              
            <Badge count={1}>
              <Meta avatar={<Avatar src={user.avatar} />} title={`@ ${user.name}`} />
            </Badge>
            

            <TabPane
            tab={<Meta avatar={<Avatar src={user.avatar} />} title={`@ ${user.name}`} />}
            key={i}
          ></TabPane>
        </Menu.Item>
      ))}
    </Tabs>
*/
