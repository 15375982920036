import React from 'react';

// import { Switch, Router, Route, Redirect } from 'react-router-dom';
import { BrowserRouter as Router, Switch, Route, withRouter, Redirect } from 'react-router-dom';

// helpers
import history from '../helpers/history';

import { Provider, connect } from 'react-redux';

import firebase from '../firebase';

// routes
import { HOME, LOGIN, TEST, ACCOUNT } from '../constants/routes';

// pages components
import Header from '../components/common/Header';
import Home from '../pages/Home';
// import Login from '../pages/Login';
import Account from '../pages/Account';
import Test from '../pages/Test';

import Register from '../pages/Auth/Register';
import Login from '../pages/Auth/Login';
import Chat from '../pages/Chat';
import PortfolioWork from '../pages/PortfolioWork';

import Spinner from '../Spinner';

/* User Action Types */
const SET_USER = 'SET_USER';
const CLEAR_USER = 'CLEAR_USER';

/* Colors Action Types */
export const SET_COLORS = 'SET_COLORS';

/* User Actions */
const setUser = user => {
  return {
    type: SET_USER,
    payload: {
      currentUser: user,
    },
  };
};

const clearUser = () => {
  return {
    type: CLEAR_USER,
  };
};

class Index extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact={true} path={HOME} component={Home} />
        {/*
        <Route path="/login" component={Login} />
        <Route path="/register" component={Register} />
        <Route path="/chat" component={Chat} />
        <Route path={ACCOUNT} component={Account} />
        <Route path="/portfolio/:id" children={<PortfolioWork />} />
      */}
        <Route path={TEST} component={Test} />
        <Redirect to={HOME} />
      </Switch>
    );
  }
}

const mapStateFromProps = state => ({
  // isLoading: state.user.isLoading,
});

const RootWithAuth = withRouter(connect(mapStateFromProps, { setUser, clearUser })(Index));

const Testtt = () => {
  return (
    <Router>
      <RootWithAuth />
    </Router>
  );
};

export default Testtt;

/*
  render() {
    return this.props.isLoading ? (
      <Spinner />
    ) : (
      <Switch>
        <Route exact={true} path={HOME} component={Home} />
        <Route path="/login" component={Login} />
        <Route path="/register" component={Register} />
        <Route path={ACCOUNT} component={Account} />
        <Route path={TEST} component={Test} />
        <Redirect to={HOME} />
      </Switch>
    );
  }
*/

/*
// <Header />
const Index = () => (
  <Router history={history}>
    <Switch>
      <Route exact={true} path={HOME} component={Home} />

      
      <Route path={LOGIN} component={Login} />

      <Route path={ACCOUNT} component={Account} />

      <Route path={TEST} component={Test} />

      <Redirect to={HOME} />
    </Switch>
  </Router>
);
*/
