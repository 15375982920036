import React, { Component, useState, FunctionComponent, useRef } from 'react';
import './BannerOne.css';
import { useTranslation, withTranslation, Trans } from 'react-i18next';
// import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { connect } from 'react-redux';

import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import firebase from '../../../../firebase';

import LettersPreloader from '../../../common/Preloaders/LettersPreloader';
// import ClosedMenu from '../ClosedMenu';

// import { EN, RU } from '../../../../constants/applications';
import { LOCAL, SEND, MESSAGE_DELIVERED } from '../../../../constants/preloaders';

import Icon68 from '../../../../images/icon/icon68.svg';

/**
 * https://react.i18next.com/latest/trans-component
 *
 * @param {*} props
 */
// function BannerOne(props) {
const BannerOne = props => {
  const earlyMessage = props.isUserSentContactMessageEarly;

  const contactFormContent = () => {
    if (earlyMessage[0]) {
      return (
        <>
          <LettersPreloader lettersArr={MESSAGE_DELIVERED} scope={LOCAL} />
        </>
      );
    }

    //
    return (
      <>
        {props.isContactMessageLoad && (
          <LettersPreloader lettersArr={SEND} scope={LOCAL} spiner={false} />
        )}

        <BannerOneFormikForm
          error={props.error.contactMessage}
          sentContactMessage={props.sentContactMessage}
          isInputsHighlight={props.isContactFormInputsHighlight}
          isContactFormInputsHighlight={props.isContactFormInputsHighlight}
        />

        {/*
        <BannerOneForm error={props.error.contactMessage} />
        <TestFormik />       
        <BannerOneForm error={props.error.contactMessage} />
      */}
      </>
    );
  };

  return (
    <>
      <div class="d-lg-flex ">
        <h2 class="font-k2d main-title top-main-title">
          {/*<Trans i18nKey="test1.subTest1"><span>test1</span> information and <br /> <span>text</span></Trans>*/}

          <Trans i18nKey="MainHeader.main_title">
            Build idea for <span>your goal</span>.
          </Trans>
          {/*
          <Trans i18nKey="MainHeader.main_title_1_part">
            We’r highly <br />
          </Trans>
          <Trans i18nKey="MainHeader.main_title_2_part">
            <span>Expert & Idea</span> <br /> Builder.
          </Trans>
          */}
        </h2>
        <div class="banner-form">{contactFormContent()}</div>
      </div>
      <div class="credit-tag">
        <p class="font-k2d motivateTitle">
          <span>Think Different</span> <br />
          impossible is nothing
        </p>
      </div>
    </>
  );
};

export default BannerOne;

/**
 *
 * @param {*} param0
 */
const MyInput = ({ field, form, ...props }) => {
  // const MyInput = ({ field, form, isContactFormInputsHighlight, ...props }) => {
  // return <input {...field} {...props} />;

  // let contentValue = isContactFormInputsHighlight ? isContactFormInputsHighlight : '';

  return (
    <>
      <textarea id="contentTextarea" {...field} {...props}></textarea>
    </>
  );
};

const mapStateToProps = state => ({
  // application
  isContactFormInputsHighlight: state.application.isContactFormInputsHighlight,
});

/**
 * https://medium.com/@yudhajitadhikary/formik-yup-together-makes-creation-of-react-forms-easier-6c9cf91dab50
 * https://github.com/Yudhajitadhikary/React_Formik
 *
 * https://jaredpalmer.com/formik/docs/api/field
 *
 * https://tproger.ru/articles/regexp-for-beginners/
 *
 * https://stackoverflow.com/questions/42174375/javascript-validation-regex-for-names
 * https://regex101.com/r/PUBviV/1  // !!!!!!!!!!!!!!!!!!!!
 */
const BannerOneFormikForm = ({
  sentContactMessage,
  error,
  isInputsHighlight,
  isContactFormInputsHighlight,
}) => {
  const { t, i18n } = useTranslation();

  // console.log(isInputsHighlight);
  // debugger;

  const divInputName = useRef('');
  let inputName = '';

  const divInputEmail = useRef('');
  let inputEmail = '';

  const inputContent = useRef('');

  const [content, setContent] = useState('');
  const [contentTriger, setContentTriger] = useState('');

  if (isInputsHighlight && isInputsHighlight != contentTriger) {
    setContentTriger(isInputsHighlight);

    // inputContent.current.focus();
    setContent(isInputsHighlight);
    inputContent.current.value = isInputsHighlight;
    inputContent.current.classList.add('textApear');

    if (divInputName.current.children) {
      inputName = divInputName.current.children[0];
      inputName.classList.add('contactFormHighlightContainer');

      inputEmail = divInputEmail.current.children[0];
      inputEmail.classList.add('contactFormHighlightContainer');
    }

    setTimeout(() => {
      // console.log(inputName);
      // debugger;
      inputContent.current.classList.remove('textApear');
      inputName.classList.remove('contactFormHighlightContainer');
      inputEmail.classList.remove('contactFormHighlightContainer');
      // divInputName.current.classList.remove('contactFormHighlightContainer');
    }, 3000);
  }
  // console.log(inputContent.current);
  // debugger;

  const handleChange = e => {
    console.log(e.target.value);
    setContent(e.target.value);
  };

  return (
    <>
      <Formik
        initialValues={{
          name: '',
          email: '',
          // content: '',
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .min(2, 'Name must be at least 2 characters')
            // .matches(/([^0-9])/i, 'invalid Name')
            // .matches(/([^\d][\d])/i, 'invalid Name')  // ни цифры, ни буквы, ни один из языков
            .matches(/([^\d])/i, 'invalid Name')
            // .matches(/^[a-zA-Z]+(?:-[a-zA-Z]+)*$/gm, 'invalid Name')
            .required('Name is required'),
          email: Yup.string()
            .email('Email is invalid')
            .required('Email is required'),
        })}
        onSubmit={fields => {
          // console.log(fields);
          // alert('SUCCESS!! :-)\n\n' + JSON.stringify(fields));
          // e.preventDefault();
          let fields_plus_content = { ...fields, content };
          sentContactMessage(fields_plus_content);
        }}
        /*
        className={
          'contactFormHighlightContainer form-control' +
          (errors.name && touched.name ? ' is-invalid' : '')
        }
        */
        render={({ errors, status, touched }) => (
          <Form>
            <div class="input-group-wrapper">
              {error && <h3 style={{ color: 'red' }}>Error, please try again</h3>}
              <div className="contactFormInoutWrap" ref={divInputName}>
                <Field
                  name="name"
                  type="text"
                  placeholder={t('MainHeader.contact_form.name')}
                  className={`form-control ${errors.name && touched.name && 'is-invalid'}`}
                />

                <ErrorMessage name="name" component="div" className="invalid-feedback" />
              </div>

              <div className="contactFormInoutWrap" ref={divInputEmail}>
                <Field
                  name="email"
                  placeholder={t('MainHeader.contact_form.email')}
                  type="text"
                  className={`form-control ${errors.name && touched.name && 'is-invalid'}`}
                />
                <ErrorMessage name="email" component="div" className="invalid-feedback" />
              </div>

              {/*component={connect(mapStateToProps, {})(MyInput)}
              
                className={`${isInputsHighlight && 'textApear'}`}
                <Field
                name="content"
                placeholder={t('MainHeader.contact_form.message')}
                component={MyInput}
                />
              */}
              {/*
                className={`${isInputsHighlight && 'textApear'}`}
              */}
              <textarea
                ref={inputContent}
                name="content"
                id="contentTextarea"
                placeholder={t('MainHeader.contact_form.message')}
                onChange={e => handleChange(e)}
              ></textarea>
            </div>

            <button type="submit">{t('MainHeader.contact_form.send')}</button>
          </Form>
        )}
      />
    </>
  );
};
// type="textarea"

/**
 *
 */
const BannerOneForm = props => {
  const { t, i18n } = useTranslation();

  const [isContactSend, setIsContactSend] = useState(false);

  const [formState, setFormState] = React.useState({
    name: '',
    email: '',
    content: '',
  });

  const inputsOnChange = e => {
    console.log(e.target.name, ' - ', e.target.value);

    let name = e.target.name;
    let value = e.target.value;

    // this.setState(state => ({ form: { ...state.form, [name]: value } }));
    setFormState({ ...formState, [name]: value });
  };

  const sentContact = e => {
    e.preventDefault();
    props.sentContactMessage(formState);
  };

  return (
    <>
      <form action="#">
        <div class="input-group-wrapper">
          {props.error.contactMessage && <h3 style={{ color: 'red' }}>Error, please try again</h3>}
          <input
            id="contactFormName"
            class="contactMessageInput"
            type="text"
            placeholder={t('MainHeader.contact_form.name')}
            name="name"
            onChange={e => inputsOnChange(e)}
            value={formState.name}
            required
            pattern="banana|cherry"
          />
          <input
            type="email"
            placeholder={t('MainHeader.contact_form.email')}
            name="email"
            onChange={e => inputsOnChange(e)}
            value={formState.email}
          />
          <textarea
            placeholder={t('MainHeader.contact_form.message')}
            onChange={e => inputsOnChange(e)}
            name="content"
            value={formState.content}
            required
          ></textarea>
        </div>
        <button onClick={e => sentContact(e)}>{t('MainHeader.contact_form.send')}</button>
      </form>
    </>
  );
};
