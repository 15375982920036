import React, { Component } from 'react';

import './VideoPlayer.css';

// https://s3.amazonaws.com/codecademy-content/courses/React/react_video-fast.mp4
const VIDEOS = {
  deer: 'https://s3.amazonaws.com/codecademy-content/courses/React/react_video-fast.mp4',
  snail: 'https://s3.amazonaws.com/codecademy-content/courses/React/react_video-slow.mp4',
  cat: 'https://s3.amazonaws.com/codecademy-content/courses/React/react_video-cute.mp4',
  spider: 'https://s3.amazonaws.com/codecademy-content/courses/React/react_video-eek.mp4',
};

const VIDEOS_2 = [
  'https://an-kor.net/wp-content/uploads/2020/03/13-November-2019-Loom-Recording-1.mp4',
  'https://s3.amazonaws.com/codecademy-content/courses/React/react_video-fast.mp4',
  'https://s3.amazonaws.com/codecademy-content/courses/React/react_video-slow.mp4',
];

class VideoPlayer extends Component {
  state = {
    src: VIDEOS.deer,
  };

  chooseVideo = newVideo => {
    this.setState({
      src: VIDEOS[newVideo],
    });
  };

  render() {
    return (
      <>
        <div>
          {/*
          <Menu chooseVideo={this.chooseVideo} />
            */}
          <Video src={VIDEOS_2[this.props.videoNumber]} />
        </div>
      </>
    );
  }
}

class Video extends Component {
  render() {
    return (
      <>
        <div>
          <video controls autoPlay src={this.props.src} />
        </div>
      </>
    );
  }
}

class Menu extends Component {
  handleClick = e => {
    const text = e.target.value;
    this.props.chooseVideo(text);
  };

  render() {
    return (
      <>
        <form onClick={this.handleClick}>
          <input type="radio" name="src" value="deer" /> Deer
          <input type="radio" name="src" value="snail" /> Snail
          <input type="radio" name="src" value="cat" /> Cat
          <input type="radio" name="src" value="spider" /> Spider
        </form>
      </>
    );
  }
}

export default VideoPlayer;

/*
// https://s3.amazonaws.com/codecademy-content/courses/React/react_video-fast.mp4
const VIDEOS = {
  deer: 'https://s3.amazonaws.com/codecademy-content/courses/React/react_video-fast.mp4',
  snail: 'https://s3.amazonaws.com/codecademy-content/courses/React/react_video-slow.mp4',
  cat: 'https://s3.amazonaws.com/codecademy-content/courses/React/react_video-cute.mp4',
  spider: 'https://s3.amazonaws.com/codecademy-content/courses/React/react_video-eek.mp4',
};

class VideoPlayer extends Component {
  state = {
    src: VIDEOS.deer,
  };

  chooseVideo = newVideo => {
    this.setState({
      src: VIDEOS[newVideo],
    });
  };

  render() {
    return (
      <>
        <div>

            <Video src={this.state.src} />
            </div>
          </>
        );
      }
    }
    
    class Video extends Component {
      render() {
        return (
          <>
            <div>
              <video controls autoPlay src={this.props.src} />
            </div>
          </>
        );
      }
    }
    
    class Menu extends Component {
      handleClick = e => {
        const text = e.target.value;
        this.props.chooseVideo(text);
      };
    
      render() {
        return (
          <>
            <form onClick={this.handleClick}>
              <input type="radio" name="src" value="deer" /> Deer
              <input type="radio" name="src" value="snail" /> Snail
              <input type="radio" name="src" value="cat" /> Cat
              <input type="radio" name="src" value="spider" /> Spider
            </form>
          </>
        );
      }
    }
    
    export default VideoPlayer;
*/
