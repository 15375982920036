import React from 'react';

const Skeleton = () => (
  <div className="skeleton_chat">
    <div className="skeleton_chat__avatar" />
    <div className="skeleton_chat__author" />
    <div className="skeleton_chat__details" />
  </div>
);

export default Skeleton;
