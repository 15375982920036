import React from 'react';
import { useTranslation, Trans } from 'react-i18next';


function Experience(props) {
  const { t } = useTranslation();
  
  const employers = [
    "LMS", "netvertise", "continuum", "am_bits",
    "relay_payments", "stucco_media", "previous_experience",
  ];

  return(<>
    <div class="card-content">
      <div class="card-subtitle">{t(`GeneralPhrases.work_experience`).toUpperCase()}</div>
      <div class="card-timeline">
        {
          employers.map(
            (el) => {
              return(
                <div class="card-item" data-year={t(`Works.${el}.company.period.text_1`)}>
                  <h3>
                    <span style={{color: "rgb(152, 157, 160)"}}>{t(`Works.${el}.project.title.text_1`)}</span>
                    {' '}
                    { t(`Works.${el}.company.title.text_2`) }
                    {/* el === "LMS" ? t(`Works.${el}.company.title.text_2`) : t(`Works.${el}.company.title.text_2`) */}
                  </h3>
                  {
                    [...Array(5)].map(
                      (emptyEl, i) => {
                        return(
                          t(`Works.${el}.company.descriptions.text_${i}`).substring(0, 5) === "Works" 
                            || t(`Works.${el}.company.descriptions.text_${i}`).length === 0
                          ? "" : <p>{t(`Works.${el}.company.descriptions.text_${i}`)}</p>
                        );
                      }
                    )
                  }
                  <strong>{ t(`Works.${el}.project.responsibilities.title`) }</strong>
                  {
                    [...Array(10)].map(
                      (emptyEl, i) => {
                        return(
                          t(`Works.${el}.${el === "LMS" ? "company" : "project"}.responsibilities.text.text_${i}`).substring(0, 5) === "Works"
                            || t(`Works.${el}.${el === "LMS" ? "company" : "project"}.responsibilities.text.text_${i}`).length === 0
                          ? "" : <p>{t(`Works.${el}.${el === "LMS" ? "company" : "project"}.responsibilities.text.text_${i}`)}</p>
                        );
                      }
                    )
                  }
                  <strong>{ t(`Works.${el}.${el === "LMS" ? "company" : "project"}.technologies.title`) }</strong>
                  <ul>{
                    [...Array(10)].map(
                      (emptyEl, i) => {
                        return(
                          t(`Works.${el}.${el === "LMS" ? "company" : "project"}.technologies.text.text_${i}`).substring(0, 5) === "Works"
                            || t(`Works.${el}.${el === "LMS" ? "company" : "project"}.technologies.text.text_${i}`).length === 0
                          ? "" : <li>{t(`Works.${el}.${el === "LMS" ? "company" : "project"}.technologies.text.text_${i}`)}</li>
                        );
                      }
                    )
                  }</ul>
                  { 
                    el === "LMS" ? "" : <strong>{ t(`Works.${el}.company.contacts.title`) }{' '}</strong>
                  }
                  <ul>{
                    [...Array(10)].map(
                      (emptyEl, i) => {
                        return(
                          t(`Works.${el}.company.contacts.data.data_${i}.href`).substring(0, 5) === "Works"
                            || t(`Works.${el}.company.contacts.data.data_${i}.href`).length === 0
                            || el === "LMS"
                          ? "" 
                          : t(`Works.${el}.company.contacts.data.data_${i}.href`) === "no data" 
                            ? <li>{t(`Works.${el}.company.contacts.data.data_${i}.text`)}</li>
                            : <li>
                                <a href={t(`Works.${el}.company.contacts.data.data_${i}.href`)} target="_blank">
                                  {t(`Works.${el}.company.contacts.data.data_${i}.text`)}
                                </a>
                              </li>
                        );
                      }
                    )
                  }</ul>
                  <strong>{ t(`Works.${el}.company.recommendations.title`) }{' '}</strong>
                  <ul>{
                    [...Array(10)].map(
                      (emptyEl, i) => {
                        return(
                          t(`Works.${el}.company.recommendations.data.data_${i}.href`).substring(0, 5) === "Works"
                            || t(`Works.${el}.company.recommendations.data.data_${i}.href`).length === 0
                          ? "" 
                          : t(`Works.${el}.company.recommendations.data.data_${i}.href`) === "no data" 
                            ? <li>{t(`Works.${el}.company.recommendations.data.data_${i}.text`)}</li>
                            : <li>
                                <a href={t(`Works.${el}.company.recommendations.data.data_${i}.href`)} target="_blank">
                                  {t(`Works.${el}.company.recommendations.data.data_${i}.text`)}
                                </a>
                              </li>
                        );
                      }
                    )
                  }</ul>
                </div>
              );
            }
          )
        }
      </div>
    </div>
  </>);
}




/*
        <hr />

        <div class="card-item" data-year="Jul22-24">
          <div class="card-item-title">...</div>
          <div class="card-item-desc">
            <p>Netvertise is an innovative e-commerce software powerhouse that provides clients worldwide with an all-inclusive e-commerce solution. In other words, we are dead serious about building gorgeous, high-performing online stores. To do this we use powerfully built technologies, creative design and content, and forceful marketing techniques that increase conversions and revenues.</p>
            <strong>My responsibilities:</strong>
            <p>Development of new functionality and solving the most complex tasks related to the company's projects. Development of an application for one of the company's projects.</p>
            <p>
              <strong>Technologies used:</strong> React, react-admin, GraphQL, react native.
            </p>
            <strong>Contacts:</strong> <a href="https://jobs.dou.ua/companies/netvertise/" target="_blank">dou.ua</a>
          </div>
        </div>

        <div class="card-item" data-year="Feb22-Jul22">
          <div class="card-item-title">Front-end Developer at Netvertise (Ukraine, Israel).</div>
          <div class="card-item-desc">
            <p>Netvertise is an innovative e-commerce software powerhouse that provides clients worldwide with an all-inclusive e-commerce solution. In other words, we are dead serious about building gorgeous, high-performing online stores. To do this we use powerfully built technologies, creative design and content, and forceful marketing techniques that increase conversions and revenues.</p>
            <strong>My responsibilities:</strong>
            <p>Development of new functionality and solving the most complex tasks related to the company's projects. Development of an application for one of the company's projects.</p>
            <p>
              <strong>Technologies used:</strong> React, react-admin, GraphQL, react native.
            </p>
            <strong>Contacts:</strong> <a href="https://jobs.dou.ua/companies/netvertise/" target="_blank">dou.ua</a>
          </div>
        </div>

        <div class="card-item" data-year="May 21 - Feb 22 ">
          <div class="card-item-title">Front-end Developer at Continuum (Ukraine, USA).</div>
          <div class="card-item-desc">
            <p>Continuum is an international IT Company operating in the area of information technologies and online business. We work on the large high-load web and mobile projects utilising the latest technology and trends of modern development practices. Continuum head office is located in Odessa (Ukraine) with subsidiary and development offices located all over the world including Great Britain, the USA, Spain, Canada. The Company was founded by experienced professionals from leading IT enterprises of Europe and USA with an aim to shape the modern online industry by the introduction of it's own unique approaches to business.</p>
            <strong>My responsibilities:</strong>
            <p>Support and development a social network, - is a subscription-based website platform that allows adult film performers and other influencers sell access to their private social media accounts.</p>
            <strong>Some facts about this social network:</strong>
            <ul>
              <li>- By May 2020, the site boasted over 190,000 models and more than 10,000,000 client accounts.</li>
              <li>- More than 1.8 petabytes (1,800,000 gigabytes) of information.</li>
            </ul>
            <p>
              <strong>Technologies used:</strong> React/Mobx, the company's own framework (is something like nest.js and NEXT.js)..
            </p>
            <strong>Contacts:</strong>
            <ul>
              <li><a href="https://jobs.dou.ua/companies/continuum/" target="_blank">dou.ua</a></li>
              <li><a href="https://www.linkedin.com/company/continuumua/" target="_blank">linkedin.com</a></li>
            </ul>
            <br/>
            <strong>Recommendation:</strong>
            <ul>
              <li><a href="https://www.linkedin.com/in/marina-rychagova-ba6758213/" target="_blank">linkedin.com (Marina Rychagova HR Manager at Continuum)</a></li>
              <li><a href="https://djinni.co/r/13104-hr-generalist-at-continuum/" target="_blank">djinni.co (Marina Rychagova HR Manager at Continuum)</a></li>
            </ul>
          </div>
        </div>   
        
        <div class="card-item" data-year="Dec 20 - Apr 21">
          <div class="card-item-title">Front-end Developer at AM-BITS (Ukraine).</div>
          <div class="card-item-desc">
            <p>AM-BITS is a system integrator of comprehensive IT solutions in the areas of network and computing infrastructure, data storage and virtualization, cybersecurity, enterprise system monitoring and technical support. We leverage the world’s best practices in the field of Big Data, AI, ML and IoT to build efficient production-ready software and hardware solutions for enterprises.</p>
            <strong>My responsibilities:</strong>
            <p>Creation of various interfaces for the company's clients, including the largest concern, like Yuria-Pharm (pharmaceutical corporation) or GMS (international messaging service provider for mobile operators and enterprises).</p>
            <p>
              <strong>Technologies used:</strong> React/Redux(thunk), Python and Hadoop (solr, kafka, etc).
            </p>
            <strong>Contacts:</strong>
            <ul>
              <li><a href="https://am-bits.com/" target="_blank">am-bits.com</a></li>
              <li><a href="https://jobs.dou.ua/companies/am-bits-llc/" target="_blank">dou.ua</a></li>
              <li><a href="https://www.linkedin.com/company/am-bits-llc/" target="_blank">linkedin.com</a></li>
            </ul>
            <br/>
            <strong>Recommendation:</strong>
            <ul>
              <li><a href="https://www.linkedin.com/in/oleksii-potapenko-73938722/" target="_blank">linkedin.com (Data Scientist at AM-BITS LLC, PhD in mathematical analysis)</a></li>
            </ul> 
          </div>
        </div>
        
        <div class="card-item" data-year="Jan 20 - Nov 20">
          <div class="card-item-title">Front-end Developer at Relay Payments (USA)</div>
          <div class="card-item-desc">
            <p>Instant electronic lumperpayments. Manage your fleet on the road with contactless, fast and trackable digital payments. Relay Payments is a venture-backed startup, building a contactless payment network in the transportation and logistics industry. We’re working hard to ensure America continues running during COVID-19, and well beyond. Our mission drives everything we do! The transportation and logistics industry has continued to run on cash and industry-specific checks (similar to cashiers checks) since the 1970s. Relay Payments is spearheading the modernization of payments, pushing the industry into the 21st century. We work with the largest Carriers, Freight Brokers, and 3PLs in the United States who care about their drivers to ensure that their products get to shelves quickly for consumer consumption.</p>
            <strong>Some facts about the company:</strong>
            <ul>
              <li>- $50M+ Monthly transaction volume processed by Relay.</li>
              <li>- 300,000+ Transactions every month powered by Relay.</li>
              <li>- 500,000+ Drivers use Relay nationwide.</li>
            </ul>
            <br/>
            <strong>My responsibilities:</strong>
            <p>Analysis of UX and design requirements and transforming it into React components.</p>                    
            <p>
              <strong>Technologies used:</strong> React/Redux (redux toolkit and injecting reducers (with replaceReducer)), Go.
            </p>
            <strong>Contacts:</strong>
            <ul>
              <li><a href="https://www.relaypayments.com/" target="_blank">relaypayments.com</a></li>
              <li><a href="https://www.linkedin.com/company/relay-payments/" target="_blank">linkedin.com</a></li>
            </ul>
            <br/>
            <strong>Recommendation:</strong>
            <ul>
              <li><a href="https://www.linkedin.com/in/sergii-koval/" target="_blank">linkedin.com (Serge Koval, CTO in Relay Payments)</a></li>
              <li><a href="https://djinni.co/r/3781-cto-at-relay-payments/" target="_blank">djinni.co (Serge Koval, CTO in Relay Payments)</a></li>
            </ul> 
          </div>
        </div>      

        <div class="card-item" data-year="Jan 19 - Nov 19">
          <div class="card-item-title">Front-end Developer at Stucco Media (Israel)</div>
          <div class="card-item-desc">
            <strong>My responsibilities:</strong>
            <p>Work on a project called SkipMM. After you register on the site, you can find your property on the map and fill details of your property, then SkipMM list your home to a Multiple Listing Service (MLS) for free – a process that typically costs hundreds of dollars and allows your home to be distributed automatically to sites like Zillow, Realtor.com, and more.</p> 
            <p>
              <strong>Technologies used:</strong> React/Redux(saga).
            </p>        
            <strong>Contacts:</strong>
            <ul>
              <li><a href="https://www.stuccomedia.com/" target="_blank">https://www.stuccomedia.com/</a></li>
              <li><a href="https://www.linkedin.com/company/stuccomedia/" target="_blank">linkedin.com</a></li>
            </ul>
            <br/>
            <strong>Recommendation:</strong>
            <ul>
              <li><a href="https://www.linkedin.com/in/noam-limor-355ba762/" target="_blank">linkedin.com (Noam Limor, Project Manager on Stucco Media)</a></li>
            </ul>
          </div>
        </div>

        <div class="card-item" data-year="Jan 14 - Jan 19">
          <div class="card-item-title">Front-end and Back-end Developer</div>
          <div class="card-item-desc">
            <p>I've been working as a web developer for more than 7 years. I have had positions as a PHP(YII2, Laravel, wordpress), JavaScript developer. I have successfully worked in teams that delivered large-scale projects, also I have commercial experience in the development of projects from scratch.</p> 
          </div>
        </div>
*/




/*
class Experience extends React.Component {
    state = {};

    render() {
        return (
            <>
              <div class="card-content">
                <div class="card-subtitle">WORK EXPERIENCE test</div>
                <div class="card-timeline">

                  <div class="card-item" data-year="Jul22-24">
                    <div class="card-item-title">...</div>
                    <div class="card-item-desc">
                      <p>Netvertise is an innovative e-commerce software powerhouse that provides clients worldwide with an all-inclusive e-commerce solution. In other words, we are dead serious about building gorgeous, high-performing online stores. To do this we use powerfully built technologies, creative design and content, and forceful marketing techniques that increase conversions and revenues.</p>
                      <strong>My responsibilities:</strong>
                      <p>Development of new functionality and solving the most complex tasks related to the company's projects. Development of an application for one of the company's projects.</p>
                      <p>
                        <strong>Technologies used:</strong> React, react-admin, GraphQL, react native.
                      </p>
                      <strong>Contacts:</strong> <a href="https://jobs.dou.ua/companies/netvertise/" target="_blank">dou.ua</a>
                    </div>
                  </div>





                  <div class="card-item" data-year="Feb22-Jul22">
                    <div class="card-item-title">Front-end Developer at Netvertise (Ukraine, Israel).</div>
                    <div class="card-item-desc">
                      <p>Netvertise is an innovative e-commerce software powerhouse that provides clients worldwide with an all-inclusive e-commerce solution. In other words, we are dead serious about building gorgeous, high-performing online stores. To do this we use powerfully built technologies, creative design and content, and forceful marketing techniques that increase conversions and revenues.</p>
                      <strong>My responsibilities:</strong>
                      <p>Development of new functionality and solving the most complex tasks related to the company's projects. Development of an application for one of the company's projects.</p>
                      <p>
                        <strong>Technologies used:</strong> React, react-admin, GraphQL, react native.
                      </p>
                      <strong>Contacts:</strong> <a href="https://jobs.dou.ua/companies/netvertise/" target="_blank">dou.ua</a>
                    </div>
                  </div>

                  <div class="card-item" data-year="May 21 - Feb 22 ">
                    <div class="card-item-title">Front-end Developer at Continuum (Ukraine, USA).</div>
                    <div class="card-item-desc">
                      <p>Continuum is an international IT Company operating in the area of information technologies and online business. We work on the large high-load web and mobile projects utilising the latest technology and trends of modern development practices. Continuum head office is located in Odessa (Ukraine) with subsidiary and development offices located all over the world including Great Britain, the USA, Spain, Canada. The Company was founded by experienced professionals from leading IT enterprises of Europe and USA with an aim to shape the modern online industry by the introduction of it's own unique approaches to business.</p>
                      <strong>My responsibilities:</strong>
                      <p>Support and development a social network, - is a subscription-based website platform that allows adult film performers and other influencers sell access to their private social media accounts.</p>
                      <strong>Some facts about this social network:</strong>
                      <ul>
                        <li>- By May 2020, the site boasted over 190,000 models and more than 10,000,000 client accounts.</li>
                        <li>- More than 1.8 petabytes (1,800,000 gigabytes) of information.</li>
                      </ul>
                      <p>
                        <strong>Technologies used:</strong> React/Mobx, the company's own framework (is something like nest.js and NEXT.js)..
                      </p>
                      <strong>Contacts:</strong>
                      <ul>
                        <li><a href="https://jobs.dou.ua/companies/continuum/" target="_blank">dou.ua</a></li>
                        <li><a href="https://www.linkedin.com/company/continuumua/" target="_blank">linkedin.com</a></li>
                      </ul>
                      <br/>
                      <strong>Recommendation:</strong>
                      <ul>
                        <li><a href="https://www.linkedin.com/in/marina-rychagova-ba6758213/" target="_blank">linkedin.com (Marina Rychagova HR Manager at Continuum)</a></li>
                        <li><a href="https://djinni.co/r/13104-hr-generalist-at-continuum/" target="_blank">djinni.co (Marina Rychagova HR Manager at Continuum)</a></li>
                      </ul>
                    </div>
                  </div>   
                  
                  <div class="card-item" data-year="Dec 20 - Apr 21">
                    <div class="card-item-title">Front-end Developer at AM-BITS (Ukraine).</div>
                    <div class="card-item-desc">
                      <p>AM-BITS is a system integrator of comprehensive IT solutions in the areas of network and computing infrastructure, data storage and virtualization, cybersecurity, enterprise system monitoring and technical support. We leverage the world’s best practices in the field of Big Data, AI, ML and IoT to build efficient production-ready software and hardware solutions for enterprises.</p>
                      <strong>My responsibilities:</strong>
                      <p>Creation of various interfaces for the company's clients, including the largest concern, like Yuria-Pharm (pharmaceutical corporation) or GMS (international messaging service provider for mobile operators and enterprises).</p>
                      <p>
                        <strong>Technologies used:</strong> React/Redux(thunk), Python and Hadoop (solr, kafka, etc).
                      </p>
                      <strong>Contacts:</strong>
                      <ul>
                        <li><a href="https://am-bits.com/" target="_blank">am-bits.com</a></li>
                        <li><a href="https://jobs.dou.ua/companies/am-bits-llc/" target="_blank">dou.ua</a></li>
                        <li><a href="https://www.linkedin.com/company/am-bits-llc/" target="_blank">linkedin.com</a></li>
                      </ul>
                      <br/>
                      <strong>Recommendation:</strong>
                      <ul>
                        <li><a href="https://www.linkedin.com/in/oleksii-potapenko-73938722/" target="_blank">linkedin.com (Data Scientist at AM-BITS LLC, PhD in mathematical analysis)</a></li>
                      </ul> 
                    </div>
                  </div>
                  
                  <div class="card-item" data-year="Jan 20 - Nov 20">
                    <div class="card-item-title">Front-end Developer at Relay Payments (USA)</div>
                    <div class="card-item-desc">
                      <p>Instant electronic lumperpayments. Manage your fleet on the road with contactless, fast and trackable digital payments. Relay Payments is a venture-backed startup, building a contactless payment network in the transportation and logistics industry. We’re working hard to ensure America continues running during COVID-19, and well beyond. Our mission drives everything we do! The transportation and logistics industry has continued to run on cash and industry-specific checks (similar to cashiers checks) since the 1970s. Relay Payments is spearheading the modernization of payments, pushing the industry into the 21st century. We work with the largest Carriers, Freight Brokers, and 3PLs in the United States who care about their drivers to ensure that their products get to shelves quickly for consumer consumption.</p>
                      <strong>Some facts about the company:</strong>
                      <ul>
                        <li>- $50M+ Monthly transaction volume processed by Relay.</li>
                        <li>- 300,000+ Transactions every month powered by Relay.</li>
                        <li>- 500,000+ Drivers use Relay nationwide.</li>
                      </ul>
                      <br/>
                      <strong>My responsibilities:</strong>
                      <p>Analysis of UX and design requirements and transforming it into React components.</p>                    
                      <p>
                        <strong>Technologies used:</strong> React/Redux (redux toolkit and injecting reducers (with replaceReducer)), Go.
                      </p>
                      <strong>Contacts:</strong>
                      <ul>
                        <li><a href="https://www.relaypayments.com/" target="_blank">relaypayments.com</a></li>
                        <li><a href="https://www.linkedin.com/company/relay-payments/" target="_blank">linkedin.com</a></li>
                      </ul>
                      <br/>
                      <strong>Recommendation:</strong>
                      <ul>
                        <li><a href="https://www.linkedin.com/in/sergii-koval/" target="_blank">linkedin.com (Serge Koval, CTO in Relay Payments)</a></li>
                        <li><a href="https://djinni.co/r/3781-cto-at-relay-payments/" target="_blank">djinni.co (Serge Koval, CTO in Relay Payments)</a></li>
                      </ul> 
                    </div>
                  </div>      

                  <div class="card-item" data-year="Jan 19 - Nov 19">
                    <div class="card-item-title">Front-end Developer at Stucco Media (Israel)</div>
                    <div class="card-item-desc">
                      <strong>My responsibilities:</strong>
                      <p>Work on a project called SkipMM. After you register on the site, you can find your property on the map and fill details of your property, then SkipMM list your home to a Multiple Listing Service (MLS) for free – a process that typically costs hundreds of dollars and allows your home to be distributed automatically to sites like Zillow, Realtor.com, and more.</p> 
                      <p>
                        <strong>Technologies used:</strong> React/Redux(saga).
                      </p>        
                      <strong>Contacts:</strong>
                      <ul>
                        <li><a href="https://www.stuccomedia.com/" target="_blank">https://www.stuccomedia.com/</a></li>
                        <li><a href="https://www.linkedin.com/company/stuccomedia/" target="_blank">linkedin.com</a></li>
                      </ul>
                      <br/>
                      <strong>Recommendation:</strong>
                      <ul>
                        <li><a href="https://www.linkedin.com/in/noam-limor-355ba762/" target="_blank">linkedin.com (Noam Limor, Project Manager on Stucco Media)</a></li>
                      </ul>
                    </div>
                  </div>

                  <div class="card-item" data-year="Jan 14 - Jan 19">
                    <div class="card-item-title">Front-end and Back-end Developer</div>
                    <div class="card-item-desc">
                      <p>I've been working as a web developer for more than 7 years. I have had positions as a PHP(YII2, Laravel, wordpress), JavaScript developer. I have successfully worked in teams that delivered large-scale projects, also I have commercial experience in the development of projects from scratch.</p> 
                    </div>
                  </div>

                </div>
              </div>
            </>
        );
    }
}
*/

export default Experience;