import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ChatBot from 'react-simple-chatbot';

import history from 'helpers/history';

import './test.css';

import HelloUser from './HelloUser';
import FirstChat from './FirstChat';
import MeetChat from './SimpleForm';

import Cyber from './cyber.jpg';

/**
 * Setings for floating ChatBot
 */
const chatSettings = {
  placeholder: 'Please, write your message',
  hideSubmitButton: false,
  hideHeader: false, // If true the header will be hidden
  hideBotAvatar: false, // If true the bot avatar will be hidden in all steps
  headerTitle: 'Hello frends', // ----------------> !!!
  floating: true, // -----------------> !!!
  // isChatOpen: true, // -----------------> !!!
  // handleEnd: () => 'test', // ----------------> !!!
  botAvatar: Cyber,
  floatingIcon: Cyber,
  width: '400px',
  userDelay: 0,
  avatarStyle: {
    borderRadius: 0,
    height: '80px',
  },
  // bubbleStyle: {
  //   borderRadius: 0,
  //   background: '#171717',
  //   color: '#fff',
  // },
  // floatingStyle: {
  //   background: 'black',
  //   borderRadius: 5,
  // },
  bubbleOptionStyle: {
    background: '#171717',
  },
  style: {
    borderRadius: 0,
  },
};

/**
 * simulated props for component
 */
let simulatedProps = {
  lang: 'en',
  user: null, //  null | {name: '', email: ''} | 'secondTime'
};

/**
 * https://lucasbassetti.com.br/react-simple-chatbot/#/docs/bmi
 *
 * - Вызов функции под конец чата можно делать например при перенаправлении
 * пользователя на другую страницу (то есть завершения чата должно быть логичным
 * после чего выполняется какое то действие).
 *
 * - В случае же желания вернуть разговор к изначальному выбору можно просто
 * триггерить еще раз ИД-шник с началом...
 *
 *
 * - Получается так, что память чат бота не зависит от текущего/измененного Стейта.
 * Если же он вызван и в него переданы данные, то этими данными он и будет оперировать.
 *
 * - Данные шага можно изменять за счет внутренних возможностей:
 * https://lucasbassetti.com.br/react-simple-chatbot/#/docs/steps -> Update Step
 *
 * - Если данные для чат бота формировать из различных источников (например данные
 * о том, залогинен пользователь или нет и т.д.), то перед выходом из чата
 * при помощи функции...
 *
 * ! Получается что список ответов, нужно сначала сформировать и только потом
 * передавать его компоненту чат бота...
 *
 * Возможно лучше заводить отдельный компонент под разные состояния, под
 * зарегестрированного свой компонет, под незарегестрированного свой...
 */
class ChatSwither extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      trigger: false,
      page: '/',
      currentChat: 'firstChat',
      isChatOpen: false,
      atlasFirstMeeting: true, // need to be done global
    };
  }

  changeIsFirstMeeting = (name, value) => {
    this.setState(state => ({ [name]: value }));
  };

  componentDidMount() {
    // this.setCurrentChat();
    // if (true) this.enableFlashingBorder();
  }

  // add highligs to bot avatar
  enableFlashingBorder = () => {
    setInterval(() => {
      let element = document.getElementsByClassName('rsc-float-button')[0];
      if (element) {
        (async () => {
          await setTimeout(() => element.classList.add('highlightContainer', 3000));
          // debugger
          element.classList.remove('highlightContainer');
        })();
      }
    }, 3000);
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentChat !== this.state.currentChat) {
      // this.setCurrentChat();
    }
  }

  render() {
    switch (this.state.currentChat) {
      case 'firstChat':
        return (
          <>
            <FirstChat
              chatSettings={chatSettings}
              atlasFirstMeeting={this.state.atlasFirstMeeting}
              changeIsFirstMeeting={this.changeIsFirstMeeting}
            />
          </>
        );
      case 'MeetChat':
        return (
          <>
            <MeetChat
              chatSettings={chatSettings}
              changeIsFirstMeeting={this.changeIsFirstMeeting}
            />
          </>
        );
      default:
        break;
    }
  }
}

export default ChatSwither;

/*
  setCurrentChat = () => {
    // let computedСhat = 'firstChat';
    // this.setState(state => ({ currentChat: computedСhat }));
  };
*/

/*
setInterval(() => {
  let element = document.getElementsByClassName('rsc-float-button')[0];
  // console.log(element);
  if (element) {
    element.classList.add('highlightContainer');
    setTimeout(() => {
      element.classList.remove('highlightContainer');
    }, 3000);
  }
}, 6000);
*/

/*
  elementDomStyling = () => {
    let img = document.getElementsByClassName('sc-fzXfLV iVhDrr')[0];
    if (img) img.setAttribute('style', 'height: 44px; width: 44px;');

    let header = document.getElementsByClassName('rsc-header')[0];
    if (header) header.setAttribute('style', 'background: #212121;');
  };
  */

/*
class ThemedExample extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();

    this.state = {
      trigger: false,
      page: '/',
      currentChat: 'SimpleForm',
      isChatOpen: false,
    };
  }

  componentDidMount() {
    this.elementDomStyling();
  }

  componentDidUpdate() {
    this.elementDomStyling();
  }

  // rsc-ts-bot
  // document.getElementsByClassName('rsc-ts-bot')[0].childNodes[1]
  elementDomStyling = () => {
    let img = document.getElementsByClassName('sc-fzXfLV iVhDrr')[0];
    if (img) img.setAttribute('style', 'height: 44px; width: 44px;');

    let header = document.getElementsByClassName('rsc-header')[0];
    if (header) header.setAttribute('style', 'background: #212121;');
  };

  changeSteps = values => {
    // console.log('>----> ', 'changeSteps');
    this.setState(state => ({ formComponent: true }));
  };

  changes = values => {
    // console.log('>----> ', 'changeSteps');
    this.setState(state => ({ formComponent: true }));
  };

  handleEnd = ({ steps, values }) => {
    // console.log(steps);
    // console.log(values);
    simulatedProps.user = 'secondTime';
    this.setState(state => ({ trigger: !state.trigger }));
    this.setState(state => ({ isChatOpen: true }));
    setTimeout(() => {
      this.setState(state => ({ isChatOpen: false }));
    }, 3000);
  };

  render() {
    const bubbleStyle = {
      borderRadius: 0,
      background: '#171717',
      color: '#fff',
      // margin: '-8px 0 10px 78px',
    };
    const floatingStyle = {
      background: 'black',
      borderRadius: 5,
    };
    const avatarStyle = {
      borderRadius: 0,
      height: '80px',
    };

    switch (simulatedProps.user) {
      case null:
        return (
          <>
            <SimpleForm
              floating={true}
              // bubbleStyle={bubbleStyle}
              floatingStyle={floatingStyle}
              botAvatar={Cyber}
              avatarStyle={avatarStyle}
              userDelay={0}
              width="400px"
              floatingIcon={Cyber}
              handleEnd={this.handleEnd}
            />
          </>
        );
      case 'secondTime':
        return (
          <>
            <HelloUser
              floatingStyle={floatingStyle}
              floating={true}
              bubbleStyle={bubbleStyle}
              floatingStyle={floatingStyle}
              botAvatar={Cyber}
              avatarStyle={avatarStyle}
              userDelay={0}
              width="400px"
              floatingIcon={Cyber}
              isChatOpen={this.state.isChatOpen}
            />
          </>
        );
      default:
        break;
    }
  }
}

export default ThemedExample;

*/

/*
  let bot_messages = document.getElementsByClassName('rsc-ts-bot');
  if (typeof bot_messages != 'undefined' && bot_messages != null && bot_messages.length > 0) {
    // bot_messages[0].childNodes[1].setAttribute('style', 'background: #212121;');

    console.log(bot_messages);

    for (let index = 0; index < bot_messages.length; index++) {
      const element = bot_messages[index].childNodes[1].setAttribute(
        'style',
        'background: #212121;'
      );
    }
  }
*/
