import React from 'react';
// import "./index.css";

import { Layout, Breadcrumb } from 'antd';

const { Content } = Layout;

const ContentPage = (props) => {
  return (
    <>
      <Content style={{ margin: '0 16px' }}>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>User</Breadcrumb.Item>
          <Breadcrumb.Item>Bill</Breadcrumb.Item>
        </Breadcrumb>
        <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
          Bill is a cat.
        </div>
        <div class="skeleton_chat">
          <div class="skeleton_chat__avatar"></div>
          <div class="skeleton_chat__author"></div>
          <div class="skeleton_chat__details"></div>
        </div>
      </Content>
    </>
  );
};

export default ContentPage;
