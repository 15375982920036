import React from 'react';
import './FooterBottom.css'

import FooterBottomImg from 'images/shape/shape-67.svg';

/**
 * col-md-6 order-md-last  =>  aos-init aos-animate
 */
function FooterBottom() {
  return (
    <>
      <footer class="theme-footer-one">
        <img src={FooterBottomImg} alt="" class="shape-two" />
        <div class="container">
          <div class="bottom-footer">
            <div class="clearfix">
              <p>&copy; 2024 copyright all right reserved</p>
              {/*
              <ul>
                <li>
                  <a href="#">Login</a>
                </li>
                <li>
                  <a href="faq.html">Faq.</a>
                </li>
                <li>
                  <a href="#">Terms.</a>
                </li>
              </ul>
              */}
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default FooterBottom;
