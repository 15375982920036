import actionCreatorFactory from 'typescript-fsa';
const actionCreator = actionCreatorFactory('auth');

import { ILogInData, IUser, ISentMessageData } from 'models/User';

// ILogInData - arguments model, IUser - returns model
export const logIn = actionCreator.async<ILogInData, IUser>('LOG_IN');

export const sentContactMessage = actionCreator.async<ISentMessageData, boolean>(
  'SENT_CONTACT_MESSAGE'
);
