const psevdo_axios = (props: any) => {
  // => { method: 'GET'||'POST', url, data, headers }

  // console.log(props); debugger

  let response = {};
  let promise = new Promise((resolve, reject) => {
    // let dataFromServer = { userName: "work.fse@gmail.com", email: "work.fse@gmail.com", token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ3b…nkifQ.9ofgzvD7dGghWJ2Z_bpyXmhWUr-wQfgCPLCdjoivblY" };
    let dataFromServer = simulated_router(props);

    response = {
      // data: simulated_router(props),
      data: dataFromServer,
      status: 200, // => 200
      statusText: {},
      headers: {},
      config: {},
    };

    logs(props, response);
    setTimeout(() => resolve(response), 1500);
  });

  return promise;
};

const logs = (request: any, response: any) => {
  setTimeout(() => {
    console.log('----------');
    console.log('request - ', request);
    console.log('response - ', response); // debugger
  }, 1000);
};

const userData = {
  userName: 'work.fse@gmail.com',
  email: 'work.fse@gmail.com',
  token:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ3b…nkifQ.9ofgzvD7dGghWJ2Z_bpyXmhWUr-wQfgCPLCdjoivblY',
};

const simulated_router = (request: any) => {
  console.log('request.url - ', request.url);

  switch (
    request.url // => ["Listing", ...]
  ) {
    case `https://api.an-kor.com/api/User/Login`:
      // console.log(); debugger
      return userData;
    case `https://api.an-kor.com/api/CustomerService/contact`:
      // console.log(); debugger
      return true;
    default:
      break;
  }
};

export default psevdo_axios;
