import React from 'react';

import { connect } from 'react-redux';

import { ReactComponent as SkypeSVG } from '../../About/skype.svg';

import { useTranslation, Trans } from 'react-i18next';

import { Link } from 'react-router-dom';

import Masonry from 'react-masonry-css';
import './Massonry.css';

// https://www.npmjs.com/package/@fortawesome/react-fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCoffee,
  faVideo,
  faUserPlus,
  faSignInAlt,
  faHome,
  faSearch,
  faMapMarker,
  faMapMarkerAlt,
  faMapSigns,
  faUniversalAccess,
} from '@fortawesome/free-solid-svg-icons';

// images
import skipMM_video_presentation from '../../../../images/portfolio/1.jpg';
import skipMM from '../../../../images/home/skip-mm.jpg';
import skipMmSearch from '../../../../images/home/skip-mm-search.jpg';
import skipMmSignUp from '../../../../images/home/skip-mm-sign-up.jpg'; 


import gms_crm_1 from '../../../../images/home/gms_crm_1.png';
import lms_1 from '../../../../images/home/lms_1.png';
import booking_1 from '../../../../images/home/booking_1.png';
import rate from '../../../../images/home/rate-sl-5.jpg';
import test_portfolio_1 from '../../../../images/home/test_portfolio_1.png';
import test_portfolio_2 from '../../../../images/home/test_portfolio_2.png';
import fancentro from '../../../../images/home/fancentro_1.png';
import skipmm from '../../../../images/home/skip_mm.png';
import relay_portfolio_1 from '../../../../images/home/relay_portfolio_1.png';
import virtual_machine from '../../../../images/home/virtual_machine.webp';



import skipMmLogin from '../../../../images/home/skip-mm-login.jpg';
import skipMmListingStepGeneralSection from '../../../../images/home/skip-mm-listing-general-section.jpg';
import skipMmListingStep_1 from '../../../../images/home/skip-mm-listing-step-1.jpg';
// import skipMmListingStep_2 from '../../../../images/home/skip-mm-listing-step-2.jpg';
import skipMmListingStep_2 from '../../../../images/home/skip-mm-general.jpg';
import skipMmListingStep_3 from '../../../../images/home/skip-mm-listing-step-3.jpg';
import skipMmAccount from '../../../../images/home/skip-mm-account.jpg';


// import Video from '../../VideoPlayer';
// import Modal from 'react-modal';

// import VideoModal from '../../../common/Modal';

import VideoPlayer from '../../../../components/home/VideoPlayer';

import Gallery from './Gallery';

/**
 * https://www.npmjs.com/package/react-modal
 */
import Modal from 'react-modal';

// Modal.defaultStyles.overlay.backgroundColor = 'cornsilk';
Modal.defaultStyles.overlay.backgroundColor = 'rgba(0, 0, 0, 0.12)';
Modal.defaultStyles.overlay.zIndex = '99';

const customStyles = {
  /*
   */
  content: {
    top: '50%',
    left: '50%',
    // right: 'auto',
    // bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    height: '90vh',
    // width: '90vh',
  },
};

var subtitle;

function MassonryGallery(props) {
  const { t } = useTranslation();

  const breakpointColumnsObj = {
    default: 2,
    1100: 2,
    700: 1,
    500: 1,
  };

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [videoNumber, setVideoNumber] = React.useState(null);
  const [imgForModal, setImgForModal] = React.useState(skipMM);


  const [selectedWork, setSelectedWork] = React.useState('relay_payments');


  const openModal = (imgValue, value) => {
    setIsOpen(true);
    setVideoNumber(value);
    setImgForModal(imgValue);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const wantToSendEmail = (e, sentText) => {
    closeModal();
    props.moreButtonClick(e, sentText)
  }

  /**
   * Некоторые данные вытаскиваются из переводов по принципу "или", а некоторые по 
   * принципу "И". Например:
   * title вытаскивается ИЛИ такой ИЛИ такой.
   * А вот facts.text вытаскивается И такой И такой*.
   * 
   * * В переводах указываются пустые поля, а тут они всеравно достаются.. тем самым мы
   * избегаем ошибки с пустыми полями.
   * 
   * ** Мы можем тут в массив тайтла вытаскивать все переводы тайтла, но в Рендере
   * выводить только одну запись из массива... В Рендере соответственно, записи
   * типа "И" мы Маппим, а типа "ИЛИ" выводим как одиночные!
   */
  const modalData = {
    "title": [
      `Works.${selectedWork}.project.title.text_1`, 
      `Works.${selectedWork}.project.title.text_2`,
    ],
    "descriptions": [
      // `Works.${selectedWork}.company.descriptions.text_1`,
      // `Works.${selectedWork}.company.descriptions.text_2`,
      // `Works.${selectedWork}.company.descriptions.text_3`,
      // `Works.${selectedWork}.company.descriptions.text_4`,
      // `Works.${selectedWork}.company.descriptions.text_5`,
      `Works.${selectedWork}.project.descriptions.text_1`,
      `Works.${selectedWork}.project.descriptions.text_2`,
      // `Works.${selectedWork}.project.descriptions.text_3`,
      // `Works.${selectedWork}.project.descriptions.text_4`,
      // `Works.${selectedWork}.project.descriptions.text_5`,
    ],
    "facts": {
      "title": `Works.${selectedWork}.project.facts.title`,
      "text": [
        `Works.${selectedWork}.project.facts.text.text_1`, 
        `Works.${selectedWork}.project.facts.text.text_2`, 
        `Works.${selectedWork}.project.facts.text.text_3`,
        `Works.${selectedWork}.project.facts.text.text_4`,
        `Works.${selectedWork}.project.facts.text.text_5`,
      ],
    },
    "contacts": {
      "title": `Works.${selectedWork}.project.contacts.title`,
      "data": [
        { "href": `Works.${selectedWork}.project.contacts.data.data_1.href`, "text": `Works.${selectedWork}.project.contacts.data.data_1.text` },
        { "href": `Works.${selectedWork}.project.contacts.data.data_2.href`, "text": `Works.${selectedWork}.project.contacts.data.data_2.text` },
        { "href": `Works.${selectedWork}.project.contacts.data.data_3.href`, "text": `Works.${selectedWork}.project.contacts.data.data_3.text` },
        { "href": `Works.${selectedWork}.project.contacts.data.data_4.href`, "text": `Works.${selectedWork}.project.contacts.data.data_4.text` },
        { "href": `Works.${selectedWork}.project.contacts.data.data_5.href`, "text": `Works.${selectedWork}.project.contacts.data.data_5.text` },
      ] 
    },
    "recommendations": {
      "title": `Works.${selectedWork}.project.recommendations.title`,
      "data": [
        { "href": `Works.${selectedWork}.project.recommendations.data.data_1.href`, "text": `Works.${selectedWork}.project.recommendations.data.data_1.text` },
        { "href": `Works.${selectedWork}.project.recommendations.data.data_2.href`, "text": `Works.${selectedWork}.project.recommendations.data.data_2.text` },
        { "href": `Works.${selectedWork}.project.recommendations.data.data_3.href`, "text": `Works.${selectedWork}.project.recommendations.data.data_3.text` },
        { "href": `Works.${selectedWork}.project.recommendations.data.data_4.href`, "text": `Works.${selectedWork}.project.recommendations.data.data_4.text` },
        { "href": `Works.${selectedWork}.project.recommendations.data.data_5.href`, "text": `Works.${selectedWork}.project.recommendations.data.data_5.text` },
      ]
    },
    "technologies": {
      "title": `Works.${selectedWork}.project.technologies.title`,
      "text": [
        `Works.${selectedWork}.project.technologies.text.text_1`, 
        `Works.${selectedWork}.project.technologies.text.text_2`, 
        `Works.${selectedWork}.project.technologies.text.text_3`,
        `Works.${selectedWork}.project.technologies.text.text_4`,
        `Works.${selectedWork}.project.technologies.text.text_5`,
      ],
    },
    "responsibilities": {
      "title": `Works.${selectedWork}.project.responsibilities.title`,
      "text": [
        `Works.${selectedWork}.project.responsibilities.text.text_1`, 
        `Works.${selectedWork}.project.responsibilities.text.text_2`, 
        `Works.${selectedWork}.project.responsibilities.text.text_3`,
        `Works.${selectedWork}.project.responsibilities.text.text_4`,
        `Works.${selectedWork}.project.responsibilities.text.text_5`,
      ],
    },
    "more_button": [
      `Works.${selectedWork}.project.get_more_button.button_text`, 
      `Works.${selectedWork}.project.get_more_button.text_send_from_button`
    ],
  };

  const createModalData = () => {

    const { t } = useTranslation();

    const canSendEmail = () => (
      <div className="center" style={{ marginBottom: '30px' }}>
        <a href="#" className="more-in-blog-post" onClick={(e) => wantToSendEmail( e, t(modalData.more_button[1]) )}>
          { t(modalData.more_button[0]) }
          <i className="flaticon-next-1"></i>
        </a>
      </div>
    );
  
    const dontCanSendEmail = () => (
      // <div className="center" style={{ marginBottom: '30px' }}>
      <div class="theme-title-three text-center" style={{marginTop: '50px', marginBottom: "50px"}}>
        <h3>{ t('GeneralPhrases.contact_form_sended') }:</h3>
       
        <div class="container">
          <div class="row">
            <div class="col-lg-6" style={{ paddingRight: "0px" }}>

              <div className="card-contact" style={{justifyContent: 'right'}}>
                { t('Contacts.text_1') }
                {/* &nbsp;&nbsp; */}
                <svg
                  style={{ marginLeft: "12px" }}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0118 0z" />
                  <circle cx="12" cy="10" r="3" />
                </svg>
              </div>

              <div className="card-contact" style={{justifyContent: 'right'}}>
                +38095 136 0 138 <br /> (tel, telegram, viber, whatsap)
                {/* &nbsp;&nbsp; */}
                <svg
                  style={{ marginLeft: "12px" }}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M22 16.92v3a2 2 0 01-2.18 2 19.79 19.79 0 01-8.63-3.07 19.5 19.5 0 01-6-6 19.79 19.79 0 01-3.07-8.67A2 2 0 014.11 2h3a2 2 0 012 1.72 12.84 12.84 0 00.7 2.81 2 2 0 01-.45 2.11L8.09 9.91a16 16 0 006 6l1.27-1.27a2 2 0 012.11-.45 12.84 12.84 0 002.81.7A2 2 0 0122 16.92z" />
                </svg>
              </div>

            </div>

            <div class="col-lg-6" style={{ paddingLeft: "0px" }}>

              <div className="card-contact" style={{justifyContent: 'left'}}>
                <svg
                  style={{ borderRight: 'none', marginRight: '0px' }}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
                  <path d="M22 6l-10 7L2 6" />
                </svg>
                work.fse@gmail.com
              </div>

              <div className="card-contact" style={{justifyContent: 'left'}}>
                <SkypeSVG style={{ borderRight: 'none', marginRight: '0px' }} />
                yuriy.evl (skype)
              </div>

            </div>
          </div>
        </div>
      </div>
    );

    return(
      <>
        <hr style={{ marginTop: '50px' }} />


        <div className="theme-title-three text-center mb-200" style={{ marginBottom: '10px' }}>
          
          <h2 className="title">{t(modalData.title[1])}</h2>
          { modalData.descriptions.map( (el, i) => <p style={{ paddingTop: '0px' }}>{t(el)}</p> ) }

          <div className="container">
            <div className="row" style={{ justifyContent: 'center' }}>
              <div class="col-lg-6">
                <h3>{ t(modalData.facts.title) }</h3>
                <ul>{ 
                  modalData.facts.text.map( 
                    (el, i) => {
                      if ( t(el).length === 0 ) return("");
                      return(<li>{t(el)}</li>);
                    } 
                  ) 
                }</ul>
              </div>
              <div class="col-lg-3">
                <h3>{ t(modalData.contacts.title) }</h3>
                <ul>{ 
                  modalData.contacts.data.map( 
                    (el, i) => {
                      if ( t(el.href) === "no data" ) return( <li>{t(el.text)}</li> );
                      if ( t(el.href).length === 0 ) return("");
                      return(<li><a href={t(el.href)} target="_blank">{t(el.text)}</a></li>);
                    } 
                  ) 
                }</ul>
              </div>
              <div class="col-lg-3">
                <h3>{ t(modalData.recommendations.title) }</h3>
                <ul>{ 
                  modalData.recommendations.data.map( 
                    (el, i) => {
                      if ( t(el.href) === "no data" ) return( <li>{t(el.text)}</li> );
                      if ( t(el.href).length === 0 ) return("");
                      return(<li><a href={t(el.href)} target="_blank">{t(el.text)}</a></li>);
                    } 
                  ) 
                }</ul>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row" style={{ justifyContent: 'center' }}>
              <div class="col-lg-6">
                <h3>{ t(modalData.technologies.title) }</h3>
                <ul>{ 
                  modalData.technologies.text.map( 
                    (el, i) => {
                      if ( t(el).length === 0 ) return("");
                      return(<li>{t(el)}</li>);
                    }
                  ) 
                }</ul>
              </div>
              <div class="col-lg-6">
                <h3>{ t(modalData.responsibilities.title) }</h3>
                <ul>{ 
                  modalData.responsibilities.text.map( 
                    (el, i) => {
                      if ( t(el).length === 0 ) return("");
                      return(<li>{t(el)}</li>);
                    } 
                  ) 
                }</ul>
              </div>
            </div>
          </div>

        </div>


        {props.isUserSentContactMessageEarly[0] === false ? canSendEmail() : dontCanSendEmail()}


        {/* <div className="center" style={{ marginBottom: '30px' }}>
          <a href="#" className="more-in-blog-post" onClick={(e) => wantToSendEmail( e, t(modalData.more_button[1]) )}>
            { t(modalData.more_button[0]) }
            <i className="flaticon-next-1"></i>
          </a>
        </div> */}

        {/* <Masonry 
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          { listProjectsData.map( (el, i) => createProject(...el, i) ) }
        </Masonry> */}

      </>
    );
  }


  const createProject = (title, text, image, workName='relay_payments', i) => {
    if (workName === "stub") return(<></>);

    return(
      <>
        <div key={i} class="isotop-item mt-150" onClick={ () => {openModal(image); setSelectedWork(workName)} }>
          <div class="item-content">
            <div class="img-box">
              <img src={image} alt="" />
            </div>
            <div class="text">
              {/* cl-two - красный цвет, cl-four - синий цвет | <div class="icon-box cl-two"><FontAwesomeIcon icon={faUserPlus} /></div> */}
              <h3 class="massonryTitle">{t(title)}</h3>
              <p>{t(text)}</p>
              <a href="#" class="read-more">+</a>
            </div>
          </div>
        </div>
      </>
    );
  }
  
  const listProjectsData = [
    ['Works.booking_1.project.title.text_2', 'Works.booking_1.project.descriptions.text_1', booking_1, 'booking_1'],
    ['Works.LMS.project.title.text_2', 'Works.LMS.project.descriptions.text_1', lms_1, 'LMS'],
    // ['Works.netvertise.company.title.text_1', 'Works.netvertise.project.descriptions.text_1', virtual_machine, 'netvertise'],
    ['Works.am_bits.project.title.text_2',  'Works.am_bits.project.descriptions.text_1', gms_crm_1, 'am_bits'],
    ['Works.relay_payments.project.title.text_2',  'Works.relay_payments.project.descriptions.text_1', relay_portfolio_1, 'relay_payments'],
    ['Works.continuum.project.title.text_2', 'Works.continuum.project.descriptions.text_1', fancentro, 'continuum'],
    ['Works.stucco_media.project.title.text_2', 'Works.stucco_media.project.descriptions.text_1', skipmm, 'stucco_media'],
    ['In the process of data filling', 'In the process of data filling.', rate, 'previous_experience'],
    ['stub', 'stub', 'stub', 'stub'],
    ['stub', 'stub', 'stub', 'stub'],
    // ['Сreate account.', 'To find your property on the map, you need create you account.', test_portfolio_2, 'relay_payments'],
    // ['Сreate account.', 'To find your property on the map, you need create you account.', test_portfolio_1, 'relay_payments'],
    // ['Home page.', 'SkipMM aims to help you as a homeowners skip the middleman and sell your home on your own.', skipMM, 'stucco_media'],
  ];


  return (
    <>
      <div class="theme-title-three text-center mb-200" style={{ marginBottom: '0px' }}>
        <h2 class="title">{t('Portfolio.super_title_1')}</h2>
        <p>{t('Portfolio.super_description_1')}</p>
      </div>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        { listProjectsData.map( (el, i) => createProject(...el, i) ) }
      </Masonry>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h2
          ref={_subtitle => (subtitle = _subtitle)}
          onClick={closeModal}
          style={{ float: 'right', cursor: 'pointer', fontSize: '2.5rem', color: 'red' }}
        >{t('GeneralPhrases.close_modal')} &times;</h2>

        {createModalData()}

        <img src={imgForModal} />
        {/* {videoNumber ? <VideoPlayer videoNumber={videoNumber} /> : <img src={imgForModal} />} */}
      </Modal>
    </>
  );
}


const mapStateToProps = state => ({
  isUserSentContactMessageEarly: state.auth.isUserSentContactMessageEarly,
});

const mapDispatchToProps = {
  // contactFormHighlight,
};

export default connect(mapStateToProps, mapDispatchToProps)(MassonryGallery);



// export default MassonryGallery;







/*
SkipMM aims to help you as a homeowners skip the middleman and sell your home on your own. SkipMM equip you with a comprehensive set of the tools you will need to sell your home on your terms while saving the most money in the process.

With SkipMM you can list your home to a Multiple Listing Service (MLS) for free – a process that typically costs hundreds of dollars and allows your home to be distributed automatically to sites like Zillow, Realtor.com, and more. After you register on the site, you can find your property on the map and fill details of your property.

Work on a project called SkipMM. After you register on the site, you can find your property on the map and fill details of your property, then SkipMM list your home to a Multiple Listing Service (MLS) for free – a process that typically costs hundreds of dollars and allows your home to be distributed automatically to sites like Zillow, Realtor.com, and more.
*/






/*
<h2
ref={_subtitle => (subtitle = _subtitle)}
onClick={closeModal}
style={{ float: 'right', cursor: 'pointer', fontSize: '2.5rem', color: 'red' }}
>
Close Modal X
</h2>
<hr style={{ marginTop: '50px' }} />

<div class="center">
<a 
  href="#" 
  className="more-in-blog-post"
  onClick={e => wantToSendEmail(e)}
>
  Want to know more?
  <i class="flaticon-next-1"></i>
</a>
</div>

<hr style={{ marginTop: '50px' }} />
<br />


<VideoPlayer videoNumber={videoNumber} />
<Gallery />
<div {...props}></div>
{console.log(videoNumber)}
<img src={imgForModal} />


{videoNumber ? <VideoPlayer videoNumber={videoNumber} /> : <img src={imgForModal} />}
*/



/*
<VideoPresentation_SkipMM value="2" openModal={openModal} />
<SignUp_SkipMM openModal={openModal} value="value" />
<Home_SkipMM openModal={openModal} value="value" />
<HomeSearch_SkipMM openModal={openModal} value="value" />
<></>
<ListingStep1_SkipMM openModal={openModal} value="value" />
<ListingStep2_SkipMM openModal={openModal} value="value" />
<Account_SkipMM openModal={openModal} value="value" />
<Login_SkipMM openModal={openModal} value="value" />
*/

/*
const SignUp_SkipMM = ({ openModal, value }) => (
  <>
    <div class="isotop-item mt-150" onClick={() => openModal(skipMmSignUp)}>
      <div class="item-content">
        <div class="img-box">
          <img src={skipMmSignUp} alt="" />
        </div>
        <div class="text">
          <div class="icon-box cl-two">
            <FontAwesomeIcon icon={faUserPlus} />
          </div>
          <h3 class="massonryTitle">Сreate account.</h3>
          <p>To find your property on the map, you need create you account.</p>
          <a href="#" class="read-more">
            +
          </a>
        </div>
      </div>
    </div>
  </>
);

const HomeSearch_SkipMM = ({ openModal }) => (
  <>
    <div class="isotop-item mt-150" onClick={() => openModal(skipMmSearch)}>
      <div class="item-content">
        <div class="img-box">
          <img src={skipMmSearch} alt="" />
        </div>
        <div class="text">
          <div class="icon-box cl-two">
            <FontAwesomeIcon icon={faSearch} />
          </div>
          <h3 class="massonryTitle">Search.</h3>
          <p>Search you real estate using data by Google API.</p>
          <a href="#" class="read-more">
            +
          </a>
        </div>
      </div>
    </div>
  </>
);

const ListingStep1_SkipMM = ({ openModal }) => (
  <div class="isotop-item" onClick={() => openModal(skipMmListingStep_1)}>
    <div class="item-content">
      <div class="img-box">
        <img src={skipMmListingStep_1} alt="" />
      </div>
      <div class="text">
        <div class="icon-box cl-two">
          <FontAwesomeIcon icon={faMapMarkerAlt} />
        </div>
        <h3 class="massonryTitle">Property on the map.</h3>
        <p>
          You can find your property on the map and enter how much you want to earn for you house.
        </p>
        <a href="#" class="read-more">
          +
        </a>
      </div>
    </div>
  </div>
);

const ListingStep2_SkipMM = ({ openModal }) => (
  <div class="isotop-item" onClick={() => openModal(skipMmListingStep_2)}>
    <div class="item-content">
      <div class="img-box">
        <img src={skipMmListingStep_2} alt="" />
      </div>
      <div class="text">
        <div class="icon-box cl-two">
          <FontAwesomeIcon icon={faMapSigns} />
        </div>
        <h3 class="massonryTitle">Details of property.</h3>
        <p>
          Fill details of your property and you can list your home to a Multiple Listing Service
          (MLS).
        </p>
        <a href="#" class="read-more">
          +
        </a>
      </div>
    </div>
  </div>
);

const Account_SkipMM = ({ openModal }) => (
  <div class="isotop-item" onClick={() => openModal(skipMmAccount)}>
    <div class="item-content">
      <div class="img-box">
        <img src={skipMmAccount} alt="" />
      </div>
      <div class="text">
        <div class="icon-box cl-two">
          <FontAwesomeIcon icon={faUniversalAccess} />
        </div>
        <h3 class="massonryTitle">Account.</h3>
        <p>You can manage lists of you property on you account.</p>
        <a href="#" class="read-more">
          +
        </a>
      </div>
    </div>
  </div>
);
*/

/*---------------------------------------------------------------------------------------------*/
/*
const VideoPresentation_SkipMM = ({ openModal, value }) => {
  // console.log(value);
  // debugger;
  return (
    <>
      <div class="isotop-item mt-150" onClick={() => openModal(skipMmLogin, value)}>
        <div class="item-content">
          <div class="img-box">
            <img src={skipMmLogin} alt="skipMM video presentation" />
          </div>
          <div class="text">
            <div class="icon-box cl-two">
              <FontAwesomeIcon icon={faVideo} />
            </div>
            <h3 class="massonryTitle">Video Presentation.</h3>
            <p>Video presentation of the SkipMM.</p>
            <a href="#" class="read-more">
              +
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

const Login_SkipMM = ({ openModal }) => (
  <>
    <div class="isotop-item mt-150" onClick={() => openModal(skipMmLogin)}>
      <div class="item-content">
        <div class="img-box">
          <img src={skipMmLogin} alt="" />
        </div>
        <div class="text">
          <div class="icon-box cl-two">
            <FontAwesomeIcon icon={faSignInAlt} />
          </div>
          <h3 class="massonryTitle">Login.</h3>
          <p>Login page.</p>
          <a href="#" class="read-more">
            +
          </a>
        </div>
      </div>
    </div>
  </>
);

const ListingStep3_SkipMM = ({ openModal }) => (
  <div class="isotop-item" onClick={() => openModal(skipMmListingStep_3)}>
    <div class="item-content">
      <div class="img-box">
        <img src={skipMmListingStep_3} alt="" />
      </div>
      <div class="text">
        <div class="icon-box cl-four">
          <i class="fa fa-fan"></i>
        </div>
        <h3 class="massonryTitle">Listing page N3.</h3>
        <p>Enter ... .... .. ..... ... ........</p>
        <a href="#" class="read-more">
          +
        </a>
      </div>
    </div>
  </div>
);

const GeneralSection_SkipMM = ({ openModal }) => (
  <div class="isotop-item" onClick={() => openModal(skipMmListingStepGeneralSection)}>
    <div class="item-content">
      <div class="img-box">
        <img src={skipMmListingStepGeneralSection} alt="" />
      </div>
      <div class="text">
        <div class="icon-box cl-four">
          <i class="fa fa-fan"></i>
        </div>
        <h3 class="massonryTitle">GeneralSection.</h3>
        <p>Enter ... .... .. ..... ... ........</p>
        <a href="#" class="read-more">
          +
        </a>
      </div>
    </div>
  </div>
);
*/

/*
      <div class="theme-title-three text-center mb-200" style={{ marginBottom: '50px' }}>
        <p>
          After you register on the site, you can find your property on the map and fill details of
          your property.
        </p>
      </div>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        <HomeSearch_SkipMM openModal={openModal} value="value" />

        <ListingStep3_SkipMM openModal={openModal} value="value" />
        
        <GeneralSection_SkipMM openModal={openModal} value="value" />
      </Masonry>
*/

/*
const First = ({ doSomething, value, openModal }) => (
  <>
  <div className="isotop-item mt-150">
  <div class="item-content">
    <Link to="portfolio/skipMM">
      <div class="img-box">
        <img src={skipMM} alt="" />
      </div>
      <div class="text">
        <div class="icon-box cl-two">
          <i class="fa fa-google" aria-hidden="true"></i>
        </div>
        <h3 class="massonryTitle">Google.</h3>
        <p>We product work with google.</p>
        <a href="#" class="read-more">
          +
        </a>
      </div>
    </Link>
  </div>
</div>
</>
);
*/

/*
function MassonryGallery(props) {
  const breakpointColumnsObj = {
    default: 2,
    1100: 2,
    700: 1,
    500: 1,
  };

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [videoNumber, setVideoNumber] = React.useState(0);

  const openModal = value => {
    // console.log('open modal function');
    setIsOpen(true);
    setVideoNumber(value);
  };

  const afterOpenModal = () => {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  // const doSomething = value => {
  //   console.log('doSomething called by child with value:', value);
  //   setIsOpen(true);
  // };

  // --------------

  // @ts-ignore
  const First = ({ doSomething, value }) => (
    <div class="isotop-item mt-150" onClick={() => openModal(value)}>
      <div class="item-content">
        <div class="img-box">
          <img src={serv} alt="" />
        </div>
        <div class="text">
          <div class="icon-box cl-two">
            <i class="fa fa-google" aria-hidden="true"></i>
          </div>
          <h3 class="massonryTitle">Google.</h3>
          <p>We product work with google.</p>
          <a href="#" class="read-more">
            +
          </a>
        </div>
      </div>
    </div>
  );

  // @ts-ignore
  const Second = ({ doSomething, value }) => (
    <div class="isotop-item" onClick={() => openModal(value)}>
      <div class="item-content">
        <div class="img-box">
          <img src={ImgGalleryThree} alt="" />
        </div>
        <div class="text">
          <div class="icon-box cl-four">
            <i class="fa fa-dropbox" aria-hidden="true"></i>
          </div>
          <h3 class="massonryTitle">Dropbox.</h3>
          <p>We product work with Dropbox.</p>
          <a href="#" class="read-more">
            +
          </a>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        <First doSomething="" value="0" />
        <Second doSomething="" value="1" />
      </Masonry>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h2 ref={_subtitle => (subtitle = _subtitle)} onClick={closeModal}>
          Hello
        </h2>

        <VideoPlayer videoNumber={videoNumber} />
      </Modal>
    </>
  );
}
*/

/*
import VideoModal from '../../../common/Modal';

function MassonryGallery(props) {
  const breakpointColumnsObj = {
    default: 2,
    1100: 2,
    700: 1,
    500: 1,
  };

  // @ts-ignore
  const First = ({ doSomething, value }) => (
    <div class="isotop-item mt-150" onClick={() => doSomething(value)}>
      <div class="item-content">
        <div class="img-box">
          <img src={ImgGalleryTwo} alt="" />
        </div>
        <div class="text">
          <div class="icon-box cl-two">
            <i class="fa fa-google" aria-hidden="true"></i>
          </div>
          <h3 class="massonryTitle">Google.</h3>
          <p>We product work with google.</p>
          <a href="#" class="read-more">
            +
          </a>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        <First doSomething="" value="1" />
        <First doSomething="" value="2" />
        <VideoModal>
          <First doSomething="" value="1" />
          <First doSomething="" value="2" />
        </VideoModal>
      </Masonry>
    </>
  );
}

export default MassonryGallery;
*/

/*
        <div class="isotop-item mt-150" onClick={hadleFormSubmit}>
          <div class="item-content">
            <div class="img-box">
              <img src={ImgGalleryTwo} alt="" />
            </div>
            <div class="text">
              <div class="icon-box cl-two">
                <i class="fa fa-google" aria-hidden="true"></i>
              </div>
              <h3 class="massonryTitle">Google.</h3>
              <p>We product work with google.</p>
              <a href="#" class="read-more">
                +
              </a>
            </div>
          </div>
        </div>

        <div class="grid-sizer">
          <div class="item-content">
            <div class="img-box">
              <Video /> 
              </div>
              <div class="text">
                <div class="icon-box cl-one">
                  <i class="fa fa-gg" aria-hidden="true"></i>
                </div>
                <h3 class="massonryTitle">Uber.</h3>
                <p>The best client we work with.</p>
                <a href="#" class="read-more">
                  +
                </a>
              </div>
            </div>
          </div>
  
          <div class="isotop-item">
            <div class="item-content">
              <div class="img-box">
                <img src={ImgGalleryThree} alt="" />
              </div>
              <div class="text">
                <div class="icon-box cl-four">
                  <i class="fa fa-dropbox" aria-hidden="true"></i>
                </div>
                <h3 class="massonryTitle">Dropbox.</h3>
                <p>We product work with Dropbox.</p>
                <a href="#" class="read-more">
                  +
                </a>
              </div>
            </div>
          </div>
  
          <div class="isotop-item">
            <div class="item-content">
              <div class="img-box">
                <img src={ImgGalleryFour} alt="" />
              </div>
              <div class="text">
                <div class="icon-box cl-three">
                  <i class="fa fa-linkedin" aria-hidden="true"></i>
                </div>
                <h3 class="massonryTitle">Invision.</h3>
                <p>We product work with Invision.</p>
                <a href="#" class="read-more">
                  +
                </a>
              </div>
            </div>
          </div>
*/

/*
  const [isShowModal, setShowModal] = useState(true);

  const hadleFormSubmit = () => {
    console.log('hadleFormSubmit');
    setShowModal(true);
  };

  const handleCloseModal = () => {
    console.log('handleCloseModal');
  };

*/

/*
function MassonryGallery(props) {
  const breakpointColumnsObj = {
    default: 2,
    1100: 2,
    700: 1,
    500: 1,
  };

  const [isShowModal, setShowModal] = useState(true);

  const hadleFormSubmit = () => {
    console.log('hadleFormSubmit');
    setShowModal(true);
  };

  const handleCloseModal = () => {
    console.log('handleCloseModal');
  };

  return (
    <>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        <div class="isotop-item mt-150" onClick={hadleFormSubmit}>
          <div class="item-content">
            <div class="img-box">
              <img src={ImgGalleryTwo} alt="" />
            </div>
            <div class="text">
              <div class="icon-box cl-two">
                <i class="fa fa-google" aria-hidden="true"></i>
              </div>
              <h3 class="massonryTitle">Google.</h3>
              <p>We product work with google.</p>
              <a href="#" class="read-more">
                +
              </a>
            </div>
          </div>
        </div>

        <div class="grid-sizer">
          <div class="item-content">
            <div class="img-box">
              <Video />
              </div>
              <div class="text">
                <div class="icon-box cl-one">
                  <i class="fa fa-gg" aria-hidden="true"></i>
                </div>
                <h3 class="massonryTitle">Uber.</h3>
                <p>The best client we work with.</p>
                <a href="#" class="read-more">
                  +
                </a>
              </div>
            </div>
          </div>
  
          <div class="isotop-item">
            <div class="item-content">
              <div class="img-box">
                <img src={ImgGalleryThree} alt="" />
              </div>
              <div class="text">
                <div class="icon-box cl-four">
                  <i class="fa fa-dropbox" aria-hidden="true"></i>
                </div>
                <h3 class="massonryTitle">Dropbox.</h3>
                <p>We product work with Dropbox.</p>
                <a href="#" class="read-more">
                  +
                </a>
              </div>
            </div>
          </div>
  
          <div class="isotop-item">
            <div class="item-content">
              <div class="img-box">
                <img src={ImgGalleryFour} alt="" />
              </div>
              <div class="text">
                <div class="icon-box cl-three">
                  <i class="fa fa-linkedin" aria-hidden="true"></i>
                </div>
                <h3 class="massonryTitle">Invision.</h3>
                <p>We product work with Invision.</p>
                <a href="#" class="read-more">
                  +
                </a>
              </div>
            </div>
          </div>
        </Masonry>
  
        <Modal
          isOpen={false}
          onRequestClose={handleCloseModal}
          className=""
          overlayClassName="overlay"
        >
          <div className="">
            <p>Your data updated success!</p>
            <button title="Ok" style="" onClick={handleCloseModal} />
          </div>
        </Modal>
      </>
    );
  }
  
  export default MassonryGallery;
*/

/*
  <Masonry
      breakpointCols={breakpointColumnsObj}
      className="my-masonry-grid"
      columnClassName="my-masonry-grid_column"
  >                                  <div class="grid-sizer">
      <div>My Element</div>
      <div>My Element</div>
      <div>My Element</div>
      <div>My Element</div>
  </Masonry>
*/
