import React from 'react';
// import "./index.css";

import { Row, Col, Layout, Avatar, Badge } from 'antd';
import { Menu, Dropdown, Button } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined, UserOutlined } from '@ant-design/icons';

const { Header } = Layout;

const menu = (
  <Menu>
    <Menu.Item>
      <a target="_blank" rel="noopener noreferrer" href="http://www.alipay.com/">
        1st menu item
      </a>
    </Menu.Item>
    <Menu.Item>
      <a target="_blank" rel="noopener noreferrer" href="http://www.taobao.com/">
        2nd menu item
      </a>
    </Menu.Item>
    <Menu.Item>
      <a target="_blank" rel="noopener noreferrer" href="http://www.tmall.com/">
        3rd menu item
      </a>
    </Menu.Item>
  </Menu>
);

const AdminHeader = (props) => {
  return (
    <>
      <Header className="site-layout-background" style={{ padding: 0 }}>
        <Row>
          <Col span={6}>
            {React.createElement(props.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: 'trigger',
              onClick: props.toggle,
            })}
          </Col>
          <Col span={6}></Col>
          <Col span={6}>
            <Dropdown overlay={menu} placement="bottomRight">
              <Button>bottomRight</Button>
            </Dropdown>
          </Col>
          <Col span={6}>
            {/* <Avatar icon={<UserOutlined />} /> */}
            <Dropdown overlay={menu} placement="bottomRight">
              <span className="avatar-item">
                <Badge count={1}>
                  <Avatar shape="square" icon={<UserOutlined />} />
                </Badge>
              </span>
            </Dropdown>
          </Col>
        </Row>
      </Header>
    </>
  );
};

export default AdminHeader;
