import React, { Component } from 'react';
import ChatBot from 'react-simple-chatbot';

class HelloUser extends Component {
  render() {
    return (
      <ChatBot
        floating={this.props.floating}
        // bubbleStyle={this.props.bubbleStyle}
        floatingStyle={this.props.floatingStyle}
        botAvatar={this.props.botAvatar}
        avatarStyle={this.props.avatarStyle}
        userDelay={this.props.userDelay}
        width={this.props.width}
        floatingIcon={this.props.floatingIcon}
        opened={this.props.isChatOpen}
        steps={[
          {
            id: '1',
            message: 'What number I am thinking?',
            trigger: '2',
          },
          {
            id: '2',
            options: [
              { value: 1, label: 'Number 1', trigger: '4' },
              { value: 2, label: 'Number 2', trigger: '3' },
              { value: 3, label: 'Number 3', trigger: '3' },
            ],
          },
          {
            id: '3',
            message: 'Wrong answer, try again.',
            trigger: '2',
          },
          {
            id: '4',
            message: 'Awesome! You are a telepath!',
            end: true,
          },
        ]}
      />
    );
  }
}

export default HelloUser;
