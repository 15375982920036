import React, { FunctionComponent, useState, useEffect } from 'react';

import './Header.css';

import AccountDropdown from './AccountDropdown';

// import MyImageSvg from '../../images/logo/logo13.svg';
import MyImageSvg from '../../../images/logo/resume2.svg';

import { HOME } from 'constants/routes';

interface IProps {
  isHomePage?: boolean;
  pathname?: string;
}

const Header: FunctionComponent<IProps> = ({ isHomePage, pathname }: IProps) => {
  return (
    <>
      <div className="one-page-header-one">
        <div className="logo">
          <a href="#">
            <img src={MyImageSvg} alt="" />
          </a>
        </div>
      </div>

      {pathname === HOME ? '' : <AccountDropdown />}
    </>
  );
};

export default Header;

/*
          <h1>Header component</h1>
          <header>
            <NavLink to={HOME} >HOME</NavLink>
            <NavLink to={LOGIN} > - LOGIN</NavLink>            
            <NavLink to={TEST} > - TEST</NavLink>            
          </header>
*/

/*
    return(
        <NavLink
            to={ACCOUNT}
            className={styles.headerMenuItem}
            activeClassName={styles.activeMenuItem}
        >
            My Account
        </NavLink>
    );
*/
