import React from 'react';

import { connect } from 'react-redux';
// aplication actions
import { contactFormHighlight } from 'redux/modules/aplication/actions';

import './PortfolioContact.css';

import { Element, animateScroll as scroll } from 'react-scroll';

import { ReactComponent as SkypeSVG } from './skype.svg';

import { useTranslation, Trans } from 'react-i18next';

/**
 * @contactFormHighlight == action
 * @isUserSentContactMessageEarly == [false, 0]
 */
function PortfolioContact({ contactFormHighlight, isUserSentContactMessageEarly }) {
  const { t } = useTranslation();

  const sendMessageButtonClick = (e, html_title) => {
    e.preventDefault();

    setTimeout(() => {
      contactFormHighlight(html_title);
    }, 800);

    let options = {
      duration: 1500,
      delay: 100,
    };

    scroll.scrollTo(100, options);
  };

  const canSendEmail = () => (
    <div class="center">
      <a
        href="#"
        onClick={e =>
          sendMessageButtonClick(
            e,
            t('Contacts.button_text_2')
          )
        }
        class="more-in-blog-post"
      >
        {t('Contacts.button_text_1')}<i class="flaticon-next-1"></i>
      </a>
    </div>
  );

  const dontCanSendEmail = () => '';

  return (
    <>
      <Element name="bottomContact"></Element>
      <div className="agn-contact-banner">
        <div class="arch-contact">
          <div class="form-wrapper">
            <div class="container">
              <div class="inner-wrapper">
                <div class="row">
                  <div class="col-lg-12 order-lg-last">
                    <div class="contact-text">
                      <div class="theme-title-one arch-title">
                        <div class="upper-title">{t('Contacts.title_1')}</div>
                        <h2 class="botom-contact-title">
                          {t('Contacts.description_1')}
                        </h2>
                      </div>
                      <p class="botom-contact-subTitle">{t('Contacts.description_2')}</p>
                      <ul>
                        <li>
                          {t('Contacts.text_1')} <p>({t('Contacts.text_2')})</p>
                        </li>
                        <li>
                          +380 95 136 0 138 <p>({t('Contacts.text_3')})</p>
                        </li>
                        <li>
                          yuriy.evl <p>({t('Contacts.text_4')})</p>
                        </li>
                        <li>
                          work.fse@gmail.com <p>({t('Contacts.text_5')})</p>
                        </li>
                      </ul>
                      {/*
                      <div className="card-contact">
                        <SkypeSVG />
                        work.fse@gmail.com
                      </div>
                      */}
                    </div>

                    {isUserSentContactMessageEarly[0] == false
                      ? canSendEmail()
                      : dontCanSendEmail()}
                  </div>

                  {/*
                  <div class="col-lg-6 order-lg-first">
                    <form action="#">
                      <input type="email" placeholder="Email*" />
                      <textarea placeholder="Message"></textarea>
                      <button class="theme-button-two">Send</button>
                    </form>
                  </div>
                  */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = state => ({
  isUserSentContactMessageEarly: state.auth.isUserSentContactMessageEarly,
});

const mapDispatchToProps = {
  contactFormHighlight,
};

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioContact);
