import React, { Component, Suspense } from 'react';
import { useTranslation, withTranslation, Trans } from 'react-i18next';

// use hoc for class based components
class TestClassComponent extends Component {
  render() {
    const { t, i18n } = this.props;

    return (
      <>
        <h2>{t('title')}</h2>;
        <Trans i18nKey="description.part1">
          To get started, edit <code>src/App.js</code> and save to reload.
        </Trans>
      </>
    );
  }
}

export default withTranslation()(TestClassComponent);
