import React, { Component, Suspense } from 'react';
import { useTranslation, withTranslation, Trans } from 'react-i18next';

function TestTranslate() {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="App-intro">
        <Trans i18nKey="description.part1">
          To get started, edit <code>src/App.js</code> and save to reload.
        </Trans>
      </div>
    </>
  );
}

export default TestTranslate;
