import React from 'react';
import './Services.css';
import { useTranslation, withTranslation, Trans } from 'react-i18next';
import Rating from 'react-rating';

// https://github.com/fisshy/react-scroll
import {
  Link as AnchorLink,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from 'react-scroll';

// https://www.npmjs.com/package/@fortawesome/react-fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons';

/**
 *
 */
function Services() {
  const { t, i18n } = useTranslation();

  /*
    <Trans i18nKey="About.main_title_1_part">
      We’r highly <br />
    </Trans>
    <p className="font-lato">{t('About.main_text')}</p>
  */

  // const handleSetActive = to => {
  //   console.log(to);
  // };

  return (
    <>
      <Element name="skills"></Element>
      <div class="op-services-one">
        <div class="container">
          <div class="row">
            <div class="col-lg-4">
              <div class="title-text">{t('Services.super_title')}</div>
            </div>
            <div class="col-lg-8">
              <h2 class="theme-title-four font-k2d">
                <Trans i18nKey="Services.title">
                  I love to build usable <span>products.</span>
                </Trans>
              </h2>
              <p>
                <b className='allert'>{ t("GeneralPhrases.not_complet").toUpperCase() }</b>
                {/* I have experience with various technologies, libraries and frameworks. Here I will
                list only actual skills for now and for nearest future. */}
                {/*
                <br />
                <AnchorLink
                  activeClass="active"
                  to="test1"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                  onSetActive={handleSetActive}
                >
                  <p style={{ color: 'red' }}>Link</p>
                </AnchorLink>
                */}
              </p>




{/* 
Project Organization
- Setting up the project from scratch: Webpack, configuring React, Typescript, Babel, SCSS, CSS Modules, Vite, and Prettier.
- Configuring the testing environment: Jest, RTL (React Testing Library), Storybook, Loki, Cypress.
- Comprehensive infrastructure configuration to ensure that both the project itself, the testing environment, and Storybook - can utilize all features and operate as a unified whole.
Architecture
- Deep understanding of architecture and experience working with large projects.
- Orientation to business and user needs: A balance is maintained between DRY and local customization.
- Tech debt & Refactoring: Each module can be independently modified/rewritten without side effects.
- Isolation: The module should not depend directly on other modules of the same layer or overlying layers.
- Public API: Each module must have a declaration of its public API at the top level.
- Architecture components can be flexibly replaced and added for new requirements.
Project Implementation
- TypeScript (union types, generic components).
- CSS Modules and theming.
- Redux (Toolkit, RTK Query) / MobX.
- Creating a library of reusable components (everything in the project, from SVG icons to text, is components).
- Maximizing efficiency (avoiding unnecessary redraws and rerenders in the project).
- Asynchronous loading of libraries, components, reducers, and translations.
Linting and Prettier
- Code style (ESLint and stylelint).
- Custom plugins.
- Prettier.
Testing
- Storybook and screenshot tests.
- Unit and RTL tests.
- End-to-end testing.
- Reporting.
CI/CD
- Setting up CI pipeline, including tests for project build, Storybook build, and code linting.
- Publishing unit and screenshot test reports on CI GitHub pages. 



                <div class="list-item">
                    <h6 class="font-k2d">...</h6>
                    <ul>
                      <MapServices
                        arr={[
                          { title: '...', rating: 4.5 },
                          { title: '...', rating: 4.5 },
                          { title: '...', rating: 4.5 },
                          { title: '...', rating: 4.5 },
                          { title: '...', rating: 4.5 },
                        ]}
                      />
                    </ul>
                  </div>
*/}



              <div class="row">
                <div class="col-sm-12">
               
                  <div class="list-item">
                    <h6 class="font-k2d">Project Organization</h6>
                    <ul>
                      <MapServices
                        arr={[
                          { title: 'Setting up the project from scratch: Webpack, configuring React, Typescript, Babel, SCSS, CSS Modules, Vite, and Prettier.', rating: 4.4 },
                          { title: 'Configuring the testing environment: Jest, RTL (React Testing Library), Storybook, Loki, Cypress.', rating: 4.5 },
                          { title: 'Comprehensive infrastructure configuration to ensure that both the project itself, the testing environment, and Storybook - can utilize all features and operate as a unified whole.', rating: 4.5 },
                        ]}
                      />
                    </ul>
                  </div>

                  <div class="list-item">
                    <h6 class="font-k2d">Architecture</h6>
                    <ul>
                      <MapServices
                        arr={[
                          { title: 'Deep understanding of architecture and experience working with large projects.', rating: 4.5 },
                          { title: 'Orientation to business and user needs: A balance is maintained between DRY and local customization.', rating: 4.5 },
                          { title: 'Tech debt & Refactoring: Each module can be independently modified/rewritten without side effects.', rating: 4.5 },
                          { title: 'Isolation: The module should not depend directly on other modules of the same layer or overlying layers.', rating: 4.5 },
                          { title: 'Public API: Each module must have a declaration of its public API at the top level.', rating: 4.5 },
                          { title: 'Architecture components can be flexibly replaced and added for new requirements.', rating: 4.5 },
                        ]}
                      />
                    </ul>
                  </div>

                </div>


                <div class="col-sm-6">


                  <div class="list-item">
                    <h6 class="font-k2d">Front-end</h6>
                    <b className='allert'>{ t("GeneralPhrases.not_complet").toUpperCase() }</b>
                    {/* <ul>
                      <MapServices
                        arr={[
                          { title: 'JavaScript', rating: 4.4 },
                          { title: 'ReactJS', rating: 4.5 },
                          { title: 'TypeScript', rating: 4.2 },
                          { title: 'Redux-Saga', rating: 4.3 },
                          { title: 'Firebase', rating: 4.1 },
                          { title: 'CSS/HTML', rating: 3.2 },
                          { title: 'Sass', rating: 3 },
                          { title: 'React Native', rating: 2.0 },
                          { title: 'React VR', rating: 1.3 },
                        ]}
                      />
                    </ul> */}
                  </div>

                  {/* <div class="list-item">
                    <h6 class="font-k2d">Back-end</h6>
                    <ul>
                      <MapServices
                        arr={[
                          { title: 'PHP', rating: 4.3 },
                          { title: 'Laravel', rating: 4.2 },
                          { title: 'NodeJS', rating: 2.4 },
                        ]}
                      />
                    </ul>
                  </div> */}

                </div>

                {/*---------------------------------------*/}

                <div class="col-sm-6">
                  <div class="list-item">
                    <h6 class="font-k2d">Databases</h6>
                    <b className='allert'>{ t("GeneralPhrases.not_complet").toUpperCase() }</b>
                    {/* <ul>
                      <MapServices
                        arr={[
                          { title: 'SQL', rating: 3.9 },
                          { title: 'Eloquent', rating: 3.7 },
                          { title: 'NoSQL', rating: 3.8 },
                          { title: 'Firebase', rating: 4.1 },
                        ]}
                      />
                    </ul> */}
                  </div>

                  <div class="list-item pb-none">
                    <h6 class="font-k2d">Other</h6>
                    <b className='allert'>{ t("GeneralPhrases.not_complet").toUpperCase() }</b>
                    <ul>
                      {/* <MapServices
                        arr={[
                          { title: 'VR', rating: 1.5 },
                          { title: 'Git', rating: 4 },
                          { title: 'GitHub', rating: 3 },
                          { title: 'Bitbucket', rating: 2.8 },
                        ]}
                      /> */}
                      {/*
                        <Element name="test1" className="element">
                          <span style={{ color: 'red' }}>test 1</span>
                        </Element>
                        <li>
                          <Element name="test1" className="element"></Element>
                        </li>
                       */}
                    </ul>
                  </div>
                </div>

                {/*
                <div class="col-sm-6">
                  <div class="list-item">
                    <h6 class="font-k2d">Databases</h6>
                    <ul>
                      <MapServices
                        arr={[
                          { title: 'SQL', rating: 3.9 },
                          { title: 'Eloquent', rating: 3.7 },
                          { title: 'NoSQL', rating: 3.8 },
                          { title: 'Firebase', rating: 4.1 },
                        ]}
                      />
                    </ul>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="list-item">
                    <h6 class="font-k2d">Other</h6>
                    <ul>
                      <MapServices
                        arr={[
                          { title: 'VR', rating: 1.5 },
                          { title: 'VR', rating: 1.5 },
                          { title: 'Git', rating: 4 },
                          { title: 'GitHub', rating: 3 },
                          { title: 'Bitbucket', rating: 2.8 },
                        ]}
                      />
                      
                      <li>
                        <Element name="test1" className="element">
                          <span style={{ color: 'red' }}>test 1</span>
                        </Element>
                      </li>
                      
                    </ul>
                  </div>
                </div>
                */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Services;

/**
 *
 * @param {*} arr = [ { title: 'JavaScript', rating: 4.6 }, { title: 'ReactJS', rating: 4.5 } ]
 */
const MapServices = ({ arr }) =>
  arr.map((e, i) => (
    <li>
      <span style={{ paddingRight: '17px' }}>{e.title}</span>
      <div style={{ marginTop: '8px', lineHeight: '20px' }}>
        {/* <Rating
          emptySymbol={
            <span style={{ paddingRight: '5px', color: '#d6d6d6' }}>
              <FontAwesomeIcon icon={faGraduationCap} />
            </span>
          }
          fullSymbol={
            <span style={{ paddingRight: '5px' }}>
              <FontAwesomeIcon icon={faGraduationCap} />
            </span>
          }
          initialRating={e.rating}
          readonly
        /> */}
      </div>
    </li>
  ));

/*
  return arr.map((e, i) => (
    <li>
      <span style={{ paddingRight: '17px' }}>{e.title}</span>
      <div style={{ marginTop: '8px', lineHeight: '20px' }}>
        <Rating
          emptySymbol={
            <span style={{ paddingRight: '5px', color: '#d6d6d6' }}>
              <FontAwesomeIcon icon={faGraduationCap} />
            </span>
          }
          fullSymbol={
            <span style={{ paddingRight: '5px' }}>
              <FontAwesomeIcon icon={faGraduationCap} />
            </span>
          }
          initialRating={e.rating}
          readonly
        />
      </div>
    </li>
  ));
*/
