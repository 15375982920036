import React, { Component } from 'react';

import LoginIconMenu from '../../../../images/icon/icon38.svg';
import LoginIconProfile from '../../../../images/icon/icon39.svg';
import LoginIconSettings from '../../../../images/icon/icon40.svg';
import LoginIconBilling from '../../../../images/icon/icon42.svg';
import LoginIconWishlist from '../../../../images/icon/icon41.svg';

class AccountDropdown extends Component {
  state = {
    menuIsOpen: false,
    // language: 'eng', // => eng, ru
  };

  toggleMenu = () => {
    // console.log('toggleSubMenu function');
    this.setState(state => ({ menuIsOpen: !state.menuIsOpen }));
  };

  render() {
    // console.log( this.state.menuIsOpen );

    /*
        class={`eCommerce-side-menu op-page-menu-one ${
          props.menuIsOpen ? "show-menu" : ""
        }`}    
    */

    return (
      <>
        <div className="theme-Ecommerce-menu">
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ float: 'right', marginRight: '100px' }}
          >
            <div className="right-content">
              <ul>
                <li className="action-list-item user-profile-action">
                  <div className={`dropdown ${this.state.menuIsOpen && 'show'}`}>
                    <button
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                      onClick={this.toggleMenu}
                    >
                      >
                      <img src={LoginIconMenu} alt="" />
                      <span>Account</span>
                    </button>
                    <div
                      className={`dropdown-menu dropdown-menu-right ${this.state.menuIsOpen &&
                        'show'}`}
                    >
                      <ul>
                        <li>
                          <a href="#">
                            <img src={LoginIconProfile} alt="" className="icon svg" /> My Profile
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <img src={LoginIconSettings} alt="" className="icon svg" /> Settings
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <img src={LoginIconBilling} alt="" className="icon svg" /> Billing
                            Address
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <img src={LoginIconWishlist} alt="" className="icon svg" /> Wishlist
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AccountDropdown;
