// import firebase from '../../../../../firebase';
import firebase from '../firebase';

/**
 *
 * https://firebase.google.com/docs/reference/js/firebase.database.DataSnapshot#val
 * console.log(snapshot.numChildren()); // 23
 * console.log(snapshot.child(`${newPostKey}`).numChildren()); // 3
 * console.log(snapshot.child(`${newPostKey}`).key); // -M71jqk6aN_5dpTBLIZ0
 * console.log(snapshot.child(`${newPostKey}`).val()); // {content: "some content", email: "work.fse@gmail.com", name: "Garry"}
 * console.log(snapshot.child(`${newPostKey}`).val().email); // work.fse@gmail.com
 *
 * @param { name, email, content } => contact data from Home page
 */
export const setContactMessage = ({ name, email, content }) => {
  let promise = new Promise((resolve, reject) => {
    // A post entry
    let postData = {
      name,
      email,
      content,
    };

    // Get a key for a new contact message.
    let newPostKey = firebase
      .database()
      .ref()
      .child(`contactMessages`)
      .push().key;

    // Записываем данные нового сообщения одновременно в список сообщений и список сообщений пользователя.
    let updates = {};
    updates['/contactMessages/' + newPostKey] = postData;
    // updates['/user-posts/' + uid + '/' + newPostKey] = postData;

    var isConnect = false;

    firebase
      .database()
      .ref()
      .update(updates)
      .then(() => {
        isConnect = true;
        return firebase
          .database()
          .ref('contactMessages')
          .once('value');
      })
      .then(snapshot => {
        let lastSavedEmail = snapshot.child(`${newPostKey}`).val().email;
        if (lastSavedEmail == email) {
          resolve(true);
        }

        reject(false);
      });

    setTimeout(() => {
      if (!isConnect) {
        reject(false);
      }
    }, 5000);
  });

  return promise;
};

/**
 * https://firebase.google.com/docs/database/web/lists-of-data => Listen for value events
 *
 * @param {*} setPostsToState
 */
export const getBlogPosts = async setPostsToState => {
  let posts = [];

  let ref = firebase.database().ref(`blogPosts`);

  // Attaching a value observer to a list of data will return the entire list of data as
  // a single snapshot which you can then loop over to access individual children.
  let snapshot = await ref.once('value');
  // setPostsToState(posts);

  snapshot.forEach(childSnapshot => {
    let postKey = childSnapshot.key;
    let { title, description } = childSnapshot.val();

    // console.log(postKey, title, description);
    posts[postKey] = { title, description };
  });
  // console.log(posts);
  // debugger;
  setPostsToState(posts);

  /*
  snapshot.forEach(childSnapshot => {
    let postKey = childSnapshot.key;
    let postComments = getComments(postKey);
    
    postComments.then(comments => {
      let { title, content, metrics } = childSnapshot.val();
      let childData = { title, content, comments, metrics };
      // let childData = { title, content };
      posts.push(childData);
      
      // this.setState(state => ({ ...state, posts: posts }));
      setPostsToState(posts);
    });
  });
  */
};

/*---------------------------- Data for Test Blog ----------------------------------*/

// https://firebase.google.com/docs/database/web/lists-of-data => Listen for value events
export const getPosts = async setPosts => {
  let posts = [];

  let ref = firebase.database().ref(`test/blog/posts/php`);

  // Attaching a value observer to a list of data will return the entire list of data as
  // a single snapshot which you can then loop over to access individual children.
  let snapshot = await ref.once('value');
  snapshot.forEach(childSnapshot => {
    let postKey = childSnapshot.key;
    let postComments = getComments(postKey);

    postComments.then(comments => {
      let { title, content, metrics } = childSnapshot.val();
      let childData = { title, content, comments, metrics };
      // let childData = { title, content };
      posts.push(childData);

      // this.setState(state => ({ ...state, posts: posts }));
      setPosts(posts);
    });
  });
};

const getComments = async postKey => {
  let comments = [];
  let ref = firebase.database().ref(`test/blog/comments/${postKey}`);

  let snapshot = await ref.once('value');
  snapshot.forEach(childSnapshot => {
    let comment = childSnapshot.val();

    /*
      let arr = [];
      for (const key in comment.likes) {
        if (comment.likes.hasOwnProperty(key)) {
          // const element = comment.likes[key];
          arr.push({ [key]: comment.likes[key] });
        }
      }
      comment.likes = arr;
      */
    let plusArr = [];
    let minusArr = [];
    for (const key in comment.likes) {
      if (comment.likes.hasOwnProperty(key)) {
        if (comment.likes[key] == 1) {
          plusArr.push(key);
        } else if (comment.likes[key] == 0) {
          minusArr.push(key);
        }
      }
    }
    comment.likes = { plusArr, minusArr };

    // console.log(comment);
    // console.log(arr);
    // debugger;

    comments.push(comment);
  });

  return comments;
};

export const writeNewPost = (postTitle, postValue, user) => {
  // A post entry
  let postData = {
    title: postTitle,
    content: postValue,
    user: {
      id: user,
      name: '',
    },
  };

  // Get a key for a new Post.
  let newPostKey = firebase
    .database()
    .ref()
    .child(`test/blog/posts/php`)
    .push().key;

  // Записываем данные нового сообщения одновременно в список сообщений и список сообщений пользователя.
  let updates = {};
  updates['/test/blog/posts/php/' + newPostKey] = postData;
  // updates['/user-posts/' + uid + '/' + newPostKey] = postData;
  firebase
    .database()
    .ref()
    .update(updates);
};
