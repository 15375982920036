import React, { FunctionComponent } from 'react';

import { connect } from 'react-redux';
import { ApplicationState } from 'redux/temp_interface';

// aplication actions
import { changeLanguage, contactFormHighlight } from 'redux/modules/aplication/actions';
// auth actions
import { sentContactMessage } from 'redux/modules/auth/actions';

import Header from '../../components/common/Header';
import MainHeader from '../../components/home/MainHeader';
import Promo from '../../components/home/Promo';
import About from '../../components/home/About';
import Gallery from '../../components/home/Gallery';
import Services from '../../components/home/Services';
import Partner from '../../components/home/Partner';
import Skill from '../../components/home/Skill';
import PortfolioContact from '../../components/home/PortfolioContact';
import FooterBottom from '../../components/home/FooterBottom';
import TestChatbot_3 from '../Test/TestChatbot_3';
import ToTopButton from 'components/common/ToTopButton';

import { useTranslation } from 'react-i18next';

interface IProps {
  location: {
    pathname: string;
  };
  changeLanguage: any;
  appLanguage: any;
  sentContactMessage: any;
  isContactMessageLoad: any;
  isUserSentContactMessageEarly: any;
  error: any;
  isContactFormInputsHighlight: any;
  contactFormHighlight: any;
}

const Home: FunctionComponent<IProps> = ({
  location: { pathname },
  changeLanguage,
  appLanguage,
  sentContactMessage,
  isContactMessageLoad,
  isUserSentContactMessageEarly,
  error,
  isContactFormInputsHighlight,
  contactFormHighlight
}: IProps) => {
  // changeLanguage ->  ƒ () { return dispatch(actionCreator.apply(this, arguments)); }

  // console.log(dispatch); // {contactMessage: false}
  // debugger;

  const { t, i18n } = useTranslation();

  contactFormHighlight(null);

  return (
    <>
      <Header pathname={pathname} />
      <MainHeader
        changeLanguage={changeLanguage}
        appLanguage={appLanguage}
        sentContactMessage={sentContactMessage}
        isContactMessageLoad={isContactMessageLoad}
        isUserSentContactMessageEarly={isUserSentContactMessageEarly}
        error={error}
        isContactFormInputsHighlight={isContactFormInputsHighlight}
      />
      <Promo t={t} />
      <About />
      <Gallery />
      <Services />
      <Partner />
      {/* <Skill /> */}
      <PortfolioContact />
      <FooterBottom />
      
      <TestChatbot_3 />
      
      <ToTopButton />
    </>
  );
};

// export default Home;

const mapStateToProps = (state: ApplicationState) => ({
  // application
  appLanguage: state.application.language,
  isContactFormInputsHighlight: state.application.isContactFormInputsHighlight,
  // auth
  isContactMessageLoad: state.auth.isContactMessageLoad,
  isUserSentContactMessageEarly: state.auth.isUserSentContactMessageEarly,
  error: state.auth.error,
});

const mapDispatchToProps = {
  // application
  contactFormHighlight,
  changeLanguage,
  // auth
  sentContactMessage: sentContactMessage.started,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
