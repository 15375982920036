import React from 'react';
import axios from 'axios';
import psevdo_axios from '../helpers/psevdo_axios';
import _ from 'lodash';

import { contactUsValidationScheme } from 'helpers/validations';

const API_URL = 'https://api.an-kor.com';

export const postRequest = (url: string, data?: any, token?: string, formRequest?: boolean) => {
  const URL = `${API_URL}${url}`;

  let headers = {};

  if (token) {
    headers = {
      ...headers,
      Authorization: 'Bearer ' + token,
    };
  }

  if (formRequest) {
    headers = {
      ...headers,
      'Content-Type': 'application/x-www-form-urlencoded',
    };
  }

  return psevdo_axios({
    method: 'post',
    url: URL,
    data: data,
    headers,
  });

  return axios({
    method: 'post',
    url: URL,
    data: data,
    headers,
  });
};

export const getRequest = (url: string, data?: any, token?: string) => {
  const URL = `${API_URL}${url}`;

  return axios({
    method: 'get',
    url: URL,
    data,
    headers: token && {
      Authorization: 'Bearer ' + token,
    },
  });
};
