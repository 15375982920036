import React from 'react';
import { useParams } from 'react-router-dom';

import MenuTop from './MenuTop';
import Breadcrumbs from './Breadcrumbs';
import PortfolioServices from './PortfolioServices';
import PortfolioContact from './PortfolioContact';
import FooterBottom from './FooterBottom';

function PortfolioWork() {
  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.
  let { id } = useParams();

  return (
    <>
      {/*
        <h3>ID: {id}</h3>
      */}
      <MenuTop />
      <Breadcrumbs />
      <PortfolioServices />
      <PortfolioContact />
      <FooterBottom />
    </>
  );
}

export default PortfolioWork;
