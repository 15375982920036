import React, { Component, useState, FunctionComponent } from 'react';
import { useTranslation, withTranslation, Trans } from 'react-i18next';
// import { Link } from 'react-router-dom';

import './SidebarMenu.css';

import ClosedMenu from '../ClosedMenu';

import icon43 from '../../../../images/logo/logo5.svg';
import resume from '../../../../images/logo/resume2.svg';
import logo5 from '../../../../images/icon/icon43.svg';
import menu2 from '../../../../images/logo/menu.png';

// https://github.com/fisshy/react-scroll
import {
  Link as AnchorLink,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from 'react-scroll';

import { EN, RU, UA } from '../../../../constants/applications';
import LettersPreloader from '../../../common/Preloaders/LettersPreloader';
import { GLOBAL, LOCAL, SEND, TRANSLATE } from '../../../../constants/preloaders';



/**
 * To open/close menu, you need to change classes:
 * - eCommerce-side-menu op-page-menu-one
 * - eCommerce-side-menu op-page-menu-one show-menu
 */
function SidebarMenu(props) {
  const { t, i18n } = useTranslation();

  const subMenu = e => {
    e.preventDefault();
    props.toggleSubMenu();
  };

  const handleSetActive = to => {
    console.log(to);
  };

  const [isTrans, setIsTrans] = useState(false);

  const changeLanguage = lng => {
    props.changeLanguage(lng);
    i18n.changeLanguage(lng);
    setIsTrans(true);
    setTimeout(() => {
      setIsTrans(false);
    }, 1000);
  };

  // onBlur={() => {console.log('onBlur'); props.closeMenu();} }
  if (isTrans) return <LettersPreloader lettersArr={TRANSLATE} scope={GLOBAL} />;
  return (
    <>
      <div
        id="sidebar-menu"
        class={`eCommerce-side-menu op-page-menu-one ${props.menuIsOpen ? 'show-menu' : ''}`}
      >
        <div class="inner-wrapper">
          <div class="logo-wrapper">
            <button class="close-button" onClick={props.closeMenu}>
              <img src={logo5} alt="" />
            </button>
            <img src={menu2} alt="" />
          </div>


          <div class="op-menu-one" style={{position: "unset", borderLeft: "none", borderBottom: "none"}}>
            <ul class="ct-language__dropdown" style={{width: "auto"}}>
              <li>
                <a
                  class={`lang-en lang-select ${props.appLanguage === EN && 'selected'}`}
                  onClick={() => changeLanguage(EN)}
                >
                  Eng.{' '}
                </a>
              </li>

              <li>
                <a
                  class={`lang-en lang-select ${props.appLanguage === UA ? 'selected' : ''}`}
                  onClick={() => changeLanguage(UA)}
                >
                  UA.{' '}
                </a>
              </li>
            </ul>
          </div>


          <div class="main-menu-list">
            <ul>
              <li>
                <AnchorLink
                  activeClass="activeMenuLink"
                  to="profile"
                  spy={true}
                  smooth={true}
                  offset={-120}
                  duration={1000}
                  onSetActive={handleSetActive}
                >
                  <p>{t('MainHeader.menu.profile')}</p>
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  activeClass="activeMenuLink"
                  to="latestProject"
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={1000}
                  onSetActive={handleSetActive}
                >
                  <p>{t('MainHeader.menu.latest_projects')}</p>
                </AnchorLink>
              </li>

              <li>
                <AnchorLink
                  activeClass="activeMenuLink"
                  to="skills"
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={2000}
                  onSetActive={handleSetActive}
                >
                  <p>{t('MainHeader.menu.skills')}</p>
                </AnchorLink>
              </li>

              <li>
                <AnchorLink
                  activeClass="activeMenuLink"
                  to="recommendations"
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={2000}
                  onSetActive={handleSetActive}
                >
                  <p style={{ fontSize: '32px' }}>{t('MainHeader.menu.recommendations')}</p>
                </AnchorLink>
              </li>

              <li>
                <AnchorLink
                  activeClass="activeMenuLink"
                  to="bottomContact"
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={2000}
                  onSetActive={handleSetActive}
                >
                  <p>{t('MainHeader.menu.contacts')}</p>
                </AnchorLink>
              </li>

              {/*
              <li class="dropdown-holder" onClick={subMenu}>
                <a href="#">
                  Pages
                  <button type="button" class="expander">
                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                  </button>
                </a>
                <ul class={`sub-menu ${props.subMenuIsOpen ? 'show' : ''}`}>
                  <li>
                    <a href="service-creative.html">Services</a>
                  </li>
                  <li>
                    <a href="project-minimal.html">Portfolio</a>
                  </li>
                  <li>
                    <a href="blog-filter.html">Blog</a>
                  </li>
                </ul>
              </li>
              */}
            </ul>
          </div>

          <p class="copy-right">&copy; 2024 All Right Reserved</p>
        </div>
      </div>
    </>
  );
}

export default SidebarMenu;
