import React, { useState, useEffect, useRef } from 'react';

import './ToTopButton.css';

// https://github.com/fisshy/react-scroll
import {
  Link as AnchorLink,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from 'react-scroll';

/**
 * Alternative hook for componentDidMount:
 * - If the second argument is an empty array, like the example above, it will behave
 * exactly like the componentDidMount, only executing on the first rendering.
 * - Alternatively, you can pass one or more values inside the array, which will make
 * useEffect execute every time those value changes.
 */
const ToTopButton = () => {
  const [visibleButton, setVisibleButton] = useState(false);

  const buttonToTop = useRef('');

  useEffect(() => {
    window.onscroll = () => {
      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        setVisibleButton(true);
      } else {
        setVisibleButton(false);
      }
    };
  }, []);

  const toTop = () => {
    // console.log('to top button click');

    let options = {
      duration: 700,
      delay: 100,
    };

    scroll.scrollTo(0, options);
  };

  return (
    <>
      {visibleButton ? (
        <button onClick={toTop} id="myBtn" ref={buttonToTop} title="Go to top">
          Top
        </button>
      ) : (
        ''
      )}
    </>
  );
};

export default ToTopButton;

/**
 *
 */
const ToTopButton_imperative = () => {
  const [visibleButton, setVisibleButton] = useState(false);

  const buttonToTop = useRef('');

  useEffect(() => {
    window.onscroll = () => {
      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        // buttonToTop.current.style.display = 'block';
        buttonToTop.current.classList.add('apearUpButton');
      } else {
        // buttonToTop.current.style.display = 'none';
        buttonToTop.current.classList.remove('apearUpButton');
      }
    };
  }, []);

  const toTop = () => {
    console.log('to top button click');
  };

  return (
    <>
      <button onClick={toTop} id="myBtn" ref={buttonToTop} title="Go to top">
        Top
      </button>
    </>
  );
};
