import React from 'react';
import { connect } from 'react-redux';
// aplication actions
import { contactFormHighlight } from 'redux/modules/aplication/actions';

import { useTranslation, Trans } from 'react-i18next';

import './Gallery.css';

import ImgGalleryOne from '../../../images/portfolio/13.jpg';
import ImgGalleryTwo from '../../../images/portfolio/14.jpg';
import ImgGalleryThree from '../../../images/portfolio/15.jpg';
import ImgGalleryFour from '../../../images/portfolio/16.jpg';

import MassonryGalery from './Massonry';

import { Element, animateScroll as scroll } from 'react-scroll';

/**
 * @contactFormHighlight == action
 * @isUserSentContactMessageEarly == [false, 0]
 */
function Gallery({ contactFormHighlight, isUserSentContactMessageEarly }) {

  const { t } = useTranslation();

  const moreButtonClick = (e, html_title) => {
    e.preventDefault();

    setTimeout(() => {
      contactFormHighlight(html_title);
    }, 800);

    let options = {
      duration: 1500,
      delay: 100,
    };

    scroll.scrollTo(100, options);
  };

  // console.log('Gallery - ', isContactFormInputsHighlight);

  const canSendEmail = () => {
    return (
      <>
        <div class="more-text ml-auto">
          <span>{t('Portfolio.check_more.title')}</span>
          {/* <p>{t('Portfolio.check_more.text_1')}</p> */}
          <h2 class="allert">{t('Portfolio.check_more.text_3')}</h2>
          <a
            href="#"
            class="more-button"
            onClick={e =>
              moreButtonClick(e, `${t('Portfolio.check_more.text_send_from_button')}`)
            }
          >
            {t('Portfolio.check_more.button_text')}<i class="flaticon-next-1"></i>
          </a>
        </div>
      </>
    );
  };

  const dontCanSendEmail = () => {
    return (
      <>
        <div class="more-text ml-auto">
          <span>{t('Portfolio.check_more.title')}</span>
          <p>{t('Portfolio.check_more.text_2')}</p>
        </div>
      </>
    );
  };

  return (
    <>
      <Element name="latestProject"></Element>
      <div class="op-project-one">
        <div class="container">
          <div class="upper-heading">
            <div class="row">
              <div class="col-lg-6">
                <h2 class="theme-title-four font-k2d">
                  <Trans i18nKey="Portfolio.super_title">
                    Check out my latest <span>projects.</span>
                  </Trans>
                </h2>
              </div>
              <div class="col-lg-6">
                <p class="font-lato">
                  {t('Portfolio.super_description')}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="inner-wrapper">
          {/*
          <div class="work-text">work</div>
          */}
          <div class="bg-wrapper">
            <div class="container">
              <div id="isotop-gallery-wrapper">
                <MassonryGalery moreButtonClick={moreButtonClick} />
              </div>
              {isUserSentContactMessageEarly[0] == false ? canSendEmail() : dontCanSendEmail()}
              {/*
               */}
            </div>
            {/*
            <div class="project-text">projects</div>
            */}
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = state => ({
  // application
  // isContactFormInputsHighlight: state.application.isContactFormInputsHighlight,
  isUserSentContactMessageEarly: state.auth.isUserSentContactMessageEarly,
});

const mapDispatchToProps = {
  // application
  contactFormHighlight,
};

export default connect(mapStateToProps, mapDispatchToProps)(Gallery);
