import React from 'react';
import firebase from '../../../firebase';
import { connect } from 'react-redux';
import { setCurrentChannel, setPrivateChannel } from '../actions';
import { Menu, Icon } from 'semantic-ui-react';
import { Card } from 'antd';
import { Typography, Space } from 'antd';

import { StarOutlined, CheckCircleTwoTone, HeartTwoTone } from '@ant-design/icons';

const { Text, Link } = Typography;

class Starred extends React.Component {
  state = {
    user: this.props.currentUser,
    usersRef: firebase.database().ref('users'),
    activeChannel: '',
    starredChannels: [],
  };

  componentDidMount() {
    if (this.state.user) {
      this.addListeners(this.state.user.uid);
    }
  }

  componentWillUnmount() {
    this.removeListener();
  }

  removeListener = () => {
    this.state.usersRef.child(`${this.state.user.uid}/starred`).off();
  };

  addListeners = userId => {
    this.state.usersRef
      .child(userId)
      .child('starred')
      .on('child_added', snap => {
        const starredChannel = { id: snap.key, ...snap.val() };
        this.setState({
          starredChannels: [...this.state.starredChannels, starredChannel],
        });
      });

    this.state.usersRef
      .child(userId)
      .child('starred')
      .on('child_removed', snap => {
        const channelToRemove = { id: snap.key, ...snap.val() };
        const filteredChannels = this.state.starredChannels.filter(channel => {
          return channel.id !== channelToRemove.id;
        });
        this.setState({ starredChannels: filteredChannels });
      });
  };

  setActiveChannel = channel => {
    this.setState({ activeChannel: channel.id });
  };

  changeChannel = channel => {
    this.setActiveChannel(channel);
    this.props.setCurrentChannel(channel);
    this.props.setPrivateChannel(false);
  };

  displayChannels = starredChannels =>
    starredChannels.length > 0 &&
    starredChannels.map(channel => (
      <Menu.Item
        key={channel.id}
        onClick={() => this.changeChannel(channel)}
        name={channel.name}
        style={{ opacity: 0.7, paddingTop: '5px' }}
        active={channel.id === this.state.activeChannel}
      >
        {/*
        # {channel.name}
          <StarOutlined />
        */}
        <Text mark keyboard underline style={{ marginRight: '6px' }}>
          <CheckCircleTwoTone twoToneColor="#52c41a" /> <b>#{channel.name}</b>
        </Text>
      </Menu.Item>
    ));

  render() {
    const { starredChannels } = this.state;

    return (
      <Card>
        <Menu.Menu className="menu" style={{ paddingTop: '15px' }}>
          <Menu.Item>
            <span>
              {/*
              <Icon name="star" /> STARRED
              */}
              <HeartTwoTone twoToneColor="#eb2f96" /> Favorite
            </span>{' '}
            ({starredChannels.length})
          </Menu.Item>
          {this.displayChannels(starredChannels)}
        </Menu.Menu>
      </Card>
    );
  }
}

export default connect(null, { setCurrentChannel, setPrivateChannel })(Starred);

/*
import React from 'react';
import firebase from '../../../firebase';
import { connect } from 'react-redux';
import { setCurrentChannel, setPrivateChannel } from '../actions';
import { Menu, Icon } from 'semantic-ui-react';

import { Table, Tag, Card } from 'antd';

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: text => <a>{text}</a>,
  },
  {
    title: 'short desc',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: 'Tags',
    key: 'tags',
    dataIndex: 'tags',
    render: tags => (
      <span>
        {tags.map(tag => {
          let color = tag.length > 5 ? 'geekblue' : 'green';
          if (tag === 'loser') {
            color = 'volcano';
          }
          return (
            <Tag color={color} key={tag}>
              {tag.toUpperCase()}
            </Tag>
          );
        })}
      </span>
    ),
  },
];

class Starred extends React.Component {
  state = {
    user: this.props.currentUser,
    usersRef: firebase.database().ref('users'),
    activeChannel: '',
    starredChannels: [],
  };

  componentDidMount() {
    if (this.state.user) {
      this.addListeners(this.state.user.uid);
    }
  }

  componentWillUnmount() {
    this.removeListener();
  }

  removeListener = () => {
    this.state.usersRef.child(`${this.state.user.uid}/starred`).off();
  };

  addListeners = userId => {

    this.state.usersRef
      .child(userId)
      .child('starred')
      .on('child_added', snap => {
        const starredChannel = { id: snap.key, ...snap.val() };
        this.setState({
          starredChannels: [...this.state.starredChannels, starredChannel],
        });
      });

    this.state.usersRef
      .child(userId)
      .child('starred')
      .on('child_removed', snap => {
        const channelToRemove = { id: snap.key, ...snap.val() };
        const filteredChannels = this.state.starredChannels.filter(channel => {
          return channel.id !== channelToRemove.id;
        });
        this.setState({ starredChannels: filteredChannels });
      });
  };

  setActiveChannel = channel => {
    this.setState({ activeChannel: channel.id });
  };

  changeChannel = channel => {
    this.setActiveChannel(channel);
    this.props.setCurrentChannel(channel);
    this.props.setPrivateChannel(false);
  };

  getNotificationCount = channel => {

  };


  displayChannels = channels => {
    const that = this;

    let channelsFunctionObj = {};
    const rowClick = rowIndex => {
      channelsFunctionObj[rowIndex]();

      // // background: #f5f5f5;
      let el = document.querySelectorAll('tr');
      el.map = [].map; // => заимствование метода
      el.map(e => {
        e.setAttribute('style', 'background: #fff;');
      });

      let clickedTr = rowIndex + 2;
      el[clickedTr] && el[clickedTr].setAttribute('style', 'background: #f5f5f5;');

      // Этот пример возвращает список всех элементов iframe, которые имеют атрибут "data-src":
      // var matches = el.querySelectorAll('tr[data-row-key]');
    };
    let data_2 = [];
    if (channels.length > 0) {
      data_2 = channels.map((channel, i) => {

        this.getNotificationCount(channel);
        channelsFunctionObj[i] = () => {
          that.changeChannel(channel);
        };
        // console.log(channel);
        // debugger;
        return {
          // onClick: () => this.changeChannel(channel),
          key: i,
          name: channel.name,
          address: channel.details.slice(0, 20) + '...',
          tags: ['new'],
          style: { background: '#f5f5f5' },
        };
      });

      return (
        <>
          <div styleName="rowTableStyle">
            <Table
              size={'small'}
              showHeader={false}
              columns={columns}
              dataSource={data_2}
              scroll={{ y: 240 }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: event => {
                    // console.log(event, record, rowIndex);
                    rowClick(rowIndex);
                  }, // click row
                };
              }}
            />

          </div>
        </>
      );
    }
  };

  render() {
    const { starredChannels } = this.state;

    return (
      <Menu.Menu className="menu">
        <Menu.Item>
          <span>
            <Icon name="star" /> STARRED
          </span>{' '}
          ({starredChannels.length})
        </Menu.Item>
        {this.displayChannels(starredChannels)}
      </Menu.Menu>
    );
  }

}

export default connect(null, { setCurrentChannel, setPrivateChannel })(Starred);
*/
