import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { logIn, sentContactMessage } from './actions';
import { IState } from './interfaces';

const INITIAL_STATE: IState = {
  isLoading: false,
  user: null,
  forgotPasswordRequestSent: false,
  userData: null,
  isContactMessageLoad: false,
  isUserSentContactMessageEarly: [false, 0],
  error: {
    contactMessage: false,
  },
};

const reducer = reducerWithInitialState<IState>(INITIAL_STATE)
  .case(sentContactMessage.started, state => ({
    ...state,
    isContactMessageLoad: true,
  }))
  .case(sentContactMessage.done, (state, payload) => {
    let countMessage = state.isUserSentContactMessageEarly[1];
    return {
      ...state,
      isContactMessageLoad: false,
      isUserSentContactMessageEarly: [true, ++countMessage],
      error: { ...state.error, contactMessage: false },
    };
  })
  .case(sentContactMessage.failed, (state, payload) => ({
    ...state,
    isContactMessageLoad: false,
    error: { ...state.error, contactMessage: true },
  }))

  .case(logIn.started, (state, payload) => {
    // console.log(payload); debugger  // => {email: "work.fse@gmail.com", password: "qwerty"}
    return {
      ...state,
      isLoading: true,
      error: null,
    };
  })
  .case(logIn.done, (state, payload) => {
    // console.log(payload); debugger  // => {params: {email: "work.fse@gmail.com", password: "qwerty"}, result: {token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ3b…nkifQ.UN5vrFvYCMw1tQkJdjVo7PO83ppn6vbJpoD0REDX-to", userName: "work.fse@gmail.com", email: "work.fse@gmail.com"} }
    return {
      ...state,
      user: payload.result,
      isLoading: false,
      error: null,
    };
  })
  .case(logIn.failed, (state, payload) => ({
    ...state,
    isLoading: false,
    error: payload.error,
  }));

export default reducer;
