import React from 'react';
import { Grid } from 'semantic-ui-react';

import './App.css';

import { connect } from 'react-redux';

import firebase from '../../firebase';

import 'semantic-ui-css/semantic.min.css';
// import ColorPanel from "./ColorPanel/ColorPanel";
import SidePanel from './SidePanel/SidePanel';
import UserPanel from './SidePanel/UserPanel';
import Channels from './SidePanel/Channels';
import Channels_2 from './SidePanel/Channels_2';
import DirectMessages from './SidePanel/DirectMessages';
import Starred from './SidePanel/Starred';

import Messages from './Messages/Messages';
import MetaPanel from './MetaPanel/MetaPanel';
import MetaPanel_2 from './MetaPanel/MetaPanel_2';

import { SettingOutlined } from '@ant-design/icons';

import { Menu, Icon } from 'semantic-ui-react';

import {
  Collapse,
  Select,
  Button,
  Row,
  Col,
  Layout,
  Table,
  Tag,
  PageHeader,
  Descriptions,
  Card,
} from 'antd';

import { Tabs, Radio } from 'antd';

import { Typography } from 'antd';

const { TabPane } = Tabs;
const { Title } = Typography;

// -----------

// ----------------------------------

const { Panel } = Collapse;
const { Option } = Select;

function callback(key) {
  console.log(key);
}

const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

/*
  <SettingOutlined
  onClick={(event) => {
    // If you don't want click extra trigger collapse, you can prevent this:
    event.stopPropagation();
  }}
  />
*/
const genExtra = () => <Button type="primary">Primary</Button>;

class Demo extends React.Component {
  state = {
    expandIconPosition: 'left',
  };

  onPositionChange = expandIconPosition => {
    this.setState({ expandIconPosition });
  };

  render() {
    const { expandIconPosition } = this.state;
    return (
      <div>
        <Collapse
          defaultActiveKey={['1']}
          onChange={callback}
          expandIconPosition={expandIconPosition}
        >
          <Panel header="This is panel header 1" key="1" extra={genExtra()}>
            {/*
            <Channels currentUser={this.props.currentUser} />
            */}
          </Panel>
          <Panel header="This is panel header 2" key="2">
            <Starred currentUser={this.props.currentUser} />
            {/*
            <Table columns={columns} dataSource={data} />
            */}
          </Panel>
          <Panel header="This is panel header 3" key="3">
            <div>{text}</div>
          </Panel>
        </Collapse>
      </div>
    );
  }
}

// --------------------------------------------

const { Content } = Layout;

class App extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        // console.log(user);
        // debugger;

        this.props.setUser(user);
        // this.props.history.push('/account');
      } else {
        // this.props.history.push('/login');
        this.props.clearUser();
      }
      /*
      if (!user) this.props.history.push('/login');
      */
    });
  }

  render() {
    const {
      currentUser,
      currentChannel,
      isPrivateChannel,
      userPosts,
      primaryColor,
      secondaryColor,
    } = this.props;
    return (
      <>
        <div style={{ margin: '16px' }}>
          <Row>
            <Col span={4}>
              <Card>
                <Title level={3}>User</Title>
                <UserPanel currentUser={currentUser} />
                <div>
                  {/*
                  <Tabs defaultActiveKey="1" tabPosition="left" style={{ height: 220 }}>
                    {[...Array(4).keys()].map(i => (
                      <TabPane
                        tab={<UserPanel currentUser={currentUser} />}
                        key={i}
                        disabled={i === 28}
                      ></TabPane>
                    ))}
                  </Tabs>
                */}
                  <DirectMessages currentUser={currentUser} />
                </div>
              </Card>
            </Col>
            <Col span={14}>
              <div
                style={{
                  margin: '0px 15px',
                }}
              >
                <Messages
                  key={currentChannel && currentChannel.id}
                  currentChannel={currentChannel}
                  currentUser={currentUser}
                  isPrivateChannel={isPrivateChannel}
                />
              </div>
            </Col>

            <Col span={6}>
              {/*
              <Content
                className="site-layout-background"
                style={{
                  padding: 24,
                  margin: 0,
                  minHeight: 280,
                }}
              >
                <Table size={'small'} columns={columns} dataSource={data} />
                <ChanelsTable />
              </Content>
              */}
              {/*
              <Starred currentUser={currentUser} />
              */}
              <Channels_2 currentUser={currentUser} />

              {/*
              <DirectMessages currentUser={currentUser} />
              <UserPanel primaryColor={primaryColor} currentUser={currentUser} />

              <MetaPanel_2
                key={currentChannel && currentChannel.name}
                userPosts={userPosts}
                currentChannel={currentChannel}
                isPrivateChannel={isPrivateChannel}
                currentUser={currentUser}
              />
              <Channels currentUser={currentUser} />
              <Starred currentUser={currentUser} />
              */}
            </Col>

            {/*
            <Col span={5}>
              <MetaPanel
                key={currentChannel && currentChannel.name}
                userPosts={userPosts}
                currentChannel={currentChannel}
                isPrivateChannel={isPrivateChannel}
              />

              <UserPanel primaryColor={primaryColor} currentUser={currentUser} />

              <MetaPanel_2
                key={currentChannel && currentChannel.name}
                userPosts={userPosts}
                currentChannel={currentChannel}
                isPrivateChannel={isPrivateChannel}
                currentUser={currentUser}
              />
              <Channels currentUser={currentUser} />
              <Starred currentUser={currentUser} />
              <DirectMessages currentUser={currentUser} />

              <Demo currentUser={currentUser} />
            </Col>
             */}
          </Row>
        </div>
      </>
    );
  }
}

/* User Action Types */
const SET_USER = 'SET_USER';
const CLEAR_USER = 'CLEAR_USER';

/* User Actions */
const setUser = user => {
  return {
    type: SET_USER,
    payload: {
      currentUser: user,
    },
  };
};

const clearUser = () => {
  return {
    type: CLEAR_USER,
  };
};

const mapStateToProps = state => ({
  currentUser: state.user.currentUser,
  currentChannel: state.channel.currentChannel,
  isPrivateChannel: state.channel.isPrivateChannel,
  userPosts: state.channel.userPosts,
  primaryColor: state.colors.primaryColor,
  secondaryColor: state.colors.secondaryColor,
});

export default connect(mapStateToProps, { setUser, clearUser })(App);

/*
        <Grid columns="equal" className="app" style={{ background: secondaryColor }}>
          {/ <ColorPanel key={currentUser && currentUser.name} currentUser={currentUser} /> /}
          
          <SidePanel
            key={currentUser && currentUser.uid}
            currentUser={currentUser}
            primaryColor={primaryColor}
          />

          <Grid.Column style={{ marginLeft: 320 }}>
            <Messages
              key={currentChannel && currentChannel.id}
              currentChannel={currentChannel}
              currentUser={currentUser}
              isPrivateChannel={isPrivateChannel}
            />
          </Grid.Column>

          <Grid.Column width={4}>
            <MetaPanel
              key={currentChannel && currentChannel.name}
              userPosts={userPosts}
              currentChannel={currentChannel}
              isPrivateChannel={isPrivateChannel}
            />
          </Grid.Column>
        </Grid>
*/

/*
// prettier-ignore
const App = ({ currentUser, currentChannel, isPrivateChannel, userPosts, primaryColor, secondaryColor }) => (
  <Grid columns="equal" className="app" style={{ background: secondaryColor }}>

    <SidePanel
      key={currentUser && currentUser.uid}
      currentUser={currentUser}
      primaryColor={primaryColor}
    />

    <Grid.Column style={{ marginLeft: 320 }}>
      <Messages
        key={currentChannel && currentChannel.id}
        currentChannel={currentChannel}
        currentUser={currentUser}
        isPrivateChannel={isPrivateChannel}
      />
    </Grid.Column>

    <Grid.Column width={4}>
      <MetaPanel
        key={currentChannel && currentChannel.name}
        userPosts={userPosts}
        currentChannel={currentChannel}
        isPrivateChannel={isPrivateChannel}
      />
    </Grid.Column>
  </Grid>
);


*/
