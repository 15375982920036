import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
import storage from 'redux-persist/es/storage';
import createFilter from 'redux-persist-transform-filter';

import logger from 'redux-logger';

import reducers from './reducers';
import sagas from './sagas';
import { setIsStarted } from './modules/aplication/actions';

// const saveSubsetFilter = createFilter('auth', ['user', 'userData']);
// const saveSubsetFilter2 = createFilter('listing', ['listingStep', 'sessionExpired']);

const persistConfig = {
  key: 'resume',
  storage,
  // blacklist: ['auth', 'application'], // auth will not be persisted
  whiteList: ['auth', 'application'], // only application will be persisted
  // whiteList: ['channel'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

let middleWareList = [];

const sageMiddleware = createSagaMiddleware();
middleWareList.push(sageMiddleware);

const dev = process.env.NODE_ENV !== 'production';
if (dev) middleWareList.push(logger);

// const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware( ...[sageMiddleware, logger] )));
const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(...middleWareList))
);

const state = store.getState();
const persistor = persistStore(store, {}, () => store.dispatch(setIsStarted(state))); // is this state trow into payload?

sageMiddleware.run(sagas);

export default { store, persistor };
