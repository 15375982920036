import React from 'react';
import ReactDOM from 'react-dom';

// import 'assets/scss/index.scss';
// styles
// import 'semantic-ui-css/semantic.min.css';
/*
 */
import './css/style.css';
import './css/responsive.css';
import './css/custom-animation.css';
import './styles.css';
import App from './App';

// import i18n (needs to be bundled ;))
import './i18n';

ReactDOM.render(<App />, document.getElementById('root'));

/*
FAVIKON
https://medium.com/@alperen.talaslioglu/building-dynamic-favicon-with-javascript-223ad7999661
https://smartlanding.biz/zavlekayushhij-favicon-i-title-esli-vash-sajt-otkryt-v-neaktivnoj-vkladke.html
*/

// гугл 3д
// https://experiments.withgoogle.com/collection/webvr

/*
https://www.kickresume.com/en/help-center/it-resume-samples/?page=3

https://www.kickresume.com/en/help-center/takeplace-java-script-web-developer-resume-sample/
https://www.kickresume.com/en/help-center/software-engineer-1-resume-sample/
https://www.kickresume.com/en/help-center/qa-engineer-1-resume-sample/  
https://www.kickresume.com/en/help-center/senior-software-engineer-1-resume-sample/ // - style example for PDF resume
https://www.kickresume.com/en/help-center/google-server-manufacturing-supervisor-resume-sample/
*/

/*
  // незабыть добавить ссылку/ки на гугл клауд документацию...
  https://cloud.google.com/appengine/docs/standard/nodejs/quickstart

  https://futuresportal.ew.r.appspot.com/

  // тематический курс на Удеми (ссылка на подготовку к прод. деплою)
  https://www.udemy.com/course/react-fullstack-with-nodeexpress-psql-and-aws/learn/lecture/16147175#overview
  // в другом курсе про Пет-Клинику чювак использует конструктор Ант-Десинга - незабыть посмотреь его..

  // шаблон для запуска скомпилированного реакта под експрессом
  // просто засунуть сборку в Билд фолдер
  https://github.com/iqbal125/react-express-sample 

  // webRTC
  https://www.youtube.com/watch?v=BpN6ZwFjbCY 
  // React Group Video Chat | simple-peer webRTC
  https://www.youtube.com/watch?v=R1sfHPwEH7A
*/

// https://firebase.google.com/docs/storage/web/upload-files?hl=ru

// npm install --save react-zoom-pan-pinch
// yarn add react-zoom-pan-pinch

// как перебрать объект js
// https://abcinblog.blogspot.com/2019/09/jses.html?m=1

//  backgroundImage: `url(${AboutImg})`,
/*
// 'cover' => Масштабирует изображение с сохранением пропорций так, чтобы его ширина или высота равнялась ширине или высоте блока.
<div
  style={{
    background: `url(${grayscaleNotebook}) 50% 50% no-repeat`,
    backgroundSize: 'cover',  
    minHeight: '700px',
  }}
></div>
*/

// дисплей flex и инлай-блок
// https://ru.stackoverflow.com/questions/309573/%D0%91%D0%BB%D0%BE%D0%BA%D0%B8-div-%D0%BF%D0%BE-%D0%B3%D0%BE%D1%80%D0%B8%D0%B7%D0%BE%D0%BD%D1%82%D0%B0%D0%BB%D0%B8

// https://learn.javascript.ru/css-selectors

// localStorage.clear()

/**
 * This command will let you use any release within any project.
 * yarn policies set-version <version number>
 */

/**
 * Alternative hook for componentDidMount:
 */
import ToTopButton from 'components/common/ToTopButton';

/**
 * JS functional component with i18
 */
import BannerOne from './components/home/MainHeader/BannerOne';

/**
 * interface IProps { location: {
 * const Home: FunctionComponent<IProps> = ({
 */
import Home from 'pages/Home';

/**
 * .map((e, i) =>
 */
import LettersPreloader from 'components/common/Preloaders/LettersPreloader';

/**
 * state = { subMenuIsOpen: false };
 *
 * openMenu = () => {
 *   this.setState(state => ({ menuIsOpen: true }));
 * };
 */

// https://stackoverflow.com/questions/39758136/render-html-string-as-real-html-in-a-react-component
// <div dangerouslySetInnerHTML={{ __html: this.state.posts[0].description }} />

/*
import { ISentMessageData } from 'models/User';

import './MainHeader.css';

import BannerOne from './BannerOne';
import SidebarMenu from './SidebarMenu';
import ClosedMenu from './ClosedMenu';

interface IProps {
  changeLanguage: (data: string) => void; // aplication => actions (string - "en" | "ru")
  appLanguage: string; // state => application => language ("en" | "ru")
  sentContactMessage: (data: ISentMessageData) => boolean; // async action
  isContactMessageLoad: boolean; // State => auth => isContactMessageLoad => false | true (when is loading)
  isUserSentContactMessageEarly: [boolean, number]; // user sent data earlier, how many times
}

  export interface IProps {
    user?: any;
  }
*/

//
/*
Событие onblur возникает при потере элемента фокуса. Это обычно происходит, если щелкнуть на другой 
элемент текущего документа. Событие onblur противоположно по своему действию событию onfocus.

Включаем фокусировку на любом элементе: tabindex
https://learn.javascript.ru/focus-blur

onBlur={() => {console.log('onBlur'); props.closeMenu();} }
*/

/*
const chatSettings = {
  floatingStyle: {
    background: 'black',
  },
  bubbleOptionStyle: {
    background: '#171717',
  },
  style: {
    borderRadius: 0,
  },
};

<FirstChat chatSettings={chatSettings} />

<ChatBot {...this.props.chatSettings} />
*/

/* 
  stagging6/src/components/account/AccountProperties/index.tsx

  // https://www.carlrippon.com/typed-usestate-with-typescript/
  const [state, setState] = React.useState<any>({
    exampleItems: [],
    pageOfItems: []
  });

  React.useEffect(() => {
    setState(
      {
        ...state,
        // exampleItems: [...Array(30).keys()].map(i => ({ id: (i+1), name: 'Item ' + (i+1) }))
        exampleItems: listings
      }
    )
  }, []);


  const onChangePage = (pageOfItems: any) => {
    // update state with new page of items
    setState({ ...state, pageOfItems: pageOfItems });
  }
*/

// History
/*

// 2 Вариант:
import history from 'helpers/history';

history.push(SIGNUP);

history.push({
  pathname: '/',
  state: { test: 'test' },
});
*/

/*
setInterval(() => {
  let element = document.getElementsByClassName('rsc-float-button')[0];
  // console.log(element);
  if (element) {
    element.classList.add('highlightContainer');
    setTimeout(() => {
      element.classList.remove('highlightContainer');
    }, 3000);
  }
}, 6000);


  let img = document.getElementsByClassName('sc-fzXfLV iVhDrr')[0];
  if (img) img.setAttribute('style', 'height: 44px; width: 44px;');

  let el = document.querySelectorAll('tr');
  el.map = [].map; // => заимствование метода
  el.map(e => {
    e.setAttribute('style', 'background: #fff;');
  });  
*/

/*
  // https://learn.javascript.ru/async-await
  // У слова async один простой смысл: эта функция всегда возвращает промис.
  // Ключевое слово await заставит интерпретатор JavaScript ждать до тех пор, пока
  // промис справа от await не выполнится. После чего оно вернёт его результат, и
  // выполнение кода продолжится.
  
  // * await можно использовать только внутри async функции, но мы часть пользуемся
  // только тем что нам дает await, в то время как значение возвращаемое async
  // (а это так же промис) мы используем редко.

  // решение с .then и с await:
  
  const setChildrenData = () => {
    let ref = firebase.database().ref('test/part1');

    ref.child('username').set({
      firstname: 'Garry',
      secondname: 'Kornegi',
    });

    ref.once('value').then(snapshot => {
      let username = snapshot.child('username').val();
      console.log(username);
    });
  };

  const setAsyncChildrenData = async () => {
    let ref = firebase.database().ref('test/part1');

    ref.child('username').set({
      firstname: 'Garry_2',
      secondname: 'Kornegi_2',
    });

    let snapshot = await ref.once('value');
    let username = snapshot.child('username').val();
    console.log('setAsyncChildrenData - ', username);
  };

  // Вызов на месте
  (async () => {
    await setTimeout(() => element.classList.add('highlightContainer', 3000));
    element.classList.remove('highlightContainer');
  })();
*/

/**
 * Work with FireBase
 *
 * https://firebase.google.com/docs/reference/js/firebase.database.DataSnapshot
 * https://firebase.google.com/docs/database/web/read-and-write#basic_write
 * https://firebase.google.com/docs/database/web/lists-of-data
 */
import TestFirebase from 'pages/Test/TestFireBase';

// < ------------------------------------------------------------------------------- >

/*
<style>
.theme-tab.tab-sm>.nav-tabs>.nav-item .nav-link {
    border-radius: 5px !important;
}

.icon-btn {
    border-radius: 5% !important;
}

.owl-stage-outer {
  display: none !important;
}

.recent {
  display: none !important;
}

.chitchat-container .chitchat-left-sidebar .recent-default .recent ~ .chat {
    margin-top: 0px !important;
    height: 100% !important;
}

.badge {
  border-radius: 0px !important;
}

.profile {
  border-radius: 100% !important;
}

.contact-details {
  display: none !important;
}

.contact-chat {
  margin-top: 50px !important;
}

.contact-chat>ul>li.replies .msg-box li:first-child h5 {
  border-radius: 10px 0px 10px 10px;
}

.icon-btn.btn-outline-primary {
  display: none !important;
}

.emojis-main .contact-poll {
  display: none !important;
}

.btn-fix {
  display: none !important;
}

.chat-main li .chat-box .date-status .status {
  display: none !important;
}

.contact-chat>ul>li.replies .msg-box li .badge {
  display: none !important;
}

.badge.sm {
  display: none !important;
}
</style>
*/

/*
https://themeforest.net/item/chitchat-perfect-chat-and-discussion-html-template/25814686
http://themes.pixelstrap.com/chitchat/pages/
http://themes.pixelstrap.com/chitchat/pages/messenger.html#

https://ant.design/components/tabs/
https://ant.design/components/badge/
https://ant.design/components/button/

// change aaallll avas
var userPopuapAvatar = document.getElementsByClassName("bg-size");
for (let index = 0; index < userPopuapAvatar.length; index++) {
  userPopuapAvatar[index].setAttribute('style', 'background-image: url("https://p7.hiclipart.com/preview/312/283/679/avatar-computer-icons-user-profile-business-user-avatar.jpg"); background-size: cover; background-position: center center; display: block;');
}


var avas = document.getElementsByClassName("profile online menu-trigger bg-size");
for (let index = 0; index < avas.length; index++) {
  avas[index].setAttribute('style', 'background-image: url("https://p7.hiclipart.com/preview/312/283/679/avatar-computer-icons-user-profile-business-user-avatar.jpg"); background-size: cover; background-position: center center; display: block;');
}

var test = document.getElementsByClassName("user-popup status one");
child = test[0].childNodes
child[1].setAttribute('style', 'background-image: url("https://p7.hiclipart.com/preview/312/283/679/avatar-computer-icons-user-profile-business-user-avatar.jpg"); background-size: cover; background-position: center center; display: block;');

// 
// document.getElementsByClassName("owl-stage-outer")[0].setAttribute('style', 'display: none;');
*/

/*
// ------------- chat -------------- //
Chat
Direct messages | Group messages

Garry_2  --  20.04.20
Hello, my name is Garry number 2

// activ chat
Korman  --  20.04.20
last message  --  null

    Hello, test chat work.. first message.. -
    Second test message, please give me the answer -

- Hello Korman, glad to see you!
- so how do you like our chat?

    im try to upload file -
*/
