import React from 'react';
// import "./index.css";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch,
} from 'react-router-dom';

// import { useRouteMatch } from 'react-router';

import { Layout, Menu } from 'antd';
import {
  DesktopOutlined,
  PieChartOutlined,
  FileOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';

const { Sider } = Layout;
const { SubMenu } = Menu;

/*
<NavLink
  to={ACCOUNT}
  className={styles.headerMenuItem}
  activeClassName={styles.activeMenuItem}
>
  My Account
</NavLink>
*/
const SiderMenu = props => {
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.
  let { path, url } = useRouteMatch();

  const [state, setState] = React.useState({
    actualPage: 1,
  });

  // console.log(path, url);
  // debugger;

  return (
    <>
      <Sider
        trigger={null}
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={broken => {
          console.log(broken);
        }}
        collapsible
        collapsed={props.collapsed}
        onCollapse={props.onCollapse}
      >
        <div className="logo" />

        <Menu theme="dark" defaultSelectedKeys={[state.actualPage]} mode="inline">
          {/*
           */}
          <Menu.Item key="1">
            <DesktopOutlined />
            <Link to={`${url}/test_1`} onClick={() => setState({ ...state, actualPage: 1 })}>
              <span>Default</span>
            </Link>
          </Menu.Item>

          <Menu.Item key="2">
            <PieChartOutlined />
            <Link to={`${url}/test_2`} onClick={() => setState({ ...state, actualPage: 2 })}>
              <span>MultiChanel Chat</span>
            </Link>
          </Menu.Item>

          <SubMenu
            key="sub2"
            title={
              <span>
                <TeamOutlined />
                <span>Team</span>
              </span>
            }
          >
            <Menu.Item key="6">Team 1</Menu.Item>
            <Menu.Item key="8">Team 2</Menu.Item>
          </SubMenu>
          <Menu.Item key="9">
            <FileOutlined />
          </Menu.Item>
        </Menu>
      </Sider>
    </>
  );
};

export default SiderMenu;
