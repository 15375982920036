// import React, { FunctionComponent, Component } from 'react';
import React, { Component, Suspense } from 'react';
import { useTranslation, withTranslation, Trans } from 'react-i18next';

import { connect } from 'react-redux';
import { ApplicationState } from 'redux/temp_interface';

// auth actions
import { logIn, sentContactMessage } from 'redux/modules/auth/actions';
// aplication actions
import { changeLanguage } from 'redux/modules/aplication/actions';

import './Test.css';

// import Header from '../../components/common/Header';
// @ts-ignore
import TestBlogsComponentFireBase from './TestFireBase/Blog';
import TestFireBase from './TestFireBase';
import TestChatbot from './TestChatbot';
import TestChatbot_2 from './TestChatbot_2';
import TestChatbot_3 from './TestChatbot_3';
import TestLogin from './TestLogin';
import TestSentContactMessage from './TestSentContactMessage';
import TestModal from './TestModal';
import TestParent from './TestParent';
import TestModalParent from './TestModalParent';
import TestTranslate from './TestTranslate';
import TestChangeLanguage from './TestChangeLanguage';

// import Modal from 'react-modal';

/**
 *
 */
interface ObjectLiteral {
  [key: string]: any;
}

interface IProps {
  state?: any;
  logIn?: any;
  authIsLoading?: any;
  authData?: any;
  sentContactMessage?: any;
  isContactMessageLoad?: any;
  isUserSentContactMessageEarly?: any;
  changeLanguage?: any;
  appLanguage?: any;
}

interface IState {
  change?: any;
  data?: any;
}

class Test extends Component<IProps> {
  state: IState = {
    change: false,
    data: [],
  };

  render() {
    if (this.props.authIsLoading) return 'page is loading';

    // @ts-ignore
    const Child = ({ doSomething, value }) => (
      <div onClick={() => doSomething(value)}>Click Me</div>
    );

    // @ts-ignore
    const Second = ({ doSomething, value }) => (
      <div onClick={() => doSomething(value)}>Click Me...</div>
    );

    return (
      <>
        <br />
        <hr />
        <TestTranslate />
        <hr />
        <br />
        {/*
        <br />
        <hr />
        <TestBlogsComponentFireBase />
        <br />
        <hr />
        */}
        {/*
        <TestFireBase />
        <br />
        <TestChatbot />
        <TestChatbot_2 />
        <TestChatbot_3 />
        <hr />
        <TestLogin
          logIn={this.props.logIn}
          authIsLoading={this.props.authIsLoading}
          authData={this.props.authData}
        />
        <hr />
        <TestSentContactMessage
          sentContactMessage={this.props.sentContactMessage}
          isContactMessageLoad={this.props.isContactMessageLoad}
          isUserSentContactMessageEarly={this.props.isUserSentContactMessageEarly}
        />
        <hr />
        <TestModal />
        <hr />
        <TestParent>
          <Child doSomething="" value="1" />
          <Child doSomething="" value="2" />
          <Second doSomething="" value="second" />
        </TestParent>
        <hr />
        <TestModalParent>
          <Child doSomething="" value="1" />
          <Child doSomething="" value="2" />
          <Second doSomething="" value="second" />
        </TestModalParent>
        <hr />
        <TestTranslate />
        <hr />
        <TestChangeLanguage
          changeLanguage={this.props.changeLanguage}
          appLanguage={this.props.appLanguage}
        />
      */}
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </>
    );
  } // END render()
}

const mapStateToProps = (state: ApplicationState) => ({
  state: state,
  // auth
  authIsLoading: state.auth.isLoading,
  authData: state.auth.user,
  isContactMessageLoad: state.auth.isContactMessageLoad,
  isUserSentContactMessageEarly: state.auth.isUserSentContactMessageEarly,
  // aplication
  appLanguage: state.application.language,
});

const mapDispatchToProps = {
  // auth
  logIn: logIn.started,
  sentContactMessage: sentContactMessage.started,
  changeLanguage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Test);
