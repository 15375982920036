export const LOCAL = 'Local';
export const GLOBAL = 'Global';
export const LOADING = ['L', 'O', 'A', 'D', 'I', 'N', 'G'];
export const SEND = ['S', 'E', 'N', 'D'];
export const TRANSLATE = ['T', 'R', 'A', 'S', 'L', 'A', 'T', 'E'];
export const MESSAGE_DELIVERED = [
  'M',
  'E',
  'S',
  'S',
  'A',
  'G',
  'E',
  ' ',
  'D',
  'E',
  'L',
  'I',
  'V',
  'E',
  'R',
  'E',
  'D',
];
