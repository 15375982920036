import React from 'react';

import { GLOBAL } from '../../../../constants/preloaders';

/**
 *
 * @param {*} param0
 */
function Preloader({ lettersArr, scope, spiner = true }) {
  const parsingLetters = lettersArr.map((e, i) => (
    <span data-text-preloader={e} class="letters-loading">
      {e}
    </span>
  ));

  return (
    <>
      <section>
        <div id="preloader">
          <div
            id="ctn-preloader"
            class="ctn-preloader"
            style={{ position: `${scope === GLOBAL ? 'fixed' : 'absolute'}` }}
          >
            <div class="animation-preloader">
              {!spiner && <div class="spinner"></div>}
              <div class="txt-loading">{parsingLetters}</div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Preloader;

/*
  // Тоже рабочий вариант
  const lett = lettersArr.map((e, i) => {
    return (
      <span data-text-preloader={e} class="letters-loading">
        {e}
      </span>
    );
  });
  */
