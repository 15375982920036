import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { isBotWorking } from './actions';
import { IState } from './interfaces';

// import { EN } from '../../../constants/applications';

const INITIAL_STATE: IState = {
  testBot: false,
};

const reducer = reducerWithInitialState<IState>(INITIAL_STATE)
  // .case(setIsStarted, state => ({
  //   ...state,
  //   isStarted: true,
  // }))
  .case(isBotWorking, (state, payload) => ({
    ...state,
    testBot: payload,
  }));

export default reducer;
