import React, { Component, Suspense } from 'react';
import { useTranslation, withTranslation, Trans } from 'react-i18next';
import TestClassComponent from './TestClassComponent';
import TestFunctionComponent from './TestFunctionComponent';
import VikaTranslate from './VikaTranslate';

function TestTranslate() {
  const { t, i18n } = useTranslation();

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };
  return (
    <>
      <div className="App-header">
        {/*
        <button onClick={() => changeLanguage('de')}>de</button>
        */}
        <button onClick={() => changeLanguage('en')}>en</button>
        <button onClick={() => changeLanguage('ru')}>ru</button>
      </div>
      <div className="App-intro">
        <Trans i18nKey="description.part1">
          To get started, edit <code>src/App.js</code> and save to reload.
        </Trans>
        <TestClassComponent />
        <TestFunctionComponent />
        <VikaTranslate />
      </div>
    </>
  );
}

export default TestTranslate;
