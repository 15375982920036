import { SagaIterator } from 'redux-saga';
import { takeEvery } from 'redux-saga/effects';

import { isBotWorking } from './actions';

function* testBotWorker(): SagaIterator {
  console.log('testBotWorker');
}

export default function* root(): SagaIterator {
  yield takeEvery(isBotWorking, testBotWorker);
}
