import React, { Component, useState, FunctionComponent } from 'react';
import { useTranslation, withTranslation, Trans } from 'react-i18next';
// import { Link } from 'react-router-dom';

import './ClosedMenu.css'

import LettersPreloader from '../../../common/Preloaders/LettersPreloader';

import { EN, RU, UA } from '../../../../constants/applications';
import { GLOBAL, LOCAL, SEND, TRANSLATE } from '../../../../constants/preloaders';

import Icon68 from '../../../../images/icon/icon68.svg';

function ClosedMenu(props) {
  const { t, i18n } = useTranslation();

  const [isTrans, setIsTrans] = useState(false);

  const changeLanguage = lng => {
    props.changeLanguage(lng);
    i18n.changeLanguage(lng);
    setIsTrans(true);
    setTimeout(() => {
      setIsTrans(false);
    }, 1000);
  };

  if (isTrans) return <LettersPreloader lettersArr={TRANSLATE} scope={GLOBAL} />;
  return (
    <>
      <div class="op-menu-one">
        <ul class="ct-language__dropdown">
          <li>
            <a
              class={`lang-en lang-select ${props.appLanguage === EN && 'selected'}`}
              onClick={() => changeLanguage(EN)}
            >
              Eng.{' '}
            </a>
          </li>

          <li>
            <a
              class={`lang-en lang-select ${props.appLanguage === UA ? 'selected' : ''}`}
              onClick={() => changeLanguage(UA)}
            >
              UA.{' '}
            </a>
          </li>
        </ul>
        <ul class="social-icon">
          <li>
            <a href="#">
              <i class="fa fa-facebook" aria-hidden="true"></i>
            </a>
          </li>
          <li>
            <a href="#">
              <i class="fa fa-twitter" aria-hidden="true"></i>
            </a>
          </li>
          <li>
            <a href="#">
              <i class="fa fa-linkedin" aria-hidden="true"></i>
            </a>
          </li>
        </ul>
        <button class="op-menu-button sidebar-menu-open" onClick={props.openMenu}>
          <img src={Icon68} alt="" />
        </button>
      </div>
    </>
  );
}

export default ClosedMenu;
