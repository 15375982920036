import React from 'react';

import './Partner.css';

// https://www.npmjs.com/package/@fortawesome/react-fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMailBulk, faVoicemail } from '@fortawesome/free-solid-svg-icons';
import {
  faFacebookF,
  faFacebookSquare,
  faSkype,
  faFreeCodeCamp,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import { faAddressCard } from '@fortawesome/free-regular-svg-icons';

import partnerAvatarDefault from '../../../images/home/4.jpg';
import partnerAvatarKorman from '../../../images/home/avatarKorman.jpg';
import partnerAvatarNoam from '../../../images/home/avatarNoam.jpg';
import partnerNoamLimor from '../../../images/home/noamLimor.jpg';
import partnerAlexPotapenko from '../../../images/home/avatarAlex.jpg';


import { useTranslation, Trans } from 'react-i18next';

import { Element } from 'react-scroll';

/**
 *
 */
function Partner() {
  const { t } = useTranslation();

  return (
    <>
      <Element name="recommendations"></Element>
      <div class="op-testimonial-one">
        <div class="container clearfix">
          <div class="row">
            <div class="col-md-6">
              <h2 class="theme-title-four font-k2d">
                { t('Partner.title.text_1') } <span>{ t('Partner.title.text_2') }</span>
              </h2>
              <p>
                { t('Partner.description.text_1') }
              </p>
              

              <div class="client-text-box">
                <img src={partnerAlexPotapenko} alt="" />
                <blockquote>
                  “{ t('Partner.reviews.review_1.review_text') }”
                </blockquote>
                <h6 class="font-k2d">{ t('Partner.reviews.review_1.name') }</h6>
                <div class="desg">{ t('Partner.reviews.review_1.person_description') }</div>

                <div className="partnerSocialMain">
                  <div className="partnerSocialMain__item">
                    <a href="https://www.linkedin.com/in/oleksii-potapenko-73938722/" target="_blank">
                      <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                  </div>
                </div>
              </div>
              


              {/* <div class="client-text-box">
                <blockquote>
                  “What a great experience I had with Yuriy, he went the extra mile to make things
                  work better. Will deferentially with him again in the future. Thank you for all
                  your hard work.”
                </blockquote>
                <h6 class="font-k2d">Mark</h6>
                <div class="desg">CEO & Founder. Israel.</div>
                <div className="partnerSocialMain" style={{ marginTop: '10px' }}>
                  <div className="partnerSocialMain__item">Contact by request.</div>
                </div>
              </div> */}




            </div>

            <div class="col-md-6">
              <div class="client-text-box">
                <img src={partnerAvatarKorman} alt="" />
                <blockquote>
                  “{ t('Partner.reviews.review_2.review_text') }”
                </blockquote>
                <h6 class="font-k2d">{ t('Partner.reviews.review_2.name') }</h6>
                <div class="desg">{ t('Partner.reviews.review_2.person_description') }</div>

                <div className="partnerSocialMain">
                  <div className="partnerSocialMain__item">
                    <a href="https://www.facebook.com/yuriy.korman" target="_blank">
                      <FontAwesomeIcon icon={faFacebookSquare} />
                    </a>
                  </div>
                  <div className="partnerSocialMain__item">
                    <a
                      href="https://www.upwork.com/o/profiles/users/~018b3aa893c39ec56a/?fbclid=IwAR3_gT8X9WiHch0QaaRS9DRagAn1yQN5VbUwh5vJyLbVT6uarNe47mbOEd4"
                      target="_blank"
                    >
                      <FontAwesomeIcon icon={faAddressCard} />
                    </a>
                  </div>
                  {/* <div className="partnerSocialMain__item">
                    <a href="#" target="_blank">
                      <FontAwesomeIcon icon={faSkype} />{' '}
                      <span className="partnerName">
                        {' '}
                        - korman.yuri
                      </span>
                    </a>
                  </div> */}
                  {/*
                  <div className="partnerSocialMain__item">
                    <a href="#" target="_blank">
                      <FontAwesomeIcon icon={faMailBulk} />
                    </a>
                  </div>
                  <div className="partnerSocialMain__item">
                    <a href="#" target="_blank">
                      <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                  </div>
                  */}
                </div>
              </div>


              <div class="client-text-box">
                <img src={partnerNoamLimor} alt="" />
                <blockquote>
                  “{ t('Partner.reviews.review_3.review_text') }”
                </blockquote>
                <h6 class="font-k2d">{ t('Partner.reviews.review_3.name') }</h6>
                <div class="desg">{ t('Partner.reviews.review_3.person_description') }</div>

                <div className="partnerSocialMain">
                  <div className="partnerSocialMain__item">
                    <a href="https://www.linkedin.com/in/noam-limor-355ba762" target="_blank">
                      <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Partner;
