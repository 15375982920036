import React, { Component } from 'react';
import ChatBot from 'react-simple-chatbot';
import { useTranslation, withTranslation, Trans } from 'react-i18next';
// import { Link } from 'react-router-dom';

/**
 *
 * @param {*} props
 */
const HelloUser = props => {
  // placeholder={t('MainHeader.contact_form.email')}
  const { t, i18n } = useTranslation();

  const HelloUserHandleEnd = () => {
    // console.log('HelloUserHandleEnd');
    // debugger;
    props.changeIsFirstMeeting('currentChat', 'MeetChat');
  };

  return (
    <>
      <ChatBot
        {...props.chatSettings}
        handleEnd={HelloUserHandleEnd}
        steps={[
          {
            id: '100',
            message: messageProps => {
              // console.log('---------------------------------->');
              // props.changeIsFirstMeeting('atlasFirstMeeting', false);
              return props.atlasFirstMeeting
                ? t('ChatBot.first_chat.first_meeting') + " (!!! The chat is not working yet; it is in the process of development and testing.)"
                : 'What can I do for you?';
            },
            trigger: '200',
          },
          {
            id: '105',
            message: 'Awesome! You are a telepath! (!!! The chat is not working yet; it is in the process of development and testing.)',
            trigger: '110',
          },
          {
            id: '110',
            options: [
              { value: 2, label: 'What are you are?', trigger: '400' },
              { value: 3, label: 'Let me introduce myself.', trigger: '500' },
            ],
          },
          {
            id: '115',
            options: [
              { value: 3, label: 'Let me introduce myself.', trigger: '500' },
              { value: 2, label: 'Maybe later', trigger: '100' },
            ],
          },
          {
            id: '200',
            options: [
              { value: 1, label: 'Tell me about this site.', trigger: '300' },
              { value: 2, label: 'What are you are?', trigger: '400' },
              { value: 3, label: 'Let me introduce myself.', trigger: '500' },
            ],
          },
          {
            id: '300',
            message:
              'This is a resume site  (!!! The chat is not working yet; it is in the process of development and testing.)',
            trigger: '110',
          },
          {
            id: '400',
            message:
              'Hi, my name is Atlas, I am a robot designed to serve as a personal assistant... (!!! The chat is not working yet; it is in the process of development and testing.)',
            trigger: '115',
          },
          {
            id: '500',
            message: 'Great! Thanks..',
            // end: true,
            trigger: '100',
          },
        ]}
      />
    </>
  );
};
// 'This is a resume site of one good specialist, the site is made using the following technologies: ReactJS, TypeScript..., can i do anything else for you? How should i call you?',




export default HelloUser;

/*
class HelloUser extends Component {
  render() {
    return (
      <ChatBot
        {...this.props.chatSettings}
        steps={[
          {
            id: '1',
            message: props => {
              console.log('---------------------------------->');
              this.props.changeIsFirstMeeting('atlasFirstMeeting', false);
              return this.props.atlasFirstMeeting
                ? 'Hi, my name is Atlas, what can I do for you?'
                : 'What can I do for you?';
            },
            trigger: '2',
          },
          {
            id: '2',
            options: [
              { value: 1, label: 'Tell me about this site.', trigger: '4' },
              { value: 2, label: 'What are you are?', trigger: '3' },
              { value: 3, label: 'I want to make friendship with you.', trigger: '3' },
            ],
          },
          {
            id: '3',
            message: 'Wrong answer, try again.',
            trigger: '2',
          },
          {
            id: '4',
            message: 'Awesome! You are a telepath!',
            end: true,
          },
        ]}
      />
    );
  }
}

export default HelloUser;
*/
