import React, { Component } from 'react';

import Slider from 'react-slick';

import './Gallery.css';

import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

import ImgGalleryTwo from '../../../../../images/portfolio/14.jpg';
import ImgGalleryThree from '../../../../../images/portfolio/15.jpg';
import serv from '../../../../../images/portfolio/serv.jpg';
import skipMM from '../../../../../images/portfolio/skip-mm.jpg';

class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      draggable: false,
    };
    return (
      <>
        <div>
          {/*
          <div className="top-container">
            <h1>Scroll Down</h1>
            <p>Scroll down to see the sticky effect.</p>
          </div>

          <div className="header" id="myHeader">
            <h2>My Header</h2>
          </div>
            */}
          <div className="content">
            <Slider {...settings}>
              <CustomSlide index={1} />
              <CustomSlide index={2} />
              <CustomSlide index={3} />
              <CustomSlide index={4} />
              <CustomSlide index={5} />
              <CustomSlide index={6} />
            </Slider>
          </div>
        </div>
      </>
    );
  }
}

export default SimpleSlider;

class CustomSlide extends Component {
  render() {
    const { index, ...props } = this.props;

    switch (index) {
      case 1:
        return (
          <div {...props}>
            {/*
            <img src={skipMM} />
            */}
            <p>Lorem ipsum dollar</p>
          </div>
        );

      case 2:
        return (
          <div {...props}>
            <Example />
          </div>
        );

      default:
        return (
          <div {...props}>
            <h3>{index}</h3>
            <img src={serv} />
          </div>
        );
    }
  }
}

class Example extends Component {
  render() {
    const wheel = {
      wheelEnabled: false,
    };

    return (
      <TransformWrapper
        defaultScale={1}
        defaultPositionX={null}
        defaultPositionY={null}
        wheel={wheel}
      >
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          <React.Fragment>
            <div className="tools">
              <button className="tools__item" onClick={zoomIn}>
                +
              </button>
              <button className="tools__item" onClick={zoomOut}>
                -
              </button>
              <button className="tools__item" onClick={resetTransform}>
                default size
              </button>
            </div>
            <TransformComponent>
              <div className="overflow">
                <img src={skipMM} alt="test" />
              </div>
            </TransformComponent>
          </React.Fragment>
        )}
      </TransformWrapper>
    );
  }
}
