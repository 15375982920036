import React, { Component, Suspense } from 'react';
import { useTranslation, withTranslation, Trans } from 'react-i18next';

function VikaTranslate() {
  const { t, i18n } = useTranslation();

  return (
    <>
      {t('MainHeader.contact_form.email')}
      {t('MainHeader.contact_form.email')}
    </>
  );
}

export default VikaTranslate;
